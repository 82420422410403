@if (eventUserProfile) {
<div class="main">
	<div
		class="complete-header"
		[style.background]="event.styling.menuColor"
		[style.color]="event.styling.menuTextColor"
	></div>
	<app-profile-general-info
		[event]="event"
		[module]="module"
		[eventUserProfile]="eventUserProfile"
		[editMode]="true"
		[isPrivateMode]="true"
	>
	</app-profile-general-info>
	<div class="background" [style.paddingRight]="module.options.enableUserFieldsHideAbility && isMobile ? '25px' : ''">
		<div class="main-container">
			<form [formGroup]="formEditEventUser">
				<ion-row
					[class.upper-btn-in-desktop]="!isMobile"
					[class.upper-btn-in-mobile]="isMobile"
					class="upper-btn-in-desktop"
				>
					<ion-col size="12">
						<button
							mat-raised-button
							class="btn-validate"
							type="submit"
							[class.button-spinner]="loader"
							(click)="editEventUser()"
							[disabled]="loader || !formEditEventUser.valid || !customFieldFormValid"
							[ngClass]="{'btn-validate[disabled]': !formEditEventUser.valid || !customFieldFormValid}"
							[style.background]="event.styling.btnBgColor"
							[style.color]="event.styling.btnTextColor"
						>
							@if (!loader) {
							<span>{{"edit-profil.submit"| translate}}</span>
							}
						</button>
					</ion-col>
				</ion-row>
				<!-- Name field -->
				<ion-row>
					<ion-col class="custom-ion-col" col-12>
						<ion-item mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating">
								{{"edit-profil.name" | translate}}
								<span class="required"> * </span>
							</ion-label>
							<ion-input
								autocapitalize="sentences"
								mode="md"
								class="custom-input"
								type="text"
								formControlName="name"
								[ngStyle]="{'color': formEditEventUser.get('name').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.name_text-info' | translate }}"
							>
							</ion-input>
							<!-- Untouched field icon -->
							@if ((!formEditEventUser.get('name').touched && !formEditEventUser.get('name').dirty) ||
							formEditEventUser.get('name').value === '' && !formEditEventUser.get('name').errors) {
							<ion-icon src="assets/icon/Check.svg" class="check-icon" slot="end"> </ion-icon>
							}
							<!-- Success icon -->
							@if (!formEditEventUser.get('name').errors && (formEditEventUser.get('name').touched ||
							formEditEventUser.get('name').dirty) && formEditEventUser.get('name').value !== '') {
							<ion-icon src="assets/icon/Check.svg" class="check-icon" slot="end" color="primary">
							</ion-icon>
							}
							<!-- Errors icon -->
							@if (formEditEventUser.get('name').errors && (formEditEventUser.get('name').touched ||
							formEditEventUser.get('name').dirty)) {
							<ion-icon src="assets/icon/Close.svg" class="check-icon" slot="end" color="danger">
							</ion-icon>
							}
						</ion-item>
						<!-- Error MSGs -->
						<!-- Blank field -->
						@if ( formEditEventUser.get('name').errors?.required && (formEditEventUser.get('name').touched
						|| formEditEventUser.get('name').dirty)) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.blank.name" | translate }}</p>
						</div>
						}
						<!-- Invalid format -->
						@if ( formEditEventUser.get('name').errors?.minlength) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.invalid.name" | translate }}</p>
						</div>
						}
					</ion-col>
				</ion-row>
				<!-- Email field -->
				@if (module && module.options.requiredFields) {
				<ion-row>
					<ion-col class="custom-ion-col" col-12>
						<ion-item mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating">
								{{"edit-profil.email" | translate}} @if (module.options.requiredFields.email.required) {
								<span class="required"> * </span>
								}
							</ion-label>
							<ion-input
								mode="md"
								class="custom-input"
								type="email"
								[ngStyle]="{'color': formEditEventUser.get('email').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.example_email2' | translate }}"
								formControlName="email"
								[readonly]="true"
							>
							</ion-input>
							<!-- Untouched field icon -->
							@if ((!formEditEventUser.get('email').touched && !formEditEventUser.get('email').dirty) ||
							formEditEventUser.get('email').value === '' && !formEditEventUser.get('email').errors) {
							<ion-icon src="assets/icon/Check.svg" class="check-icon" slot="end"> </ion-icon>
							}
							<!-- Success icon -->
							@if (!formEditEventUser.get('email').errors && (formEditEventUser.get('email').touched ||
							formEditEventUser.get('email').dirty) && formEditEventUser.get('email').value !== '') {
							<ion-icon src="assets/icon/Check.svg" class="check-icon" slot="end" color="primary">
							</ion-icon>
							}
							<!-- Errors icon -->
							@if (formEditEventUser.get('email').errors && (formEditEventUser.get('email').touched ||
							formEditEventUser.get('email').dirty)) {
							<ion-icon src="assets/icon/Close.svg" class="check-icon" slot="end" color="danger">
							</ion-icon>
							}
						</ion-item>
						<!-- Error MSGs -->
						<!-- Blank field -->
						@if ( module.options.requiredFields.email.required &&
						formEditEventUser.get('email').errors?.required && (formEditEventUser.get('email').touched ||
						formEditEventUser.get('email').dirty)) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.blank.email" | translate }}</p>
						</div>
						}
						<!-- Invalid format -->
						@if ( formEditEventUser.get('email').errors?.minlength &&
						formEditEventUser.get('email').errors?.email) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.invalid.email" | translate }}</p>
						</div>
						}
						<!-- Disabled/enabled button -->
						@if (module.options.enableUserFieldsHideAbility) {
						<ion-icon
							class="disabled-icon"
							(click)="updateFieldVisibility('email')"
							[src]="showUserOrModuleVisibilityOptions('email') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
						}
					</ion-col>
				</ion-row>
				}
				<!-- Biography / Description field -->
				@if (module && module.options.requiredFields && module.options.requiredFields.description.editionScope)
				{
				<ion-row>
					<ion-col class="custom-ion-col" col-12>
						<ion-item mode="md" lines="none" class="textarea-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.biography" | translate}} @if
								(module.options.requiredFields.description.required) {
								<span class="required"> * </span>
								}
							</ion-label>
							<!-- <app-tiny-preview-button
                  height="150px"
                  [editorRef]="editorRef"
                  style="width: 100%; padding: 15px"
                  >
                </app-tiny-preview-button> -->
							<!-- <ng-template #editorRef> -->
							<editor
								[init]="getTinyInit()"
								formControlName="description"
								style="width: 100%; margin: 23px 0; white-space: pre-line"
								[disabled]="module.options.requiredFields.description.uniqueEdit &&  eventUserProfile.description[event.language] !== ''"
							>
							</editor>
							<!-- </ng-template> -->
							<!-- <ion-textarea
              autocapitalize="sentences"
              mode="md"
              rows="5"
              formControlName="description"
              [ngStyle]="{'color': formEditEventUser.get('description').errors ? '#900C3F' : '#4B4D4D'}"
              placeholder="{{ 'edit-profil.placeholders.bio_text-info' | translate }}"
              style="min-height: 156px; white-space: pre-line"
              [disabled]="module.options.requiredFields.description.uniqueEdit &&  eventUserProfile.description[event.language] !== ''"
              >
            </ion-textarea> -->
							<!-- Untouched field icon -->
							@if ((!formEditEventUser.get('description').touched &&
							!formEditEventUser.get('description').dirty) || formEditEventUser.get('description').value
							=== '' && !formEditEventUser.get('description').errors) {
							<ion-icon src="assets/icon/Check.svg" class="check-icon" slot="end"> </ion-icon>
							}
							<!-- Success icon -->
							@if (!formEditEventUser.get('description').errors &&
							(formEditEventUser.get('description').touched || formEditEventUser.get('description').dirty)
							&& formEditEventUser.get('description').value !== '') {
							<ion-icon src="assets/icon/Check.svg" class="check-icon" slot="end" color="primary">
							</ion-icon>
							}
							<!-- Errors icon -->
							@if (formEditEventUser.get('description').errors &&
							(formEditEventUser.get('description').touched ||
							formEditEventUser.get('description').dirty)) {
							<ion-icon src="assets/icon/Close.svg" class="check-icon" slot="end" color="danger">
							</ion-icon>
							}
						</ion-item>
						<!-- Error MSGs -->
						<!-- Blank field -->
						@if ( module.options.requiredFields.description.required &&
						formEditEventUser.get('description').errors?.required &&
						(formEditEventUser.get('description').touched || formEditEventUser.get('description').dirty)) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>
						}
						<!-- Invalid format -->
						@if ( formEditEventUser.get('description').errors?.minlength ||
						formEditEventUser.get('description').errors?.maxlength) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.invalid.bio" | translate }}</p>
						</div>
						}
						<!-- Disabled/enabled button -->
						@if (module.options.enableUserFieldsHideAbility) {
						<ion-icon
							class="disabled-icon"
							(click)="updateFieldVisibility('description')"
							[src]="showUserOrModuleVisibilityOptions('description') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
						}
					</ion-col>
				</ion-row>
				}
				<!-- Custom fields edit component -->
				<app-custom-fields-edit
					#customFieldEditComponent
					[event]="event"
					[module]="module"
					[eventUserProfile]="eventUserProfile"
					[language]="currentLanguage"
					(validEvent)="customFieldFormValid = $event"
				>
				</app-custom-fields-edit>
				<!-- Web site field -->
				@if (module && module.options.requiredFields?.website.editionScope) {
				<ion-row>
					<ion-col class="custom-ion-col" size-xs="12" size-sm="12" size-lg="12">
						<ion-item style="width: 98% !important" mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.website" | translate}} @if
								(module.options.requiredFields.website.required) {
								<span class="required"> * </span>
								}
							</ion-label>
							<ion-input
								mode="md"
								class="custom-input"
								type="text"
								[ngStyle]="{'color': formEditEventUser.get('website').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.website_text-info' | translate }}"
								formControlName="website"
								[disabled]="module.options.requiredFields.website.uniqueEdit &&  eventUserProfile.socialMedias.website !== ''"
							>
							</ion-input>
							<!-- Untouched field icon -->
							@if ((!formEditEventUser.get('website').touched && !formEditEventUser.get('website').dirty)
							|| formEditEventUser.get('website').value === '' &&
							!formEditEventUser.get('website').errors) {
							<ion-icon src="assets/icon/Web.svg" class="check-icon" slot="end"> </ion-icon>
							}
							<!-- Success icon -->
							@if (!formEditEventUser.get('website').errors && (formEditEventUser.get('website').touched
							|| formEditEventUser.get('website').dirty) && formEditEventUser.get('website').value !== '')
							{
							<ion-icon src="assets/icon/Web.svg" class="check-icon" slot="end" color="primary">
							</ion-icon>
							}
							<!-- Errors icon -->
							@if (formEditEventUser.get('website').errors && (formEditEventUser.get('website').touched ||
							formEditEventUser.get('website').dirty)) {
							<ion-icon src="assets/icon/Web.svg" class="check-icon" slot="end" color="danger">
							</ion-icon>
							}
						</ion-item>
						<!-- Error MSGs -->
						<!-- Blank field -->
						@if ( module.options.requiredFields.website.required &&
						formEditEventUser.get('website').errors?.required && (formEditEventUser.get('website').touched
						|| formEditEventUser.get('website').dirty)) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>
						}
						<!-- Invalid format -->
						@if ( formEditEventUser.get('website').errors?.pattern) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.invalid.url" | translate }}</p>
						</div>
						}
						<!-- Disabled/enabled button -->
						@if (module.options.enableUserFieldsHideAbility) {
						<ion-icon
							class="disabled-icon"
							(click)="updateFieldVisibility('website')"
							[src]="showUserOrModuleVisibilityOptions('website') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
						}
					</ion-col>
				</ion-row>
				}
				<ion-row>
					<!-- Facebook field -->
					@if (module && module.options.requiredFields?.facebook.editionScope) {
					<ion-col class="custom-ion-col" size-xs="12" size-sm="12" size-lg="6">
						<ion-item style="width: 98% !important" mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.facebook" | translate}} @if
								(module.options.requiredFields.facebook.required) {
								<span class="required"> * </span>
								}
							</ion-label>
							<ion-input
								mode="md"
								class="custom-input"
								type="text"
								[ngStyle]="{'color': formEditEventUser.get('facebook').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.facebook_text-info' | translate }}"
								formControlName="facebook"
								[disabled]="module.options.requiredFields.facebook.uniqueEdit &&  eventUserProfile.socialMedias.facebook !== ''"
							>
							</ion-input>
							<!-- Untouched field icon -->
							@if ((!formEditEventUser.get('facebook').touched &&
							!formEditEventUser.get('facebook').dirty) || formEditEventUser.get('facebook').value === ''
							&& !formEditEventUser.get('facebook').errors) {
							<ion-icon src="assets/icon/Social/Facebook.svg" class="check-icon" slot="end"> </ion-icon>
							}
							<!-- Success icon -->
							@if (!formEditEventUser.get('facebook').errors && (formEditEventUser.get('facebook').touched
							|| formEditEventUser.get('facebook').dirty) && formEditEventUser.get('facebook').value !==
							'') {
							<ion-icon
								src="assets/icon/Social/Facebook.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							}
							<!-- Errors icon -->
							@if (formEditEventUser.get('facebook').errors && (formEditEventUser.get('facebook').touched
							|| formEditEventUser.get('facebook').dirty)) {
							<ion-icon
								src="assets/icon/Social/Facebook.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
							}
						</ion-item>
						<!-- Error MSGs -->
						<!-- Blank field -->
						@if ( module.options.requiredFields.facebook.required &&
						formEditEventUser.get('facebook').errors?.required && (formEditEventUser.get('facebook').touched
						|| formEditEventUser.get('facebook').dirty)) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>
						}
						<!-- Invalid format -->
						@if ( formEditEventUser.get('facebook').errors?.pattern) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.invalid.facebook" | translate }}</p>
						</div>
						}
						<!-- Disabled/enabled button -->
						@if (module.options.enableUserFieldsHideAbility) {
						<ion-icon
							class="disabled-icon"
							[ngClass]="!isMobile && module.options.enableUserFieldsHideAbility ? 'social' : ''"
							(click)="updateFieldVisibility('facebook')"
							[src]="showUserOrModuleVisibilityOptions('facebook') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
						}
					</ion-col>
					}
					<!-- Linkedin field -->
					@if (module && module.options.requiredFields?.linkedin.editionScope) {
					<ion-col class="custom-ion-col" size-xs="12" size-sm="12" size-lg="6">
						<ion-item mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.linkedin" | translate}} @if
								(module.options.requiredFields.linkedin.required) {
								<span class="required"> * </span>
								}
							</ion-label>
							<ion-input
								mode="md"
								class="custom-input"
								type="text"
								[ngStyle]="{'color': formEditEventUser.get('linkedin').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.linkedin_text-info' | translate }}"
								formControlName="linkedin"
								[disabled]="module.options.requiredFields.linkedin.uniqueEdit &&  eventUserProfile.socialMedias.linkedin !== ''"
							>
							</ion-input>
							<!-- Untouched field icon -->
							@if ((!formEditEventUser.get('linkedin').touched &&
							!formEditEventUser.get('linkedin').dirty) || formEditEventUser.get('linkedin').value === ''
							&& !formEditEventUser.get('linkedin').errors) {
							<ion-icon src="assets/icon/Social/Linkedin.svg" class="check-icon" slot="end"> </ion-icon>
							}
							<!-- Success icon -->
							@if (!formEditEventUser.get('linkedin').errors && (formEditEventUser.get('linkedin').touched
							|| formEditEventUser.get('linkedin').dirty) && formEditEventUser.get('linkedin').value !==
							'') {
							<ion-icon
								src="assets/icon/Social/Linkedin.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							}
							<!-- Errors icon -->
							@if (formEditEventUser.get('linkedin').errors && (formEditEventUser.get('linkedin').touched
							|| formEditEventUser.get('linkedin').dirty)) {
							<ion-icon
								src="assets/icon/Social/Linkedin.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
							}
						</ion-item>
						<!-- Error MSGs -->
						<!-- Blank field -->
						@if ( module.options.requiredFields.linkedin.required &&
						formEditEventUser.get('linkedin').errors?.required && (formEditEventUser.get('linkedin').touched
						|| formEditEventUser.get('linkedin').dirty)) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>
						}
						<!-- Invalid format -->
						@if ( formEditEventUser.get('linkedin').errors?.pattern) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.invalid.linkedin" | translate }}</p>
						</div>
						}
						<!-- Disabled/enabled button -->
						@if (module.options.enableUserFieldsHideAbility) {
						<ion-icon
							class="disabled-icon"
							(click)="updateFieldVisibility('linkedin')"
							[src]="showUserOrModuleVisibilityOptions('linkedin') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
						}
					</ion-col>
					}
				</ion-row>
				<ion-row>
					<!-- Twitter field -->
					@if (module && module.options.requiredFields?.twitter.editionScope) {
					<ion-col class="custom-ion-col" size-xs="12" size-sm="12" size-lg="6">
						<ion-item style="width: 98% !important" mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.twitter" | translate}} @if
								(module.options.requiredFields.twitter.required) {
								<span class="required"> * </span>
								}
							</ion-label>
							<ion-input
								mode="md"
								class="custom-input"
								type="text"
								[ngStyle]="{'color': formEditEventUser.get('twitter').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.twitter_text-info' | translate }}"
								formControlName="twitter"
								[disabled]="module.options.requiredFields.twitter.uniqueEdit &&  eventUserProfile.socialMedias.twitter !== ''"
							>
							</ion-input>
							<!-- Untouched field icon -->
							@if ((!formEditEventUser.get('twitter').touched && !formEditEventUser.get('twitter').dirty)
							|| formEditEventUser.get('twitter').value === '' &&
							!formEditEventUser.get('twitter').errors) {
							<ion-icon src="assets/icon/Social/Twitter X.svg" class="check-icon" slot="end"> </ion-icon>
							}
							<!-- Success icon -->
							@if (!formEditEventUser.get('twitter').errors && (formEditEventUser.get('twitter').touched
							|| formEditEventUser.get('twitter').dirty) && formEditEventUser.get('twitter').value !== '')
							{
							<ion-icon
								src="assets/icon/Social/Twitter X.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							}
							<!-- Errors icon -->
							@if (formEditEventUser.get('twitter').errors && (formEditEventUser.get('twitter').touched ||
							formEditEventUser.get('twitter').dirty)) {
							<ion-icon
								src="assets/icon/Social/Twitter X.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
							}
						</ion-item>
						<!-- Error MSGs -->
						<!-- Blank field -->
						@if ( module.options.requiredFields.twitter.required &&
						formEditEventUser.get('twitter').errors?.required && (formEditEventUser.get('twitter').touched
						|| formEditEventUser.get('twitter').dirty)) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>
						}
						<!-- Invalid format -->
						@if ( formEditEventUser.get('twitter').errors?.pattern) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.invalid.twitter" | translate }}</p>
						</div>
						}
						<!-- Disabled/enabled button -->
						@if (module.options.enableUserFieldsHideAbility) {
						<ion-icon
							class="disabled-icon"
							[ngClass]="!isMobile && module.options.enableUserFieldsHideAbility ? 'social' : ''"
							(click)="updateFieldVisibility('twitter')"
							[src]="showUserOrModuleVisibilityOptions('twitter') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
						}
					</ion-col>
					}
					<!-- Instagram field -->
					@if (module && module.options.requiredFields?.instagram.editionScope) {
					<ion-col class="custom-ion-col" size-xs="12" size-sm="12" size-lg="6">
						<ion-item mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.instagram" | translate}} @if
								(module.options.requiredFields.instagram.required) {
								<span class="required"> * </span>
								}
							</ion-label>
							<ion-input
								mode="md"
								class="custom-input"
								type="text"
								[ngStyle]="{'color': formEditEventUser.get('instagram').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.instagram_text-info' | translate }}"
								formControlName="instagram"
								[disabled]="module.options.requiredFields.instagram.uniqueEdit &&  eventUserProfile.socialMedias.instagram !== ''"
							>
							</ion-input>
							<!-- Untouched field icon -->
							@if ((!formEditEventUser.get('instagram').touched &&
							!formEditEventUser.get('instagram').dirty) || formEditEventUser.get('instagram').value ===
							'' && !formEditEventUser.get('instagram').errors) {
							<ion-icon src="assets/icon/Social/Instagram.svg" class="check-icon" slot="end"> </ion-icon>
							}
							<!-- Success icon -->
							@if (!formEditEventUser.get('instagram').errors &&
							(formEditEventUser.get('instagram').touched || formEditEventUser.get('instagram').dirty) &&
							formEditEventUser.get('instagram').value !== '') {
							<ion-icon
								src="assets/icon/Social/Instagram.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							}
							<!-- Errors icon -->
							@if (formEditEventUser.get('instagram').errors &&
							(formEditEventUser.get('instagram').touched || formEditEventUser.get('instagram').dirty)) {
							<ion-icon
								src="assets/icon/Social/Instagram.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
							}
						</ion-item>
						<!-- Error MSGs -->
						<!-- Blank field -->
						@if ( module.options.requiredFields.instagram.required &&
						formEditEventUser.get('instagram').errors?.required &&
						(formEditEventUser.get('instagram').touched || formEditEventUser.get('instagram').dirty)) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>
						}
						<!-- Invalid format -->
						@if ( formEditEventUser.get('instagram').errors?.pattern) {
						<div class="form-bloc-error">
							<p class="error-label-text">{{ "edit-profil.errors.invalid.instagram" | translate }}</p>
						</div>
						}
						<!-- Disabled/enabled button -->
						@if (module.options.enableUserFieldsHideAbility) {
						<ion-icon
							class="disabled-icon"
							(click)="updateFieldVisibility('instagram')"
							[src]="showUserOrModuleVisibilityOptions('instagram') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
						}
					</ion-col>
					}
				</ion-row>
				<ion-row [class.btn-in-desktop]="!isMobile" [class.btn-in-mobile]="isMobile">
					<ion-col size="12">
						<button
							mat-raised-button
							[class.button-spinner]="loader"
							class="btn-validate"
							type="submit"
							(click)="editEventUser()"
							[disabled]="loader || !formEditEventUser.valid || !customFieldFormValid"
							[ngClass]="{'btn-validate[disabled]': !formEditEventUser.valid || !customFieldFormValid}"
							[style.background]="event.styling.btnBgColor"
							[style.color]="event.styling.btnTextColor"
						>
							@if (!loader) {
							<span>{{"edit-profil.submit"| translate}}</span>
							}
						</button>
					</ion-col>
				</ion-row>
			</form>
		</div>
	</div>
</div>
}
