import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Browser } from "@capacitor/browser";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { Subscription, combineLatest, skipWhile, take } from "rxjs";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeModule } from "src/app/shared/enums/type-module";
import { TypeUser } from "src/app/shared/enums/type-user";
import {
	IDocument,
	IEvent,
	IEventUser,
	IFullCustomField,
	ILanguage,
	ILocation,
	IModule,
	ISchedule,
	ITrack
} from "src/app/shared/interfaces";
import {
	ICustomField,
	ICustomFieldData,
	IField,
	IModuleCustomField
} from "src/app/shared/interfaces/custom-fields.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getDocuments } from "src/app/shared/selectors/documents.selectors";
import {
	getBaseCustomFields,
	getLocations,
	getModulesCustomFields,
	getModulesCustomsFieldsOfModule,
	getTracks
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import {
	AnalyticsService,
	CustomFieldsService,
	EventUsersService,
	SchedulesService,
	UtilityService
} from "src/app/shared/services";
import { DocumentsService } from "src/app/shared/services/documents.service";
import { ConfirmIcsDownloadComponent } from "../../../../modals/confirm-ics-download/confirm-ics-download.component";
import { Clipboard } from "@capacitor/clipboard";

@Component({
	selector: "app-custom-fields-view",
	templateUrl: "./custom-fields-view.component.html",
	styleUrls: ["./custom-fields-view.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldsViewComponent implements OnInit, OnDestroy, OnChanges {
	@Input() event: IEvent;
	@Input() module: IModule;
	@Input() eventUserProfile: IEventUser;
	@Input() scheduleId: string;
	@Input() moduleType: number;
	@Input() currentLanguage: string;

	myEventUser: IEventUser;

	attendeesSub: Subscription;
	speakersSub: Subscription;
	subscriptions: Subscription[] = [];
	schedule: ISchedule;
	formCustomFields: UntypedFormGroup;
	languages: string[] = [];
	selectedLangTab: string;
	customGroupControl: UntypedFormGroup;

	computedCustomFields: IFullCustomField[];
	computedCFText: IFullCustomField[];
	computedCFHtml: IFullCustomField[];
	computedCFJob_Title: IFullCustomField[];
	computedModule: IFullCustomField[];
	multipleTextCF: IFullCustomField[];
	multipleSelectCF: IFullCustomField[];
	computedImageCF: IFullCustomField[];
	computedFileCF: IFullCustomField[];

	typeModule = TypeModule;
	typeCustomFields = TypeCustomFields;

	users: IEventUser[];
	tracks: ITrack[] = [];
	sessions: ISchedule[] = [];
	customDocuments: IDocument[] = [];
	multipleTextValues: any[] = [];

	multipleTextInputError: any[] = [];

	loader = false;
	isProfilMode = false;
	userSelectedDoc: IDocument;
	loaderToast: HTMLIonLoadingElement;

	attendeesCustomField: IEventUser[] = [];
	speakersCustomField: IEventUser[] = [];

	isMobile: boolean;
	allCustomFieldsModules: IModuleCustomField[] = [];
	baseCustomsFields: ICustomField[] = [];
	eventUserSessions: ISchedule[] = [];
	sessionSchedules: ISchedule[] = [];
	locations: ILocation[] = [];
	sessionModuleIds: string[] = [];
	sessionModules: IModule[] = [];
	typeFormatFiles: string[] = [
		"audio",
		"doc",
		"excel",
		"jpg",
		"media",
		"pdf",
		"photo",
		"ppt",
		"vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"vnd.openxmlformats-officedocument.wordprocessingml.document"
	];
	customFieldsScheduleDatas: { uid: string; sessions: ISchedule[] }[] = [];
	modulesCustomsFields: IModuleCustomField[] = [];
	// userHasBeenUpdated$: Subject<boolean> = new Subject<boolean>();

	allDatasInitialized = false;

	constructor(
		private fb: UntypedFormBuilder,
		private store: Store,
		public SDocument: DocumentsService,
		public SUtility: UtilityService,
		private platform: Platform,
		private STranslate: TranslateService,
		private SEventUsers: EventUsersService,
		private navCtrl: NavController,
		public SSchedules: SchedulesService,
		private SAnalytics: AnalyticsService,
		private modalCtrl: ModalController,
		private cdr: ChangeDetectorRef,
		public SCustomFields: CustomFieldsService
	) {
		this.formCustomFields = this.fb.group({});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			(!this.allDatasInitialized &&
				(!_.isEqual(changes.eventUserProfile, changes.eventUserProfile?.previousValue) ||
					!_.isEqual(changes.currentLanguage, changes.currentLanguage?.previousValue))) ||
			!_.isEqual(changes.scheduleId, changes.scheduleId?.previousValue)
		) {
			this.initDatas();
		}

		if (changes.currentLanguage) {
			this.event.language = this.currentLanguage;
		}
	}

	ngOnInit(): void {
		this.languages = [];
		for (const key in this.event.languagesSettings) {
			if (this.event.languagesSettings[key]) {
				if (key === this.currentLanguage) {
					this.languages.unshift(key);
				} else {
					this.languages.push(key);
				}
			}
		}

		this.initDatas();

		// Platform
		this.isMobile = (this.platform.is("mobile") && window.innerWidth < 768) || this.platform.width() < 400;
	}

	initDatas() {
		this.getCustomFieldsFromUserModule();
		this.getCustomFieldsFromScheduleModule();
		this.getAllSessions();
		this.getAllTracks();
		this.getLocations();
		this.getDocuments();
		this.getMyEventUser();
		this.allDatasInitialized = true;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		if (this.attendeesSub && !this.attendeesSub.closed) {
			this.attendeesSub.unsubscribe();
		}
		if (this.speakersSub && !this.speakersSub.closed) {
			this.speakersSub.unsubscribe();
		}

		this.allDatasInitialized = false;
	}

	cleanComponent() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		if (this.attendeesSub && !this.attendeesSub.closed) {
			this.attendeesSub.unsubscribe();
		}
		if (this.speakersSub && !this.speakersSub.closed) {
			this.speakersSub.unsubscribe();
		}

		this.allDatasInitialized = false;
	}

	isCustomFieldsExist() {
		return this.computedCustomFields.length > 0;
	}

	/**
	 * Getting all documents
	 */
	getDocuments() {
		this.subscriptions.push(
			this.store.select(getDocuments).subscribe((data) => {
				this.customDocuments = data;
			})
		);
	}

	/**
	 * Getting all schedules / sessions
	 */
	getAllSessions() {
		this.subscriptions.push(
			// combineLatest([this.SSchedules.getAllSessionsOfModulesFromFile(), this.userHasBeenUpdated$])
			this.SSchedules.getAllSessionsOfModulesFromFile()
				// this.store
				// 	.select(getAllSessions)
				// .pipe(skipWhile(([_, state]) => !this.baseCustomsFields || !state))
				.pipe(skipWhile(() => !this.baseCustomsFields))
				.subscribe((schedules) => {
					this.sessions = schedules;
					if (this.sessions) {
						if (
							(this.eventUserProfile && this.eventUserProfile.type === TypeUser.SPEAKER) ||
							(this.eventUserProfile && this.eventUserProfile.type === TypeUser.ATTENDEE)
						) {
							this.eventUserSessions = this.sessions
								.filter((session) =>
									session.customFields.some((cus) =>
										this.baseCustomsFields?.find(
											(baseCus) =>
												baseCus.uid === cus.uid &&
												baseCus.type === TypeCustomFields.MODULE &&
												(baseCus.customFieldModuleType === TypeModule.SPEAKER ||
													baseCus.customFieldModuleType === TypeModule.ATTENDEE) &&
												cus.field?.module?.items.includes(this.eventUserProfile.uid)
										)
									)
								)
								.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
						}
						// filter this.eventUserSessions to get unique sessions
						this.eventUserSessions = this.eventUserSessions.filter(
							(session, index, self) => index === self.findIndex((t) => t.uid === session.uid)
						);

						this.sessions.forEach((session) => {
							!this.sessionModuleIds.includes(session.moduleId) &&
								this.sessionModuleIds.push(session.moduleId);
						});

						this.sessionModuleIds.forEach((sessionModuleId) => {
							this.subscriptions.push(
								this.store.select(getSpecificModule(sessionModuleId)).subscribe((sessionModule) => {
									if (sessionModule) {
										const existedModule = this.sessionModules.find(
											(module) => module.uid === sessionModule.uid
										);

										!existedModule
											? this.sessionModules.push(sessionModule)
											: this.sessionModules.splice(
													this.sessionModules.findIndex(
														(module) => module.uid === sessionModule.uid
													),
													1,
													sessionModule
											  );
									}
									this.cdr.markForCheck();
								})
							);
						});
						// if (this.moduleType === TypeModule.SCHEDULE) {
						// 	this.getCustomFieldsFromScheduleModule();
						// } else {
						// 	// Attendees and Speakers modules
						// 	this.getCustomFieldsFromUserModule();
						// }
					}
				})
		);
	}

	/**
	 * Getting all tracks
	 */
	getAllTracks() {
		this.subscriptions.push(
			this.store.select(getTracks).subscribe((tracks) => {
				this.tracks = tracks;
			})
		);
	}

	/**
	 * Get all locations
	 */
	getLocations() {
		this.subscriptions.push(
			this.store.select(getLocations("asc")).subscribe((locations) => {
				this.locations = locations;
			})
		);
	}

	/**
	 * Get my event user
	 */
	getMyEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((myEventUser) => {
				if (
					!this.SEventUsers.compareEventUsersWithoutConnectedProp(this.myEventUser, myEventUser) ||
					!this.myEventUser
				) {
					this.myEventUser = myEventUser;
					this.isProfilMode =
						(this.myEventUser &&
							this.eventUserProfile &&
							this.myEventUser.uid === this.eventUserProfile.uid) ||
						false;
					// this.cdr.markForCheck();
				}
			})
		);
	}

	/**
	 * Get custom fields from "user" module
	 */
	getCustomFieldsFromUserModule() {
		if (this.eventUserProfile) {
			// Edit mode
			this.subscriptions.push(
				combineLatest([
					this.store.select(getBaseCustomFields),
					this.store.select(getModulesCustomsFieldsOfModule(this.module.uid)),
					this.store.select(getModulesCustomFields),
					this.SSchedules.getAllSessionsOfModulesFromFile()
					// this.store.select(getAllSessions)
				])
					.pipe(
						skipWhile(() => !this.sessions),
						take(1)
					)
					// .pipe(skipWhile(() => !this.sessions))
					.subscribe((results) => {
						this.baseCustomsFields = results[0];
						this.allCustomFieldsModules = results[2];
						this.sessions = results[3];
						this.computedCustomFields = [];
						this.computedCFText = [];
						this.computedCFHtml = [];
						this.multipleTextCF = [];
						this.computedImageCF = [];
						this.computedFileCF = [];
						this.multipleSelectCF = [];
						this.computedCFJob_Title = [];
						this.computedModule = [];
						this.attendeesCustomField = [];
						this.speakersCustomField = [];

						if (results[1].length > 0) {
							results[1].forEach((customField) => {
								const baseCustomFieldCorresponding = results[0].find(
									(custField) => custField.uid === customField.uid
								);

								if (baseCustomFieldCorresponding && this.eventUserProfile) {
									const data = this.eventUserProfile.customFields.find(
										(data) => data?.uid === customField?.uid
									);

									if (data) {
										// HTML text field
										if (baseCustomFieldCorresponding.type === 3)
											this.computedCFHtml.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											});
										// Job and Title text field
										else if (
											(baseCustomFieldCorresponding.name?.EnUS === "Job" ||
												baseCustomFieldCorresponding.name?.EnUS === "Company") &&
											data.field.multiLanguageText[this.currentLanguage] &&
											data.field.multiLanguageText[this.currentLanguage] !== ""
										)
											this.computedCFJob_Title.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											});
										// Multiple choice fields
										else if (baseCustomFieldCorresponding.type === 2) {
											this.multipleSelectCF.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											});
										}

										// Image fields
										else if (baseCustomFieldCorresponding.type === this.typeCustomFields.IMAGE) {
											this.computedImageCF.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											} as IFullCustomField);
										}

										// File fields
										else if (baseCustomFieldCorresponding.type === this.typeCustomFields.FILE) {
											this.computedFileCF.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											} as IFullCustomField);
										}

										// Multiple text fields
										else if (baseCustomFieldCorresponding.type === 4) {
											this.multipleTextCF.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											} as IFullCustomField);
										}

										// Module type field
										else if (
											baseCustomFieldCorresponding.type === 12 &&
											data.field.module.items &&
											data.field.module.items?.length > 0
										) {
											this.computedModule.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											} as IFullCustomField);

											if (
												!this.customFieldsScheduleDatas.find(
													(cus) => cus.uid === baseCustomFieldCorresponding.uid
												)
											) {
												this.customFieldsScheduleDatas.push({
													uid: baseCustomFieldCorresponding.uid,
													sessions: this.sessions.filter((session) => {
														return (this.module.type === TypeModule.ATTENDEE ||
															this.module.type === TypeModule.SPEAKER) &&
															this.eventUserProfile?.customFields.find(
																(cus) =>
																	cus.uid === baseCustomFieldCorresponding.uid &&
																	cus.field.module.items.includes(session.uid)
															)
															? true
															: false;

														// : this.eventUserProfile?.customFields.find(
														// 		(cus) =>
														// 			cus.uid === baseCustomFieldCorresponding.uid &&
														// 			cus.field.module.items.includes(session.uid)
														//   )
														// ? true
														// : false;
													})
												});
											}
										}

										// Other text field
										else {
											this.computedCFText.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											});
										}

										this.computedCustomFields.push({
											baseSettings: baseCustomFieldCorresponding,
											moduleSettings: customField,
											fieldDatas: data
												? data
												: {
														uid: "",
														field: {} as IField
												  }
										});
									}

									// this.computedCustomFields.push({
									// 	baseSettings: baseCustomFieldCorresponding,
									// 	moduleSettings: customField,
									// 	fieldDatas: data
									// 		? data
									// 		: {
									// 				uid: "",
									// 				field: {} as IField
									// 		  }
									// });
								}
							});
							const computedCustomFieldForAttendees = this.computedCustomFields.filter(
								(custom) =>
									((!custom.moduleSettings.hiding.profil && this.isProfilMode) ||
										(!custom.moduleSettings.hiding.list && !this.isProfilMode)) &&
									custom.baseSettings.type === this.typeCustomFields.MODULE &&
									custom.baseSettings?.customFieldModuleType === this.typeModule.ATTENDEE &&
									custom.fieldDatas.field.module.items &&
									custom.fieldDatas.field.module.items.length > 0
							);

							if (computedCustomFieldForAttendees.length > 0) {
								const itemsIds = _.uniq(
									_.flatten(
										computedCustomFieldForAttendees.map((computedCf) => {
											return computedCf.fieldDatas.field.module.items;
										})
									)
								);
								if (this.attendeesSub && !this.attendeesSub.closed) {
									this.attendeesSub.unsubscribe();
								}
								if (itemsIds.length > 0) {
									this.SEventUsers.getSpecificEventUsersForEvent(
										this.event.uid,
										TypeUser.ATTENDEE,
										itemsIds
									)
										.pipe(take(1))
										.subscribe((attendees) => {
											attendees.forEach((attendee) => {
												if (
													!this.attendeesCustomField.find(
														(eventUser) => eventUser.uid === attendee.uid
													)
												) {
													this.attendeesCustomField.push(attendee);
												}
											});

											this.cdr.markForCheck();
										});
								}
							}

							const computedCustomFieldForSpeakers = this.computedCustomFields.filter(
								(custom) =>
									((!custom.moduleSettings.hiding.profil && this.isProfilMode) ||
										(!custom.moduleSettings.hiding.list && !this.isProfilMode)) &&
									custom.baseSettings.type === this.typeCustomFields.MODULE &&
									custom.baseSettings?.customFieldModuleType === this.typeModule.SPEAKER &&
									custom.fieldDatas.field.module.items &&
									custom.fieldDatas.field.module.items.length > 0
							);
							if (computedCustomFieldForSpeakers.length > 0) {
								const itemsIds = _.uniq(
									_.flatten(
										computedCustomFieldForSpeakers.map((computedCf) => {
											return computedCf.fieldDatas.field.module.items;
										})
									)
								);
								if (this.speakersSub && !this.speakersSub.closed) {
									this.speakersSub.unsubscribe();
								}
								if (itemsIds.length > 0) {
									this.SEventUsers.getSpecificEventUsersForEvent(
										this.event.uid,
										TypeUser.SPEAKER,
										itemsIds
									)
										.pipe(take(1))
										.subscribe((speakers) => {
											speakers.forEach((speaker) => {
												if (
													!this.speakersCustomField.find(
														(eventUser) => eventUser.uid === speaker.uid
													)
												) {
													this.speakersCustomField.push(speaker);
												}
											});

											this.cdr.markForCheck();
										});
								}
							}
							this.cdr.markForCheck();
						}
					})
			);
		}
	}

	/**
	 * Get custom fields from "schedule" module
	 */
	getCustomFieldsFromScheduleModule() {
		if (this.scheduleId) {
			// Edit mode
			this.subscriptions.push(
				combineLatest([
					this.SSchedules.getSpecificSession(this.event.uid, this.module.uid, this.scheduleId),
					// this.store.select(getSpecificSession(this.scheduleId)),
					this.store.select(getBaseCustomFields),
					this.store.select(getModulesCustomsFieldsOfModule(this.module.uid)),
					this.store.select(getModulesCustomFields)
				])
					.pipe(
						skipWhile(() => !this.sessions || this.sessions.length === 0),
						take(1)
					)
					.subscribe((results) => {
						this.allCustomFieldsModules = results[3];
						this.schedule = results[0];

						this.computedCustomFields = [];
						this.computedCFText = [];
						this.computedCFHtml = [];
						this.multipleTextCF = [];
						this.multipleSelectCF = [];
						this.computedCFJob_Title = [];
						this.computedModule = [];
						this.modulesCustomsFields = results[2];

						if (results[2].length > 0) {
							results[2].forEach((customField) => {
								const baseCustomFieldCorresponding = results[1].find(
									(custField) => custField.uid === customField.uid
								);

								if (baseCustomFieldCorresponding) {
									const data = this.schedule.customFields.find(
										(data) => data.uid === customField.uid
									);

									if (data) {
										// HTML text field
										if (baseCustomFieldCorresponding.type === 3)
											this.computedCFHtml.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											});
										// Job and Title text field
										else if (
											(baseCustomFieldCorresponding.name?.EnUS === "Job" ||
												baseCustomFieldCorresponding.name?.EnUS === "Company") &&
											data.field.multiLanguageText[this.currentLanguage] &&
											data.field.multiLanguageText[this.currentLanguage] !== ""
										)
											this.computedCFJob_Title.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											});
										// Multiple choice fields
										else if (baseCustomFieldCorresponding.type === 2) {
											this.multipleSelectCF.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											});
										}

										// Multiple text fields
										else if (baseCustomFieldCorresponding.type === 4) {
											this.multipleTextCF.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											});
										}

										// Module type field
										else if (
											baseCustomFieldCorresponding.type === 12 &&
											data.field.module.items &&
											data.field.module.items.length > 0
										) {
											if (
												baseCustomFieldCorresponding.customFieldModuleType ===
												TypeModule.SCHEDULE
											) {
												if (
													!this.customFieldsScheduleDatas.find(
														(cus) => cus.uid === baseCustomFieldCorresponding.uid
													)
												) {
													this.customFieldsScheduleDatas.push({
														uid: baseCustomFieldCorresponding.uid,
														sessions: this.sessions.filter((session) => {
															return this.module.type === TypeModule.SCHEDULE
																? this.schedule?.customFields.find(
																		(cus) =>
																			cus.uid ===
																				baseCustomFieldCorresponding.uid &&
																			cus.field.module.items.includes(session.uid)
																  )
																: this.eventUserProfile?.customFields.find(
																		(cus) =>
																			cus.uid ===
																				baseCustomFieldCorresponding.uid &&
																			cus.field.module.items.includes(session.uid)
																  )
																? true
																: false;
														})
													});
												}
											}

											this.computedModule.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											});
										}
										// Other text field
										else if (
											baseCustomFieldCorresponding.type === TypeCustomFields.TEXT ||
											baseCustomFieldCorresponding.type === TypeCustomFields.SELECT ||
											baseCustomFieldCorresponding.type === TypeCustomFields.NUMERIC ||
											baseCustomFieldCorresponding.type === TypeCustomFields.URL ||
											baseCustomFieldCorresponding.type === TypeCustomFields.EMAIL ||
											baseCustomFieldCorresponding.type === TypeCustomFields.DATE
										) {
											this.computedCFText.push({
												baseSettings: baseCustomFieldCorresponding,
												moduleSettings: customField,
												fieldDatas: data
													? data
													: {
															uid: "",
															field: {} as IField
													  }
											});
										}

										this.computedCustomFields.push({
											baseSettings: baseCustomFieldCorresponding,
											moduleSettings: customField,
											fieldDatas: data
												? data
												: {
														uid: "",
														field: {} as IField
												  }
										});
									}
								}
							});
							const computedCustomFieldForAttendees = this.computedCustomFields.filter(
								(custom) =>
									((!custom.moduleSettings.hiding.profil && this.isProfilMode) ||
										(!custom.moduleSettings.hiding.list && !this.isProfilMode)) &&
									custom.baseSettings.type === this.typeCustomFields.MODULE &&
									custom.baseSettings?.customFieldModuleType === this.typeModule.ATTENDEE &&
									custom.fieldDatas.field.module.items &&
									custom.fieldDatas.field.module.items.length > 0
							);

							if (computedCustomFieldForAttendees.length > 0) {
								const itemsIds = _.uniq(
									_.flatten(
										computedCustomFieldForAttendees.map((computedCf) => {
											return computedCf.fieldDatas.field.module.items;
										})
									)
								);
								if (this.attendeesSub && !this.attendeesSub.closed) {
									this.attendeesSub.unsubscribe();
								}
								if (itemsIds.length > 0) {
									this.SEventUsers.getSpecificEventUsersForEvent(
										this.event.uid,
										TypeUser.ATTENDEE,
										itemsIds
									)
										.pipe(take(1))
										.subscribe((attendees) => {
											attendees.forEach((attendee) => {
												if (
													!this.attendeesCustomField.find(
														(eventUser) => eventUser.uid === attendee.uid
													)
												) {
													this.attendeesCustomField.push(attendee);
												}
											});

											this.cdr.markForCheck();
										});
								}
							}
							const computedCustomFieldForSpeakers = this.computedCustomFields.filter(
								(custom) =>
									((!custom.moduleSettings.hiding.profil && this.isProfilMode) ||
										(!custom.moduleSettings.hiding.list && !this.isProfilMode)) &&
									custom.baseSettings.type === this.typeCustomFields.MODULE &&
									custom.baseSettings?.customFieldModuleType === this.typeModule.SPEAKER &&
									custom.fieldDatas.field.module.items &&
									custom.fieldDatas.field.module.items.length > 0
							);

							if (computedCustomFieldForSpeakers.length > 0) {
								const itemsIds = _.uniq(
									_.flatten(
										computedCustomFieldForSpeakers.map((computedCf) => {
											return computedCf.fieldDatas.field.module.items;
										})
									)
								);
								if (this.speakersSub && !this.speakersSub.closed) {
									this.speakersSub.unsubscribe();
								}
								if (itemsIds.length > 0) {
									this.SEventUsers.getSpecificEventUsersForEvent(
										this.event.uid,
										TypeUser.SPEAKER,
										itemsIds
									)
										.pipe(take(1))
										.subscribe((speakers) => {
											speakers.forEach((speaker) => {
												if (
													!this.speakersCustomField.find(
														(eventUser) => eventUser.uid === speaker.uid
													)
												) {
													this.speakersCustomField.push(speaker);
												}
											});
											this.cdr.markForCheck();
										});
								}
							}
						}

						this.sessionSchedules = this.sessions.filter((session) => {
							return this.schedule?.customFields.find(
								(cus) =>
									cus.field.module &&
									cus.field.module.items &&
									cus.field.module.items.includes(session.uid)
							)
								? true
								: false;
						});

						this.cdr.markForCheck();
					})
			);
		}
	}

	/**
	 * Return custom module items for event users type
	 * @param itemsIds
	 * @param typeModule
	 * @returns
	 */
	returnCustomModuleItemsEventUsers(itemsIds: string[], typeModule: number): IEventUser[] {
		return typeModule === TypeModule.ATTENDEE
			? this.attendeesCustomField
					.filter((attendee) => itemsIds.includes(attendee.uid))
					.sort((a, b) =>
						a.name?.toLocaleLowerCase() > b.name?.toLocaleLowerCase()
							? 1
							: a.name?.toLocaleLowerCase() < b.name?.toLocaleLowerCase()
							? -1
							: 0
					)
			: this.speakersCustomField
					.filter((speaker) => itemsIds.includes(speaker.uid))
					.sort((a, b) =>
						a.name?.toLocaleLowerCase() > b.name?.toLocaleLowerCase()
							? 1
							: a.name?.toLocaleLowerCase() < b.name?.toLocaleLowerCase()
							? -1
							: 0
					);
	}

	/**
	 * returnCustomModuleItems
	 * @param itemsIds
	 * @param typeModule
	 */
	returnCustomModuleItems(itemsIds: string[], typeModule: number): any[] {
		return typeModule === TypeModule.DOCUMENT
			? this.customDocuments
					.filter((doc) => itemsIds.includes(doc.uid))
					.sort((a, b) =>
						a.name?.[this.event.language]?.toLocaleLowerCase() >
						b.name?.[this.event.language]?.toLocaleLowerCase()
							? 1
							: a.name?.[this.event.language]?.toLocaleLowerCase() <
							  b.name?.[this.event.language]?.toLocaleLowerCase()
							? -1
							: 0
					)
			: typeModule === TypeModule.TRACKS
			? this.tracks
					.filter((tra) => itemsIds.includes(tra.uid))
					.sort((a, b) =>
						a.name?.[this.event.language]?.toLocaleLowerCase() >
						b.name?.[this.event.language]?.toLocaleLowerCase()
							? 1
							: a.name?.[this.event.language]?.toLocaleLowerCase() <
							  b.name?.[this.event.language]?.toLocaleLowerCase()
							? -1
							: 0
					)
			: this.sessions
					.filter((ses) => itemsIds.includes(ses.uid))
					.sort((a, b) =>
						a.name?.[this.event.language]?.toLocaleLowerCase() >
						b.name?.[this.event.language]?.toLocaleLowerCase()
							? 1
							: a.name?.[this.event.language]?.toLocaleLowerCase() <
							  b.name?.[this.event.language]?.toLocaleLowerCase()
							? -1
							: 0
					);
	}

	/**
	 * checkMultiSelectDatas
	 * @param array
	 * @param language
	 * @returns
	 */
	checkMultiSelectDatas(array: ILanguage[], language: string): boolean {
		return array.filter((item) => item[language]).length > 0;
	}

	/**
	 * getEventUserTags
	 * @param eventUser
	 * @returns
	 */
	getEventUserTags(eventUser: IEventUser): ICustomFieldData[] {
		return eventUser.customFields
			.filter((eventUserCustomField) => {
				const allCustomFieldsModules = this.allCustomFieldsModules
					.filter((customFieldModule) => customFieldModule.moduleId === eventUser.moduleId)
					.slice();

				return allCustomFieldsModules.find(
					(customFieldModule) =>
						customFieldModule.uid === eventUserCustomField.uid &&
						customFieldModule.canBeTag &&
						(eventUserCustomField.field.numeric ||
							(eventUserCustomField.field.text && eventUserCustomField.field.text !== "") ||
							(eventUserCustomField.field.multiLanguageText &&
								eventUserCustomField.field.multiLanguageText[this.currentLanguage] !== "") ||
							(eventUserCustomField.field.phoneNumber &&
								eventUserCustomField.field.phoneNumber.nationalNumber !== ""))
				)
					? true
					: false;
			})
			.sort((a, b) =>
				this.getCustomFieldOrder(a.uid, eventUser.moduleId) >
				this.getCustomFieldOrder(b.uid, eventUser.moduleId)
					? 1
					: this.getCustomFieldOrder(a.uid, eventUser.moduleId) <
					  this.getCustomFieldOrder(b.uid, eventUser.moduleId)
					? -1
					: 0
			);
	}

	/**
	 * getCustomFieldOrder
	 */
	getCustomFieldOrder(customId: string, moduleId: string): number {
		return this.allCustomFieldsModules.find((cus) => cus.uid === customId && cus.moduleId === moduleId).order;
	}

	getPartOfDate(date: string, unit: string) {
		return this.SUtility.getPartOfDate(this.event, null, date, unit);
	}

	/************************************************************************************************
										Openning item functions 
	************************************************************************************************/

	/**
	 * openUserProfil
	 * @param eventUser
	 */
	openUserProfil(user: IEventUser): void {
		const path = `event/${this.event.uid}/profile/${user.moduleId}/${user.uid}`.split("/");
		this.navCtrl.navigateForward(path);
	}

	/**
	 * openSession
	 * @description redirect to session's page
	 * @param session
	 */

	openSession(session: ISchedule): void {
		const path = `/event/${this.event.uid}/schedule/${session.moduleId}/session/${session.uid}`.split("/");
		this.navCtrl.navigateForward(path);
	}

	/**
	 * openDocument
	 * @param document
	 */
	async openDocument(document: any, type: string) {
		try {
			if (document.type.includes("office")) {
				// download the document
				const base64Data = await this.SDocument.urlToBase64(document.url);
				await this.SDocument.downloadFile(document.name, base64Data, "");
			} else {
				await Browser.open({
					url: document.url,
					windowName: document.name,
					presentationStyle: "popover"
				});
			}

			type === "module" &&
				this.SAnalytics.customFieldDocumentOpended(this.event.uid, this.eventUserProfile, document);
		} catch (error) {
			this.SUtility.presentToast(
				this.STranslate.instant("errors.error.error-open-document"),
				3000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * Check if we should display related event users
	 * @returns
	 */
	showRelatedDatas(computedCustomField: IFullCustomField, type: TypeModule) {
		return (
			(((this.module.type === TypeModule.ATTENDEE || this.module.type === TypeModule.SPEAKER) &&
				((!computedCustomField.moduleSettings.hiding.profil && this.isProfilMode) ||
					(!computedCustomField.moduleSettings.hiding.list && !this.isProfilMode))) ||
				(this.module.type === TypeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general)) &&
			computedCustomField.baseSettings.type === TypeCustomFields.MODULE &&
			computedCustomField.baseSettings.customFieldModuleType === type &&
			computedCustomField.fieldDatas.field.module &&
			computedCustomField.fieldDatas.field.module.items &&
			computedCustomField.fieldDatas.field.module.items.length > 0
		);
	}

	/**
	 * getSessionsOfCustomField
	 * @param customField
	 * @param typeModule
	 * @returns
	 */
	getSessionsOfCustomField(customFieldId: string): ISchedule[] {
		return this.customFieldsScheduleDatas.find((cus) => cus.uid === customFieldId)?.sessions;
	}

	canShowItem(custom: IFullCustomField) {
		switch (custom.baseSettings.type) {
			// Simple text fields
			case TypeCustomFields.URL || TypeCustomFields.EMAIL:
				return (
					((this.module.type === TypeModule.SCHEDULE && custom.moduleSettings.hiding.general) ||
						((this.module.type === TypeModule.ATTENDEE || this.module.type === TypeModule.SPEAKER) &&
							!custom.moduleSettings.canBeTag &&
							((!custom.moduleSettings.hiding.profil && this.isProfilMode) ||
								(!custom.moduleSettings.hiding.list && !this.isProfilMode)))) &&
					custom.fieldDatas.field.text &&
					custom.fieldDatas.field.text !== ""
				);

			// Multiple text fields
			case TypeCustomFields.TEXT || TypeCustomFields.SELECT:
				return (
					((this.module.type === TypeModule.SCHEDULE && custom.moduleSettings.hiding.general) ||
						((this.module.type === TypeModule.ATTENDEE || this.module.type === TypeModule.SPEAKER) &&
							!custom.moduleSettings.canBeTag &&
							((!custom.moduleSettings.hiding.profil && this.isProfilMode) ||
								(!custom.moduleSettings.hiding.list && !this.isProfilMode)))) &&
					custom.fieldDatas.field.multiLanguageText &&
					custom.fieldDatas.field.multiLanguageText[this.currentLanguage] !== ""
				);

			// Multi select text fields
			case TypeCustomFields.MULTI_SELECT:
				return (
					((this.module.type === TypeModule.SCHEDULE && custom.moduleSettings.hiding.general) ||
						((this.module.type === TypeModule.ATTENDEE || this.module.type === TypeModule.SPEAKER) &&
							!custom.moduleSettings.canBeTag &&
							((!custom.moduleSettings.hiding.profil && this.isProfilMode) ||
								(!custom.moduleSettings.hiding.list && !this.isProfilMode)))) &&
					custom.fieldDatas.field.multiLanguageSelectArray &&
					custom.fieldDatas.field.multiLanguageSelectArray.length > 0 &&
					this.checkMultiSelectDatas(custom.fieldDatas.field.multiLanguageSelectArray, this.currentLanguage)
				);

			// Multi-text text fields
			case TypeCustomFields.MULTI_TEXT:
				return (
					((this.module.type === TypeModule.SCHEDULE && custom.moduleSettings.hiding.general) ||
						((this.module.type === TypeModule.ATTENDEE || this.module.type === TypeModule.SPEAKER) &&
							!custom.moduleSettings.canBeTag &&
							((!custom.moduleSettings.hiding.profil && this.isProfilMode) ||
								(!custom.moduleSettings.hiding.list && !this.isProfilMode)))) &&
					custom.fieldDatas.field.multiLanguageTextArray[this.currentLanguage] &&
					custom.fieldDatas.field.multiLanguageTextArray[this.currentLanguage].length > 0
				);
		}
	}

	/**
	 * Check session date and given date
	 * @param session
	 * @param date
	 * @returns
	 */
	checkSessionDate(session: ISchedule, date: string) {
		return this.compareDates(session.startDate, date) ? true : false;
		// return DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(date), "day") &&
		// 	DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(date), "year") &&
		// 	DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(date), "month")
		// 	? true
		// 	: false;
	}

	compareDates(dateOne: string, dateTwo: string) {
		return (
			this.SUtility.getDateOnConfigTimezone(this.event, this.myEventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.myEventUser, dateTwo),
				"day"
			) &&
			this.SUtility.getDateOnConfigTimezone(this.event, this.myEventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.myEventUser, dateTwo),
				"year"
			) &&
			this.SUtility.getDateOnConfigTimezone(this.event, this.myEventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.myEventUser, dateTwo),
				"month"
			)
		);
	}

	/**
	 * openDownloadModal
	 * @param session
	 */
	async openDownloadModal(session: ISchedule, event, module?: IModule) {
		try {
			event.stopPropagation();
			const modal = await this.modalCtrl.create({
				component: ConfirmIcsDownloadComponent,
				componentProps: {
					event: this.event,
					module: this.module,
					eventUser: this.myEventUser,
					session: session,
					sessions: this.sessions,
					language:
						this.myEventUser && this.myEventUser.updatedSettings.language
							? this.myEventUser.updatedSettings.language
							: this.event.language,
					mode: "session"
				},
				mode: "ios",
				canDismiss: true,
				cssClass: this.isMobile ? "confirm-ics-download-modal-mobile" : "confirm-ics-download-modal"
			});

			modal.present();
		} catch (error) {
			// snackbar error
		}
	}

	async copyToClipboard(document: any) {
		try {
			await Clipboard.write({
				string: document.url
			});
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.copied-to-clipboard"),
				3000,
				"bottom",
				"success"
			);
		} catch (error) {
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.cannot-copy-to-clipboard"),
				3000,
				"bottom",
				"danger"
			);
		}
	}

	/************************************************************************************************
										Openning file functions END
	************************************************************************************************/
}
