import { Injectable } from "@angular/core";
import { Firestore, where, writeBatch, doc } from "@angular/fire/firestore";
import { Subscription, map, switchMap, catchError, of, firstValueFrom, lastValueFrom, Subject } from "rxjs";
import {
	ICardExchangeField,
	ICardExchangeFieldResultData,
	ICardExchangeForm
} from "../interfaces/card-exchange.interfaces";
import { FirestoreService } from "./firestore.service";
import * as _ from "lodash";
import { EventUsersService } from "./event-users.service";
import { Store } from "@ngrx/store";
import { QueryConstraint } from "@firebase/firestore";
import { BarcodeScanner, ScanResult } from "@capacitor-community/barcode-scanner";
import { TranslateService } from "@ngx-translate/core";
import { GetScanning } from "../actions/utility.actions";
import { UtilityService } from "./utility.service";
import { IEvent, IEventUser, IFullCustomField, IModule } from "../interfaces";
import { Platform } from "@ionic/angular";
import { DocumentsService } from "./documents.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomFieldsService } from "./custom-fields.service";
import { getModulesByType } from "../selectors/modules.selectors";
import { TypeModule } from "../enums/type-module";

@Injectable({
	providedIn: "root"
})
export class CardExchangeService {
	formsSub: Subscription;
	cardExchangeScanQrSubject: Subject<boolean> = new Subject();

	constructor(
		private firestore: Firestore,
		private SFirestore: FirestoreService,
		private SEventUsers: EventUsersService,
		private store: Store,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private platform: Platform,
		private SDocuments: DocumentsService,
		private snackbar: MatSnackBar,
		private SCustomFields: CustomFieldsService
	) {}

	/**
	 * getSpecificForm
	 * @param eventId
	 * @param moduleId
	 * @param formId
	 * @returns
	 */
	getSpecificForm(eventId: string, moduleId: string, formId: string) {
		return this.SFirestore.getDocumentObs(
			`events/${eventId}/modules/${moduleId}/card-exchange-forms/${formId}`
		).pipe(map((doc) => doc.data() as ICardExchangeForm));
	}

	/**
	 * getSpecificFormByGroupId
	 * @param eventId
	 * @param moduleId
	 * @param groupIds
	 * @returns
	 */
	getSpecificFormByGroupId(eventId: string, moduleId: string, groupIds: string[]) {
		if (!groupIds || groupIds.length === 0) return of(null);

		return this.SFirestore.getDocumentsObs(`events/${eventId}/modules/${moduleId}/card-exchange-forms`, [
			where("visibility", "==", true),
			where("groupIds", "array-contains-any", groupIds)
		]).pipe(map((snapshot) => (snapshot.docs[0]?.exists ? (snapshot.docs[0].data() as ICardExchangeForm) : null)));
	}

	/**
	 * getUserContacts
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @returns
	 */
	getUserContacts(contactIds: string[] = []) {
		if (!contactIds || contactIds.length === 0 || !this.SEventUsers.eventUsersOfAllModules()) return [];

		return this.SEventUsers.eventUsersOfAllModules().filter((user) => contactIds?.includes(user.uid));
	}

	/**
	 * getUserContactsWithPagination
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @param queryConstraint
	 * @returns
	 */
	getUserContactsWithPagination(eventId: string, contactIds: string[] = [], queryConstraint?: QueryConstraint[]) {
		if (!eventId || !contactIds) return of([]);

		return this.SEventUsers.getSpecificsEventUsersPaginated(eventId, contactIds, queryConstraint);
	}

	/**
	 * getSpecificContactFormDatasOfUser
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @param contactId
	 * @returns
	 */
	getSpecificContactFormDatasOfUser(eventId: string, moduleId: string, userId: string, contactId: string) {
		return this.SFirestore.getDocumentsObs(
			`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas/`,
			[where("contactId", "==", contactId)]
		).pipe(
			map((payload) => payload.docs.map((doc) => doc.data() as ICardExchangeFieldResultData)),
			catchError((_) => of([]))
		);
	}

	/**
	 * createOrUpdateCardExchangeResultDatas
	 * @param eventId
	 * @param moduleId
	 * @param eventUserId
	 * @param datas
	 * @returns
	 */
	async createOrUpdateCardExchangeResultDatas(
		eventId: string,
		moduleId: string,
		userId: string,
		datas: ICardExchangeFieldResultData[]
	) {
		try {
			const batch = this.SFirestore.getBatch();
			for (const data of datas) {
				// Check if there is a data with data.fieldId, if so, update them, else create them
				const existedData = (
					await firstValueFrom(
						this.SFirestore.getDocumentsObs(
							`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas`,
							[where("fieldId", "==", data.fieldId), where("contactId", "==", data.contactId)]
						)
					)
				)?.docs?.[0]?.data() as ICardExchangeFieldResultData;

				if (existedData) {
					const ref = doc(
						this.firestore,
						// eslint-disable-next-line max-len
						`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas/${existedData.uid}`
					);
					batch.update(
						ref,
						_.omit(
							existedData.type === "oneSelect"
								? { oneSelect: data.oneSelect }
								: existedData.type === "date"
								? { date: data.date }
								: existedData.type === "evaluation"
								? { evaluation: data.evaluation }
								: existedData.type === "document"
								? { document: data.document }
								: existedData.type === "multipleSelect"
								? { multipleSelect: data.multipleSelect }
								: { dissertative: data.dissertative },
							["userId", "contactId", "creationDate", "fieldId", "formId", "type"]
						)
					);
				} else {
					const ref = doc(
						this.firestore,
						`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas/${data.uid}`
					);

					batch.set(ref, data);
				}
			}
			return batch.commit();
		} catch (error) {
			console.error("🚀 ~ CardExchangeService ~ error:", error);
		}
	}

	/**
	 * removeCardExchangeResultDatas
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @param contactId
	 * @returns
	 */
	removeCardExchangeResultDatas(eventId: string, moduleId: string, userId: string, contactId: string) {
		// get all datas realted to the contactId
		return lastValueFrom(
			this.SFirestore.getDocumentsObs(
				`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas`,
				[where("contactId", "==", contactId)]
			).pipe(
				switchMap((snapshot) => {
					const batch = writeBatch(this.firestore);
					snapshot.docs.forEach((doc) => {
						batch.delete(doc.ref);
					});
					return batch.commit();
				})
			)
		);
	}

	/**
	 * updateCardExchangeResultDatas
	 * @param eventId
	 * @param moduleId
	 * @param formId
	 * @param userId
	 * @param results
	 * @returns
	 */
	updateCardExchangeResultDatas(
		eventId: string,
		moduleId: string,
		userId: string,
		datas: ICardExchangeFieldResultData[]
	) {
		const batch = writeBatch(this.firestore);
		datas.forEach((data) => {
			const ref = doc(
				this.firestore,
				`events/${eventId}/modules/${moduleId}/event-users/${userId}/card-exchange-datas/${data.uid}`
			);
			batch.update(ref, _.omit(data, ["formId", "fieldId", "userId", "creationDate", "type"]));
		});
		return batch.commit();
	}

	/**
	 * perfomScanQr
	 * @returns
	 */
	async perfomScanQr(): Promise<ScanResult> {
		try {
			const allowed = await this.checkPermission();
			if (allowed) {
				BarcodeScanner.hideBackground(); // make background of WebView transparent
				const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

				// if the result has content
				if (result.hasContent) {
					return result;
				}

				throw new Error("No content found in the QR code.");
			} else {
				throw new Error("Permission denied.");
			}
		} catch (error) {
			console.error("🚀 ~ perfomScanQr ~ error:", error);
			this.store.dispatch(GetScanning({ payload: false }));

			const alert = await this.SUtility.presentAlert(
				this.STranslate.instant("alerts.error_scanning"),
				this.STranslate.instant("alerts.no_camera"),
				[
					{
						text: this.STranslate.instant("buttons.yes"),
						handler: () => {
							this.perfomScanQr();
						}
					},
					{
						text: this.STranslate.instant("buttons.no"),
						role: "cancel"
					}
				]
			);
			alert.present();
		}
	}

	/**
	 * checkPermission
	 * @returns
	 */
	async checkPermission() {
		const status = await BarcodeScanner.checkPermission({ force: true });
		if (status.granted) {
			return true;
		} else if (status.denied) {
			BarcodeScanner.openAppSettings();
			return false;
		}
	}

	/**
	 * downloadVCard
	 * @param eventUser
	 */
	async downloadVcard(
		event: IEvent,
		module: IModule,
		authenticateEventUser: IEventUser,
		eventUserToDownload: IEventUser,
		customFields: IFullCustomField[],
		formFields: ICardExchangeField[],
		domEvent
	) {
		const eventUser = _.cloneDeep(eventUserToDownload);
		domEvent?.stopPropagation();
		try {
			const eventUserUpdatedSettings = await firstValueFrom(
				this.SEventUsers.getSpecificEventUserUpdatedSettings(
					eventUser.eventId,
					eventUser.moduleId,
					eventUser.uid
				)
			);

			if (!formFields) {
				const cardExchangeModule = (
					await firstValueFrom(this.store.select(getModulesByType(TypeModule.CARD_EXCHANGE)))
				)?.[0];
				if (!cardExchangeModule) throw new Error("No card exchange module found");
				const form = await firstValueFrom(
					this.getSpecificFormByGroupId(event.uid, cardExchangeModule.uid, authenticateEventUser.groups)
				);
				console.log("🚀 ~ CardExchangeService ~ form:", form);
				if (form) {
					formFields = form.fields;
				}
			}

			if (!eventUserUpdatedSettings) throw new Error("No updated settings found for this user");

			eventUser.updatedSettings = eventUserUpdatedSettings;

			const { baseInformations } = this.SCustomFields.getCustomFieldsCardDatas(
				eventUser,
				module,
				customFields,
				event.language
			);

			const fieldsDatas = formFields
				? ((await firstValueFrom(
						this.getSpecificContactFormDatasOfUser(
							event.uid,
							authenticateEventUser.moduleId,
							authenticateEventUser.uid,
							eventUser.uid
						)
				  )) as ICardExchangeFieldResultData[])
				: null;

			const fields = fieldsDatas
				?.map((data) => this.getFieldDataValue(data, formFields, event.language))
				?.filter((data) => data);

			const { eventUserJob, eventUserCompany, eventUserPhoneNumber, eventUserAddress } = baseInformations;

			const eventUserPhotoData =
				eventUser.photoUrl !== ""
					? await firstValueFrom(this.SDocuments.convertFileUrlToBase64(eventUser.photoUrl))
					: null;

			let vCardData = `BEGIN:VCARD
					VERSION:3.0
					N:${
						eventUser.name.split(" ").length > 1
							? eventUser.name.split(" ")[0] + ";" + eventUser.name.split(" ").slice(1).join(" ")
							: eventUser.name
					};;;
					FN:${eventUser.name};
					X-SOCIALPROFILE;type=Facebook:${
						(module.options.enableUserFieldsHideAbility &&
							(eventUser?.updatedSettings?.fieldsVisibility?.["facebook"] === false ||
								(!eventUser?.updatedSettings?.fieldsVisibility &&
									module.options.requiredFields?.["facebook"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser?.socialMedias.facebook
							: ""
					}
					X-SOCIALPROFILE;type=Linkedin:${
						(module.options.enableUserFieldsHideAbility &&
							(eventUser?.updatedSettings?.fieldsVisibility?.["linkedin"] === false ||
								(!eventUser?.updatedSettings?.fieldsVisibility &&
									module.options.requiredFields?.["linkedin"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser?.socialMedias.linkedin
							: ""
					}
					X-SOCIALPROFILE;type=Twitter:${
						(module.options.enableUserFieldsHideAbility &&
							(eventUser?.updatedSettings?.fieldsVisibility?.["twitter"] === false ||
								(!eventUser?.updatedSettings?.fieldsVisibility &&
									module.options.requiredFields?.["twitter"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser?.socialMedias.twitter
							: ""
					}
					X-SOCIALPROFILE;type=Instagram:${
						(module.options.enableUserFieldsHideAbility &&
							(eventUser?.updatedSettings?.fieldsVisibility?.["instagram"] === false ||
								(!eventUser?.updatedSettings?.fieldsVisibility &&
									module.options.requiredFields?.["instagram"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser?.socialMedias.instagram
							: ""
					}
					X-SOCIALPROFILE;type=Website:${
						(module.options.enableUserFieldsHideAbility &&
							(eventUser?.updatedSettings?.fieldsVisibility?.["website"] === false ||
								(!eventUser?.updatedSettings?.fieldsVisibility &&
									module.options.requiredFields?.["website"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser?.socialMedias.website
							: ""
					}
					ORG:${
						eventUser?.updatedSettings && eventUserCompany
							? eventUserCompany[eventUser?.updatedSettings?.language] &&
							  eventUserCompany[eventUser?.updatedSettings?.language] !== ""
								? eventUserCompany[eventUser?.updatedSettings?.language]
								: eventUserCompany?.[event.language] ?? ""
							: eventUserCompany?.[event.language] ?? ""
					};
					TITLE:${
						eventUser?.updatedSettings && eventUserJob
							? eventUserJob[eventUser?.updatedSettings?.language] &&
							  eventUserJob[eventUser?.updatedSettings?.language] !== ""
								? eventUserJob[eventUser?.updatedSettings?.language]
								: eventUserJob?.[event.language] ?? ""
							: eventUserJob?.[event.language] ?? ""
					}
					JOB:${
						eventUser?.updatedSettings && eventUserJob
							? eventUserJob[eventUser?.updatedSettings?.language] &&
							  eventUserJob[eventUser?.updatedSettings?.language] !== ""
								? eventUserJob[eventUser?.updatedSettings?.language]
								: eventUserJob?.[event.language] ?? ""
							: eventUserJob?.[event.language] ?? ""
					}
					X-JOB:${
						eventUser?.updatedSettings && eventUserJob
							? eventUserJob[eventUser?.updatedSettings?.language] &&
							  eventUserJob[eventUser?.updatedSettings?.language] !== ""
								? eventUserJob[eventUser?.updatedSettings?.language]
								: eventUserJob?.[event.language] ?? ""
							: eventUserJob?.[event.language] ?? ""
					}
					TEL:${eventUserPhoneNumber ? eventUserPhoneNumber : ""}
					EMAIL:${
						(module.options.enableUserFieldsHideAbility &&
							(eventUser?.updatedSettings?.fieldsVisibility?.["email"] === false ||
								(!eventUser?.updatedSettings?.fieldsVisibility &&
									module.options.requiredFields?.["email"]?.hiding?.default === false))) ||
						!module.options.enableUserFieldsHideAbility
							? eventUser.email
							: ""
					}
					ADR: ${eventUserAddress ? eventUserAddress : ""}
					NOTE:${
						fields
							? Object.entries(
									fields?.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {})
							  )
									.filter(([_, value]) => value !== "" && value !== null && value !== undefined)
									.map(([key, value]) => `${key}: ${value}`)
									.join(" | ")
							: ""
					}
					${eventUserPhotoData ? `PHOTO;ENCODING=b;TYPE=JPEG:${eventUserPhotoData}` : null}
					END:VCARD`;

			// Remove all tabulations
			vCardData = vCardData.replace(/\t/g, "");

			if (!this.platform.is("mobile")) {
				const element = document.createElement("a");
				element.setAttribute("href", "data:text/vcard;charset=utf-8," + encodeURIComponent(vCardData));
				element.setAttribute("download", `${eventUser.name}.vcf`);
				element.setAttribute("target", "_blank");
				element.style.display = "none";
				element.click();
			} else {
				this.SDocuments.downloadFile(`${eventUser.name}.vcf`, vCardData, "text/vcard");
			}
		} catch (error) {
			console.error("🚀 ~ CardExchangeService ~ error:", error);
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * getFieldDataValue
	 * @param data
	 * @param fields
	 * @param language
	 * @returns
	 */
	getFieldDataValue(
		data: ICardExchangeFieldResultData,
		fields: ICardExchangeField[],
		language: string
	): { name: string; value: string } {
		const field = fields.find((field) => field.uid === data.fieldId);
		if (!field) return null;

		if (data.type === "oneSelect") {
			return {
				name: field.name[language],
				value: field.answers.find((answer) => answer.uid === data.oneSelect.answerId)
					? field.answers.find((answer) => answer.uid === data.oneSelect.answerId)?.answer?.[language]
					: null
			};
		} else if (data.type === "multipleSelect") {
			return {
				name: field.name[language],
				value: field.answers
					.filter((answer) => data.multipleSelect.answersIds.includes(answer.uid))
					.map((answer) => answer.answer[language])
					.join(", ")
			};
		} else if (data.type === "dissertative" || data.type === "plainText") {
			return { name: field.name[language], value: data.dissertative };
		} else if (data.type === "date") {
			return { name: field.name[language], value: data.date };
		} else if (data.type === "document") {
			return { name: field.name[language], value: data.document.url };
		} else if (data.type === "evaluation") {
			return { name: field.name[language], value: data.evaluation.toString() };
		}
	}

	checkContact(eventUser: IEventUser, contactId: string) {
		return eventUser.contactIds?.includes(contactId);
	}
}
