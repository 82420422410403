import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { getApp, initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from "@angular/fire/auth";
import { getDatabase, provideDatabase } from "@angular/fire/database";
import { getFunctions, provideFunctions } from "@angular/fire/functions";
import {
	initializeFirestore,
	memoryLocalCache,
	persistentLocalCache,
	persistentMultipleTabManager,
	provideFirestore
} from "@angular/fire/firestore";
import { getStorage, provideStorage } from "@angular/fire/storage";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { StoreRouterConnectingModule, routerReducer } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Capacitor } from "@capacitor/core";
import { IonicStorageModule } from "@ionic/storage-angular";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { enterAnimation } from "./shared/animations/routing-animation";
import { OffsetTopDirective } from "./shared/directives/offset-top";
import { ScrollableDirective } from "./shared/directives/scrollable";
import { MaterialModule } from "./shared/global-modules/material-modules";
import {
	AuthGuard,
	EventBlockedGuard,
	EventGuard,
	EventsListGuard,
	LoginGuard,
	RegisterFormGuard,
	ShortcodeGuard
} from "./shared/guards";
import { PathComponents } from "./shared/paths/path-components";
import { PipesModule } from "./shared/pipes/pipes.modules";
import {
	chatsReducers,
	containersReducers,
	eventsReducers,
	genericsModulesDataReducers,
	modulesReducers,
	utilityReducers
} from "./shared/reducers";
import { authReducers } from "./shared/reducers/auth.reducers";
import {
	AnalyticsService,
	AppointmentsService,
	AuthService,
	CardExchangeService,
	ChatsService,
	CheckinsService,
	CustomFieldsService,
	CustomPagesService,
	DocumentsService,
	EventUsersService,
	EventsService,
	ExternalInteractivityService,
	FeedNewsService,
	FeedbacksService,
	FormsService,
	InteractivityService,
	LanguagesService,
	LastAccessService,
	LocationsService,
	LuxonService,
	ModulesManagerService,
	PointsService,
	QuizsService,
	RankingsService,
	ReportsService,
	ResultsService,
	SchedulesService,
	StateManagerService,
	StorageService,
	SurveysService,
	TracksService,
	TreasureHuntsService,
	UtilityService,
	VisiosService,
	WordCloudsService
} from "./shared/services";
import { NgChartsModule } from "ng2-charts";
import { GenericComponentsModule } from "./shared/generic-components/generic-components.module";

// translate module config
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
}

@NgModule({
	declarations: [
		AppComponent,
		PathComponents.login,
		PathComponents.resetPassword,
		PathComponents.eventsList,
		PathComponents.privacy,
		PathComponents.splash,
		PathComponents.termsOfUse,
		ScrollableDirective,
		OffsetTopDirective
	],
	imports: [
		BrowserModule,
		CommonModule,
		IonicModule.forRoot({
			navAnimation: enterAnimation,
			swipeBackEnabled: false
		}),
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		provideFirebaseApp(() => {
			const app = initializeApp(environment.firebase);
			if (Capacitor.isNativePlatform) {
				initializeAuth(app, {
					persistence: indexedDBLocalPersistence
				});
			}
			return app;
		}),
		provideAuth(() => getAuth()),
		provideFirestore(() => {
			// return environment.platform.persistence
			// 	?
			return initializeFirestore(getApp(), {
				localCache: memoryLocalCache()
			});
			// : initializeFirestore(getApp(), {
			// 		localCache: memoryLocalCache()
			//   });
		}),
		provideDatabase(() => getDatabase()),
		provideStorage(() => getStorage()),
		provideFunctions(() => {
			const app = initializeApp(environment.firebase);
			return getFunctions(app, environment.platform.region);
		}),
		IonicStorageModule.forRoot(),
		StoreModule.forRoot({
			auth: authReducers,
			chats: chatsReducers,
			containers: containersReducers,
			events: eventsReducers,
			genericsModulesData: genericsModulesDataReducers,
			modules: modulesReducers,
			router: routerReducer,
			utility: utilityReducers
		}),
		StoreRouterConnectingModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		PipesModule,
		ServiceWorkerModule.register("service-worker.js", {
			enabled: environment.production
		}),
		DragDropModule,
		NgChartsModule,
		GenericComponentsModule
	],

	providers: [
		// { provide: ErrorHandler, useClass: CustomErrorHandler },
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

		AuthGuard,
		EventBlockedGuard,
		EventGuard,
		RegisterFormGuard,
		EventsListGuard,
		LoginGuard,
		ShortcodeGuard,
		AnalyticsService,
		AppointmentsService,
		AuthService,
		CardExchangeService,
		ChatsService,
		CheckinsService,
		CustomFieldsService,
		CustomPagesService,
		DocumentsService,
		EventsService,
		EventUsersService,
		ExternalInteractivityService,
		FeedbacksService,
		FormsService,
		InteractivityService,
		LanguagesService,
		LastAccessService,
		LocationsService,
		LuxonService,
		ModulesManagerService,
		FeedNewsService,
		PointsService,
		QuizsService,
		RankingsService,
		ReportsService,
		ResultsService,
		SchedulesService,
		StateManagerService,
		StorageService,
		SurveysService,
		TracksService,
		TreasureHuntsService,
		UtilityService,
		VisiosService,
		WordCloudsService
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
