/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable no-constant-condition */
import { Component, EventEmitter, Input, AfterViewInit, OnInit, Output } from "@angular/core";
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { Browser } from "@capacitor/browser";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import CountryList from "country-list-with-dial-code-and-flag";
import * as _ from "lodash";
import { DateTime } from "luxon";
import { Subscription, combineLatest, debounceTime, distinctUntilChanged, from, of, switchMap, take, tap } from "rxjs";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeModule } from "src/app/shared/enums/type-module";
import { TypeUser } from "src/app/shared/enums/type-user";
import { IDocument, IEvent, IEventUser, IFullCustomField, IModule, ISchedule, ITrack } from "src/app/shared/interfaces";
import {
	ICustomField,
	ICustomFieldData,
	ICustomFieldModuleGroup,
	IField,
	IFile,
	IImage,
	IModuleCustomField,
	IMultipleTextField,
	IPlacesPredictions
} from "src/app/shared/interfaces/custom-fields.interfaces";
import { ILanguageArray } from "src/app/shared/interfaces/languages.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getDocuments } from "src/app/shared/selectors/documents.selectors";
import {
	getBaseCustomFields,
	getModulesCustomsFieldsForModuleForRegister,
	getModulesCustomsFieldsOfModule,
	getTracks
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import {
	CustomFieldsService,
	EventUsersService,
	FormsService,
	SchedulesService,
	UtilityService
} from "src/app/shared/services";
import { StorageService } from "src/app/shared/services/storage.service";
import { environment } from "src/environments/environment";
import { countriesIsoCode } from "src/app/shared/datas/countries-code";
import { SearchCountryField, CountryISO, PhoneNumberFormat } from "ngx-intl-tel-input";

@Component({
	selector: "app-custom-fields-edit",
	templateUrl: "./custom-fields-edit.component.html",
	styleUrls: ["./custom-fields-edit.component.scss"]
})
export class CustomFieldsEditComponent implements OnInit, AfterViewInit {
	@Input() event: IEvent;
	@Input() module: IModule;
	@Input() eventUserProfile: IEventUser;
	@Input() language: string;
	@Input() computedCustomFields: IFullCustomField[];
	@Output() validEvent: EventEmitter<boolean> = new EventEmitter();

	currentEventUserProfile: IEventUser;
	currentLanguage: string;
	loader: boolean = true;

	// Tiny API Key

	tinyInitDesktop: any = environment.platform.tinyMce.init;
	tinyInitMobile: any = environment.platform.tinyMce.initMobile;

	subscriptions: Subscription[] = [];
	formCustomFields: UntypedFormGroup;
	languages: string[] = [];
	selectedLangTab: string;

	// computedCustomFields: IFullCustomField[];
	typeCustomFields = TypeCustomFields;
	typeModule = TypeModule;

	baseCustomFields: ICustomField[];
	modulesCustomsFields: IModuleCustomField[];

	tracks: ITrack[] = [];
	sessions: ISchedule[] = [];
	customDocuments: IDocument[] = [];

	// MODULES FILTER PROPERTIES
	filteredAttendees: ICustomFieldModuleGroup[] = [];
	filteredSpeakers: ICustomFieldModuleGroup[] = [];
	filteredTracks: ICustomFieldModuleGroup[] = [];
	filteredSessions: ICustomFieldModuleGroup[] = [];
	customFilteredDocuments: ICustomFieldModuleGroup[] = [];

	selectedAttendees: ICustomFieldModuleGroup[] = [];
	selectedSpeakers: ICustomFieldModuleGroup[] = [];
	selectedTracks: ICustomFieldModuleGroup[] = [];
	selectedSessions: ICustomFieldModuleGroup[] = [];
	customSelectedDocuments: ICustomFieldModuleGroup[] = [];

	// MULTIPLE TEXT PROPERTIES
	multipleTextValues: IMultipleTextField[] = [];
	multipleTextInputError: {
		controlId: string;
		msg: string;
	}[] = [];

	// ADDRESS FIELD PROPERTIES
	placesPredictions: IPlacesPredictions[] = [];

	// FILES/ IMAGES PROPERTIES
	customFieldMediaItems: { uid: string; data: File }[] = [];
	readonly MAX_FILE_SIZE = 51200000; // 50Mo
	invalidSizeFile: boolean;

	isMobile = false;
	isValidUrl = false;
	isRegisterMode: boolean = false;

	locale = environment.platform.locale;
	actualIndexOption: number;
	changeOperation: { uid: string; operations: string[] }[] = [];
	countryList: any;
	moduleId: string;

	// Phone tel properties
	countriesIsoCode = countriesIsoCode;
	preferedCountries: CountryISO[] = [
		CountryISO.France,
		CountryISO.UnitedStates,
		CountryISO.UnitedKingdom,
		CountryISO.Spain,
		CountryISO.Switzerland,
		CountryISO.Belgium,
		CountryISO.Canada
	];
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	phoneInitiated = false;

	constructor(
		private platform: Platform,
		private SUtility: UtilityService,
		private fb: UntypedFormBuilder,
		private SEventUsers: EventUsersService,
		private store: Store,
		private storage: StorageService,
		public SCustomFields: CustomFieldsService,
		private sanitizer: DomSanitizer,
		private modalCtrl: ModalController,
		private snackbar: MatSnackBar,
		private SSchedules: SchedulesService,
		private SForms: FormsService,
		public STranslate: TranslateService
	) {
		if ((this.platform.is("mobile") && window.innerWidth < 768) || window.innerWidth < 768) this.isMobile = true;
		this.currentLanguage = this.STranslate.currentLang;
		this.formCustomFields = this.fb.group({});
	}

	ngOnInit() {
		this.languages = [];
		for (const key in this.event.languagesSettings) {
			if (this.event.languagesSettings[key]) {
				if (key === this.currentLanguage) {
					this.languages.unshift(key);
				} else {
					this.languages.push(key);
				}
			}
		}
		this.currentEventUserProfile = this.eventUserProfile;
		this.getEventUserModule();
		this.getEventUserLanguage();
		this.getCustomFields();
	}

	ngAfterViewInit() {
		this.computedCustomFields
			.filter(
				(cus) =>
					cus.moduleSettings.editionScope &&
					((cus.baseSettings.type === TypeCustomFields.FILE &&
						cus.fieldDatas.field &&
						cus.fieldDatas.field?.file &&
						cus.fieldDatas.field?.file.url &&
						cus.fieldDatas.field?.file.url !== "") ||
						(cus.baseSettings.type === TypeCustomFields.IMAGE &&
							cus.fieldDatas.field &&
							cus.fieldDatas.field?.image &&
							cus.fieldDatas.field?.image?.url &&
							cus.fieldDatas.field?.image?.url !== ""))
			)
			.forEach((customField) => {
				this.preloadCustomFieldFileOrImage(customField);
			});
	}

	destroyComponent() {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
		this.subscriptions = [];
	}

	/**
	 * getEventUserModule
	 */
	getEventUserModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.currentEventUserProfile.moduleId)).subscribe((module) => {
				this.module = module;

				// init user visibility ability if needed
				if (this.module && this.module?.options?.enableUserFieldsHideAbility) {
					this.SEventUsers.buildFieldsVisibility(this.event.uid, this.module.uid, this.eventUserProfile);
				}
			})
		);
	}

	/**
	 * getCustomFields
	 */
	getCustomFields() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(
					this.isRegisterMode
						? getModulesCustomsFieldsForModuleForRegister(this.eventUserProfile.moduleId)
						: getModulesCustomsFieldsOfModule(this.eventUserProfile.moduleId)
				)
			])
				// .pipe(skipWhile(() => !this.module))
				.subscribe((results) => {
					this.baseCustomFields = results[0];
					this.modulesCustomsFields = results[1];

					this.buildCustomFields();

					if (this.currentEventUserProfile) {
						this.initEditForm();
					}

					this.loader = false;
				})
		);
	}

	/**
	 * buildCustomFields
	 */
	buildCustomFields() {
		this.computedCustomFields = [];

		if (this.modulesCustomsFields.length > 0) {
			this.modulesCustomsFields
				.filter((cus) => cus.editionScope)
				.forEach((customField) => {
					const baseCustomFieldCorresponding = this.baseCustomFields.find(
						(custField) => custField.uid === customField.uid
					);

					if (baseCustomFieldCorresponding) {
						const data = this.currentEventUserProfile.customFields.find(
							(data) => data?.uid === customField?.uid
						);
						this.computedCustomFields.push({
							baseSettings: baseCustomFieldCorresponding,
							moduleSettings: customField,
							fieldDatas: data
								? data
								: {
										uid: "",
										field: {
											date: {
												day: 0,
												month: 0,
												year: 0,
												value: "",
												fullDateISO: "",
												zone: ""
											},
											file: {
												lastModifiedDate: "",
												name: "",
												type: "",
												url: "",
												size: 0
											},
											image: {
												format: "",
												lastModifiedDate: "",
												name: "",
												url: "",
												size: 0,
												isExternalUrl: false
											},
											module: {
												items: [],
												moduleType: -1
											},
											multiLanguageText: {
												ArAR: "",
												DeDE: "",
												EnUS: "",
												EsES: "",
												FrFR: "",
												PtBR: ""
											},
											multiLanguageSelectArray: [],
											multiLanguageTextArray: {
												ArAR: [],
												DeDE: [],
												EnUS: [],
												EsES: [],
												FrFR: [],
												PtBR: []
											},
											numeric: 0,
											phoneNumber: {
												countryCode: "",
												dialCode: "",
												e164Number: "",
												internationalNumber: "",
												nationalNumber: "",
												number: ""
											},
											text: "",
											checkbox: false,
											country: {
												name: "",
												dialCode: "",
												code: "",
												flag: ""
											}
										}
								  }
						});
					}
				});
		}
	}

	/**
	 * Getting all documents
	 */
	getDocuments() {
		this.subscriptions.push(
			this.store.select(getDocuments).subscribe((documents) => {
				this.customDocuments = documents;
			})
		);
	}

	/**
	 * Get user language
	 */
	getEventUserLanguage() {
		this.locale = this.SUtility.getLocale(
			this.currentEventUserProfile ? this.currentEventUserProfile.updatedSettings.language : this.currentLanguage
		);
	}

	/**
	 * Getting all schedules / sessions
	 */
	getAllSessions() {
		this.subscriptions.push(
			this.SSchedules.getAllSessionsOfModulesFromFile().subscribe((sessions) => {
				// this.store.select(getAllSessions).subscribe((schedules) => {
				this.sessions = sessions;
			})
		);
	}

	/**
	 * Getting all tracks
	 */
	getAllTracks() {
		this.subscriptions.push(
			this.store.select(getTracks).subscribe((tracks) => {
				this.tracks = tracks;
			})
		);
	}

	requiredLanguageValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const value = control.value;

			if (value && value[this.language]) {
				return null;
			}

			return { required: true };
		};
	}

	/**
	 * initEditForm
	 * @description init form input with their correponding datas
	 * @returns
	 */
	initEditForm() {
		for (const custom of this.computedCustomFields) {
			if (custom.moduleSettings.editionScope) {
				for (const langSetting of Object.entries(this.event.languagesSettings)) {
					if (langSetting[1] && langSetting[0] === this.currentLanguage) {
						const language = langSetting[0];
						// all "multi language" text control
						if (
							custom.baseSettings.type === TypeCustomFields.TEXT ||
							custom.baseSettings.type === TypeCustomFields.HMTL
						) {
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}_${language}`,

								new UntypedFormControl(
									custom.fieldDatas &&
									custom.fieldDatas.field &&
									custom.fieldDatas.field.multiLanguageText
										? custom.fieldDatas.field.multiLanguageText[this.currentLanguage]
										: "",
									custom.moduleSettings.required
										? [Validators.minLength(1), Validators.maxLength(100), Validators.required]
										: [Validators.minLength(1), Validators.maxLength(100)]
								)
							);
						} else if (custom.baseSettings.type === TypeCustomFields.SELECT) {
							// all "multi language" select control
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}_${language}`,

								new UntypedFormControl(
									custom.fieldDatas &&
									custom.fieldDatas.field &&
									custom.fieldDatas.field.multiLanguageText
										? custom.fieldDatas.field.multiLanguageText
										: null,
									custom.moduleSettings.required
										? [
												Validators.minLength(1),
												Validators.maxLength(100),
												this.requiredLanguageValidator()
										  ]
										: [Validators.minLength(1), Validators.maxLength(100)]
								)
							);

							this.changeOperation.push({
								uid: custom.baseSettings.uid,
								operations: custom.baseSettings.options.map((_) => "none")
							});
						} else if (
							custom.baseSettings.type === TypeCustomFields.URL ||
							custom.baseSettings.type === TypeCustomFields.EMAIL
						) {
							// all "single language" text control (picture & url)
							const urlRegex =
								// eslint-disable-next-line no-useless-escape, max-len
								"(http?://)?(https?://)?(www?)?([\\.{0,1}])?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w.-~:/?#[\\]@%!\\(\\)\\*\\+,;=.]*/?";

							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}`,

								new UntypedFormControl(
									custom.fieldDatas && custom.fieldDatas.field ? custom.fieldDatas.field.text : "",
									custom.baseSettings.type === TypeCustomFields.EMAIL
										? custom.moduleSettings.required
											? [Validators.required, Validators.email]
											: [Validators.email]
										: custom.moduleSettings.required
										? [Validators.required, Validators.pattern(urlRegex)]
										: [Validators.pattern(urlRegex)]
								)
							);
						} else if (custom.baseSettings.type === TypeCustomFields.DATE) {
							// "Date" control
							const rulesValidators = [];
							if (custom.moduleSettings.required) {
								rulesValidators.push(Validators.required);
							}

							const cusSettings = custom?.baseSettings;

							if (cusSettings?.enableDateRule) {
								rulesValidators;
								cusSettings.dateRule.minDayDateIsEnabled
									? rulesValidators.push(this.SForms.customMinDateValidator(DateTime.now().toISO()))
									: rulesValidators.push(
											this.SForms.customMinDateValidator(cusSettings.dateRule?.minDate)
									  );

								cusSettings.dateRule.maxDayDateIsEnabled
									? rulesValidators.push(this.SForms.customMaxDateValidator(DateTime.now().toISO()))
									: rulesValidators.push(
											this.SForms.customMaxDateValidator(cusSettings.dateRule?.maxDate)
									  );
							}

							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}`,

								new UntypedFormControl(
									custom.fieldDatas && custom.fieldDatas?.field.date.fullDateISO !== ""
										? DateTime.fromISO(custom.fieldDatas.field.date.fullDateISO).toString()
										: DateTime.fromISO(new Date().toISOString()).toString(),
									rulesValidators
								)
							);
						} else if (custom.baseSettings.type === TypeCustomFields.NUMERIC) {
							// "Number" type control
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}`,

								new UntypedFormControl(
									custom.fieldDatas && custom.fieldDatas.field ? custom.fieldDatas.field.numeric : 0,
									custom.moduleSettings.required
										? [Validators.required, Validators.max(999999999)]
										: [Validators.max(999999999)]
								)
							);
						} else if (custom.baseSettings.type === TypeCustomFields.PHONE) {
							// "Phone Number" type control
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}`,

								new UntypedFormControl(
									custom.fieldDatas && custom.fieldDatas.field && custom.fieldDatas.field?.phoneNumber
										? custom.fieldDatas.field.phoneNumber
										: null,
									custom.moduleSettings.required ? [Validators.required] : []
								)
							);
						} else if (
							custom.baseSettings.type === TypeCustomFields.MULTI_TEXT &&
							!this.multipleTextValues.find((item) => item.uid === custom.baseSettings.uid)
						) {
							custom.fieldDatas && custom.fieldDatas.field.multiLanguageTextArray === undefined
								? this.multipleTextValues.push({
										uid: custom.baseSettings.uid,
										multiLanguageTextArray: {
											ArAR: [],
											DeDE: [],
											EnUS: [],
											EsES: [],
											FrFR: [],
											PtBR: []
										} as ILanguageArray
								  })
								: this.multipleTextValues.push({
										uid: custom.baseSettings.uid,
										multiLanguageTextArray: custom.fieldDatas.field?.multiLanguageTextArray
								  });
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}_${language}`,

								new UntypedFormControl("", Validators.maxLength(70))
							);
						} else if (custom.baseSettings.type === TypeCustomFields.MULTI_SELECT) {
							// all array "multi language" select control
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}_${language}`,

								new UntypedFormControl(
									custom.fieldDatas && custom.fieldDatas.field.multiLanguageSelectArray
										? custom.fieldDatas.field.multiLanguageSelectArray
										: []
								)
							);
							this.changeOperation.push({
								uid: custom.baseSettings.uid,
								operations: custom.baseSettings.options.map((_) => "none")
							});
						} else if (custom.baseSettings.type === TypeCustomFields.IMAGE) {
							// Base input file
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}_base`,
								new UntypedFormControl("", custom.moduleSettings.required ? [Validators.required] : [])
							);

							// Image's URL text field
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}_URL`,
								new UntypedFormControl(
									custom.fieldDatas && custom.fieldDatas.field
										? custom.fieldDatas.field.image.name
										: "",
									custom.moduleSettings.required ? [Validators.required] : []
								)
							);
						} else if (custom.baseSettings.type === TypeCustomFields.FILE) {
							// all "file" field control
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}`,

								new UntypedFormControl(
									custom.fieldDatas && custom.fieldDatas.field ? "" : null,
									custom.moduleSettings.required ? [Validators.required] : []
								)
							);
						} else if (custom.baseSettings.type === TypeCustomFields.ADDRESS) {
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}`,

								new UntypedFormControl(
									custom.fieldDatas && custom.fieldDatas.field && custom.fieldDatas.field.address
										? custom.fieldDatas.field.address.fullAddress
										: "",
									custom.moduleSettings.required ? [Validators.required] : []
								)
							);

							this.placesPredictions.push({
								controlId: custom.baseSettings.uid,
								data: [],
								loading: false
							});

							this.formCustomFields
								.get(`${custom.baseSettings.uid}`)
								.valueChanges.pipe(
									tap((query) => {
										return query && query.trim() !== ""
											? (this.placesPredictions.find(
													(place) => place.controlId === custom.baseSettings.uid
											  ).loading = true)
											: (this.placesPredictions.find(
													(place) => place.controlId === custom.baseSettings.uid
											  ).loading = false);
									}),
									debounceTime(1500),
									distinctUntilChanged(
										(a: string, b: string) => a.trim().toLowerCase() === b.trim().toLowerCase()
									)
								)
								.subscribe((value) => {
									if (value && value.trim() !== "") {
										this.getPlacePredictions(value, custom.baseSettings.uid);
									} else {
										this.placesPredictions.find(
											(place) => place.controlId === custom.baseSettings.uid
										).data = [];
										this.placesPredictions.find(
											(place) => place.controlId === custom.baseSettings.uid
										).loading = false;
									}
								});
						} else if (custom.baseSettings.type === TypeCustomFields.CHECKBOX) {
							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}`,

								new UntypedFormControl(
									custom.fieldDatas && custom.fieldDatas.field
										? custom.fieldDatas.field.checkbox
										: false,
									custom.moduleSettings.required ? [Validators.required] : []
								)
							);
						} else if (custom.baseSettings.type === TypeCustomFields.COUNTRY) {
							this.countryList = CountryList.getList();
							let country = null;

							// check if country name exists
							if (custom.fieldDatas && custom.fieldDatas.field && custom.fieldDatas.field.country) {
								country = this.countryList.find(
									(data) => data.code === custom.fieldDatas.field.country.code
								);
							}

							this.formCustomFields.addControl(
								`${custom.baseSettings.uid}`,
								new UntypedFormControl(
									custom.fieldDatas.field && custom.fieldDatas.field.country && country
										? country.name
										: null,
									custom.moduleSettings.required ? [Validators.required] : []
								)
							);
						}
					} else {
						// if (this.formCustomFields.get(custom.baseSettings.name[this.currentLanguage])) return;
						if (langSetting[1]) {
							const language = langSetting[0];

							// all "multi language" text control
							if (
								custom.baseSettings.type === TypeCustomFields.TEXT ||
								custom.baseSettings.type === TypeCustomFields.HMTL
							) {
								this.formCustomFields.addControl(
									`${custom.baseSettings.uid}_${language}`,
									new UntypedFormControl(
										custom.fieldDatas.field && custom.fieldDatas.field.multiLanguageText
											? custom.fieldDatas.field.multiLanguageText[language]
											: "",
										custom.moduleSettings.required
											? [Validators.minLength(1), Validators.required]
											: [Validators.minLength(1)]
									)
								);
							} else if (custom.baseSettings.type === TypeCustomFields.SELECT) {
								// all "multi language" select control
								this.formCustomFields.addControl(
									`${custom.baseSettings.uid}_${language}`,

									new UntypedFormControl(
										custom.fieldDatas &&
										custom.fieldDatas.field &&
										custom.fieldDatas.field.multiLanguageText
											? custom.fieldDatas.field.multiLanguageText
											: null,
										custom.moduleSettings.required
											? [Validators.minLength(1), Validators.maxLength(100), Validators.required]
											: [Validators.minLength(1), Validators.maxLength(100)]
									)
								);
							} else if (
								custom.baseSettings.type === TypeCustomFields.MULTI_TEXT &&
								!this.multipleTextValues.find((item) => item.uid === custom.baseSettings.uid)
							) {
								custom.fieldDatas && custom.fieldDatas.field.multiLanguageTextArray === undefined
									? this.multipleTextValues.push({
											uid: custom.baseSettings.uid,
											multiLanguageTextArray: {
												ArAR: [],
												DeDE: [],
												EnUS: [],
												EsES: [],
												FrFR: [],
												PtBR: []
											} as ILanguageArray
									  })
									: this.multipleTextValues.push({
											uid: custom.baseSettings.uid,
											multiLanguageTextArray:
												custom.fieldDatas && custom.fieldDatas.field?.multiLanguageTextArray
									  });
								this.formCustomFields.addControl(
									`${custom.baseSettings.uid}_${language}`,
									new UntypedFormControl("", Validators.maxLength(70))
								);
							} else if (custom.baseSettings.type === TypeCustomFields.MULTI_SELECT) {
								// all array "multi language" select control
								this.formCustomFields.addControl(
									`${custom.baseSettings.uid}_${language}`,
									new UntypedFormControl(
										custom.fieldDatas && custom.fieldDatas.field.multiLanguageSelectArray
											? custom.fieldDatas.field.multiLanguageSelectArray
											: []
									)
								);
							}
						}
					}
				}
			}
		}

		this.subscriptions.push(
			this.formCustomFields.valueChanges.subscribe(() => {
				this.validEvent.emit(this.getFormValidation());
			})
		);

		this.validEvent.emit(this.getFormValidation());
	}

	/**
	 * isMultiLanguageField
	 * @param customFieldType
	 * @returns
	 */
	isMultiLanguageField(cus: IFullCustomField) {
		return (
			cus.baseSettings.type === TypeCustomFields.TEXT ||
			cus.baseSettings.type === TypeCustomFields.HMTL ||
			cus.baseSettings.type === TypeCustomFields.SELECT ||
			cus.baseSettings.type === TypeCustomFields.MULTI_SELECT ||
			cus.baseSettings.type === TypeCustomFields.MULTI_TEXT
		);
	}

	getFormValues() {
		return this.formCustomFields.getRawValue();
	}

	getFormValidation(): boolean {
		return Object.entries(this.formCustomFields.controls) &&
			Object.entries(this.formCustomFields.controls).length > 0
			? Object.entries(this.formCustomFields.controls).every((control) => control[1].valid)
			: true;
	}

	/**
	 * removeControl
	 */
	removeControl(controlName: string): void {
		this.formCustomFields.removeControl(controlName);
	}

	/**
	 * CUSTOM FIELD FUNCTIONs
	 */

	/**
	 * Filter query array for all possible filter
	 * @param customId
	 * @param query
	 * @param type
	 */
	filterQueryArray(customId: string, query: string, type: number) {
		const indexOfCustom =
			this.indexOfSelectedItemsArray(customId, type) === -1 ? 0 : this.indexOfSelectedItemsArray(customId, type);
		switch (type) {
			case 0:
				// Case Session
				if (query) {
					this.filteredSessions[indexOfCustom] = {
						uid: customId,
						items: this.sessions
							.filter((el) => el.identifier.toLowerCase().indexOf(query.toLowerCase()) > -1)
							.filter(
								(filtered) =>
									!filtered.uid.includes(
										this.selectedSessions[
											this.indexOfSelectedItemsArray(customId, type)
										]?.items.find((fil) => fil.uid === filtered.uid)?.uid
									)
							),
						moduleType: type
					};
				} else {
					this.filteredSessions[indexOfCustom] = {
						uid: customId,
						items: [],
						moduleType: type
					};
				}
				break;

			case 1:
				// Case speakers
				if (query) {
					const queryTerm = query.trim().toLocaleUpperCase();
					this.SEventUsers.getEventUsersFromSearchName(this.event.uid, TypeUser.SPEAKER, queryTerm)
						.pipe(take(1))
						.subscribe((eventUsers) => {
							this.filteredSpeakers[indexOfCustom] = {
								uid: customId,
								items: eventUsers
									.filter((el) => el.identifier.toLowerCase().indexOf(query.toLowerCase()) > -1)
									.filter(
										(filtered) =>
											!filtered.uid.includes(
												this.selectedSpeakers[
													this.indexOfSelectedItemsArray(customId, type)
												]?.items.find((fil) => fil.uid === filtered.uid)?.uid
											)
									),
								moduleType: type
							};
						});
				} else {
					this.filteredAttendees[indexOfCustom] = {
						uid: customId,
						items: [],
						moduleType: type
					};
				}
				break;

			case 2:
				// Case attendees
				if (query) {
					const queryTerm = query.trim().toLocaleUpperCase();
					this.SEventUsers.getEventUsersFromSearchName(this.event.uid, TypeUser.ATTENDEE, queryTerm)
						.pipe(take(1))
						.subscribe((eventUsers) => {
							this.filteredAttendees[indexOfCustom] = {
								uid: customId,
								items: eventUsers
									.filter((el) => el.identifier.toLowerCase().indexOf(query.toLowerCase()) > -1)
									.filter(
										(filtered) =>
											!filtered.uid.includes(
												this.selectedAttendees[
													this.indexOfSelectedItemsArray(customId, type)
												]?.items.find((fil) => fil.uid === filtered.uid)?.uid
											)
									),
								moduleType: type
							};
						});
				} else {
					this.filteredAttendees[indexOfCustom] = {
						uid: customId,
						items: [],
						moduleType: type
					};
				}
				break;

			case 3:
				// Case documents
				if (query) {
					this.customFilteredDocuments[indexOfCustom] = {
						uid: customId,
						items: this.customDocuments
							.filter((el) => el.identifier.toLowerCase().indexOf(query.toLowerCase()) > -1)
							.filter(
								(filtered) =>
									!filtered.uid.includes(
										this.customSelectedDocuments[
											this.indexOfSelectedItemsArray(customId, type)
										]?.items.find((fil) => fil.uid === filtered.uid)?.uid
									)
							),
						moduleType: type
					};
				} else {
					this.customFilteredDocuments[indexOfCustom] = {
						uid: customId,
						items: [],
						moduleType: type
					};
				}
				break;

			case 32:
				// Case tracks
				if (query) {
					this.filteredTracks[indexOfCustom] = {
						uid: customId,
						items: this.tracks
							.filter((el) => el.identifier.toLowerCase().indexOf(query.toLowerCase()) > -1)
							.filter(
								(filtered) =>
									!filtered.uid.includes(
										this.selectedTracks[this.indexOfSelectedItemsArray(customId, type)]?.items.find(
											(fil) => fil.uid === filtered.uid
										)?.uid
									)
							),
						moduleType: type
					};
				} else {
					this.filteredTracks[indexOfCustom] = {
						uid: customId,
						items: [],
						moduleType: type
					};
				}
				break;
		}
	}

	/**
	 * @description Returns index of filtering items array
	 * @param customId
	 * @param moduleType
	 * @returns
	 */
	indexOfSelectedItemsArray(customId: string, moduleType: number) {
		switch (moduleType) {
			// Sessions
			case 0:
				return this.selectedSessions.findIndex((doc) => doc.uid === customId);

			// Speakers
			case 1:
				return this.selectedSpeakers.findIndex((obj) => obj.uid === customId);

			// Attendees
			case 2:
				return this.selectedAttendees.findIndex((obj) => obj.uid === customId);

			// Documents
			case 3:
				return this.customSelectedDocuments.findIndex((doc) => doc.uid === customId);

			// Tracks
			case 32:
				return this.selectedTracks.findIndex((doc) => doc.uid === customId);
		}
	}

	/**
	 * @description Finds and returns item by the given "itemId"
	 * @param itemId
	 * @param typeModule
	 * @returns
	 */
	returnItems(itemId: string, typeModule: number): IEventUser[] | IDocument[] | ITrack[] | IDocument[] | ISchedule[] {
		const returnedIndex = this.indexOfSelectedItemsArray(itemId, typeModule);

		return typeModule === TypeModule.ATTENDEE
			? this.selectedAttendees[returnedIndex]?.items
			: typeModule === TypeModule.SPEAKER
			? this.selectedSpeakers[returnedIndex]?.items
			: typeModule === TypeModule.DOCUMENT
			? this.customSelectedDocuments[returnedIndex]?.items
			: typeModule === TypeModule.TRACKS
			? this.selectedTracks[returnedIndex]?.items
			: this.selectedSessions[returnedIndex]?.items;
	}

	/**
	 * Selected query item for all possible type
	 * @param customId
	 * @param item
	 * @param type
	 */
	selectQueryItem(customId: string, item: IEventUser | IDocument | ITrack | IDocument | ISchedule, type: string) {
		const returnedIndex = this.indexOfSelectedItemsArray(
			customId,
			type === "attendees"
				? TypeModule.ATTENDEE
				: type === "speakers"
				? TypeModule.SPEAKER
				: type === "documents"
				? TypeModule.DOCUMENT
				: type === "tracks"
				? TypeModule.TRACKS
				: TypeModule.SCHEDULE
		);

		const selectedDatas =
			type === "attendees"
				? this.selectedAttendees
				: type === "speakers"
				? this.selectedSpeakers
				: type === "documents"
				? this.customSelectedDocuments
				: type === "tracks"
				? this.selectedTracks
				: this.selectedSessions;

		const filteredDatas =
			type === "attendees"
				? this.filteredAttendees
				: type === "speakers"
				? this.filteredSpeakers
				: type === "documents"
				? this.customFilteredDocuments
				: type === "tracks"
				? this.filteredTracks
				: this.filteredSessions;

		if (
			selectedDatas[returnedIndex]?.items.length === 0 ||
			selectedDatas[returnedIndex]?.items.indexOf(item) === -1
		) {
			selectedDatas[returnedIndex]?.items.push(item);
		}

		filteredDatas[returnedIndex === -1 ? 0 : returnedIndex] = {
			uid: customId,
			items: [],
			moduleType:
				type === "attendees"
					? TypeModule.ATTENDEE
					: type === "speakers"
					? TypeModule.SPEAKER
					: type === "documents"
					? TypeModule.DOCUMENT
					: type === "tracks"
					? TypeModule.TRACKS
					: TypeModule.SCHEDULE
		};
	}

	/**
	 * Remove query array for all possible type
	 * @param item
	 * @param type
	 */
	removeQueryItem(
		customId: string,
		item: string | IEventUser | IDocument | ITrack | IDocument | ISchedule,
		type: number,
		lang
	) {
		const returnedIndex = this.indexOfSelectedItemsArray(customId, type);

		if (type === 99) {
			const selectedDatas = this.multipleTextValues;
			// string type (for multiple text input)
			selectedDatas[this.getMultiTextInputIndex(customId)].multiLanguageTextArray[lang].splice(
				selectedDatas[this.getMultiTextInputIndex(customId)].multiLanguageTextArray[lang].indexOf(
					item as string
				),
				1
			);
		} else {
			// Module type

			const selectedDatasModule: ICustomFieldModuleGroup[] =
				type === TypeModule.ATTENDEE
					? this.selectedAttendees
					: type === TypeModule.SPEAKER
					? this.selectedSpeakers
					: type === TypeModule.DOCUMENT
					? this.customSelectedDocuments
					: type === TypeModule.TRACKS
					? this.selectedTracks
					: this.selectedSessions;

			selectedDatasModule[returnedIndex]?.items.splice(
				selectedDatasModule[returnedIndex]?.items.indexOf(item),
				1
			);
		}
	}

	/**
	 *
	 * @param $event
	 * @param controlName
	 */
	onChange(customId: string, moduleType: number, controlName: string) {
		this.formCustomFields.get(controlName).valueChanges.subscribe((queryItems) => {
			this.filterQueryArray(customId, queryItems, moduleType);
		});
	}

	/* ------ MULTIPLE TEXT CUSTOM FIELD UTILITY FUNCTIONS ------ */

	/**
	 * @description Push value to array on enter key pressed & clean input
	 * @param $event (control)
	 * @param $array (Array)
	 * @returns void()
	 */
	onEnter($event, index: number, controlName: string, uid: string) {
		if ($event.keyCode === 13) {
			const array = this.multipleTextValues[index].multiLanguageTextArray[this.currentLanguage];

			if ($event.target.value) {
				const valueFormated = $event.target.value.trim();

				if (valueFormated === "" || valueFormated.length > 70) return;

				// Prevent user to type the same word / phrase multiple time
				if (array?.find((item) => item === valueFormated)) {
					this.getMultiTextInputErrorIndex(uid) !== -1
						? (this.multipleTextInputError[this.getMultiTextInputErrorIndex(uid)] = {
								controlId: uid,
								msg: this.STranslate.instant("edit-profil.errors.invalid.multiple_text-content-exist")
						  })
						: this.multipleTextInputError.push({
								controlId: uid,
								msg: this.STranslate.instant("edit-profil.errors.invalid.multiple_text-content-exist")
						  });
					return;
				} else if (valueFormated?.length > 70) {
					this.multipleTextInputError[this.getMultiTextInputErrorIndex(uid)] = {
						controlId: uid,
						msg: this.STranslate.instant("edit-profil.errors.invalid.multiple_text-too-long")
					};
					return;
				}

				this.getMultiTextInputErrorIndex(uid) !== -1
					? (this.multipleTextInputError[this.getMultiTextInputErrorIndex(uid)] = {
							controlId: uid,
							msg: ""
					  })
					: this.multipleTextInputError.push({
							controlId: uid,
							msg: ""
					  });

				array.push(valueFormated);

				(<HTMLInputElement>document.getElementById(controlName)).value = "";
			}
		}
	}

	/**
	 * reinitializeInputStatus
	 * @param $event
	 */
	reinitializeInputStatus($event) {
		$event.target.markAsUntouched;
		const control = $event.target as UntypedFormControl;
		control.markAsUntouched;
	}

	/**
	 * Retuns the index of the item in the multipleTextValues array
	 * depending on the given customId parameter
	 * @param customId
	 * @returns number
	 */
	getMultiTextInputIndex(customId: string) {
		return this.multipleTextValues.findIndex((obj) => obj.uid === customId);
	}

	/**
	 * Retuns the index of the item in the multipleTextInputError array
	 * depending on the given customId parameter
	 * @param customId
	 * @returns number
	 */
	getMultiTextInputErrorIndex(customId: string) {
		return this.multipleTextInputError.findIndex((obj) => obj.controlId === customId);
	}
	/* ------ END OF "CUSTOM FIELDS UTILITY FUNCTIONS" ------ */
	/**
	 * END OF CUSTOM FIELDS FUNCTIONS
	 */

	/**
	 * getImageByUrl
	 * @param event
	 * @param customField
	 */
	getImageByUrl(event: any, customField: IFullCustomField) {
		const imageUrl = this.formCustomFields
			.get(customField.baseSettings.name[this.currentLanguage] + "_URL")
			.value?.toString();
		const urlRegex = /(https?:\/\/.*\.(?:png|jpg))/gm;
		if (urlRegex.test(imageUrl)) {
			const urlSplited = imageUrl.split("/");
			const imageName: string = imageUrl.split("/")[urlSplited.length - 1];
			const formatImage: string = imageName ? imageName.split(".")[1] : null;

			this.isRegisterMode && this.initFileOrImageData(customField);

			customField.fieldDatas.field.image.name = imageName;
			customField.fieldDatas.field.image.lastModified = Date.now();
			customField.fieldDatas.field.image.lastModifiedDate = DateTime.fromMillis(Date.now()).toString();
			customField.fieldDatas.field.image.format = formatImage;
			customField.fieldDatas.field.image.size = 0;
			customField.fieldDatas.field.image.url = imageUrl;
			customField.fieldDatas.field.image.isExternalUrl = true;

			this.isValidUrl = true;
		} else {
			this.isValidUrl = false;
		}
	}

	/**
	 * importCustomFieldFileImage
	 * @description Import file or image from user computer and store it on the storage
	 * @param event
	 * @param customField
	 */
	importCustomFieldFileImage(eventUser: IEventUser, customField: IFullCustomField) {
		const oldCustomFieldData: ICustomFieldData = { field: {}, uid: "" };

		Object.assign(
			oldCustomFieldData,
			eventUser.customFields.find((item) => item.uid === customField.baseSettings.uid)
		);

		const item = this.customFieldMediaItems.find((item) => item.uid === customField.baseSettings.uid)?.data;

		if (!item) return of(null);

		const path = `/events/${this.event.uid}/modules/${this.module.uid}/event-users/${eventUser.uid}/custom-fields/${customField.baseSettings.uid}`;

		return from(this.storage.checkIfFileExists(path)).pipe(
			switchMap(async (fileExist) => {
				try {
					if (fileExist) {
						const pathToDelete = `/events/${this.event.uid}/modules/${this.module.uid}/event-users/${eventUser.uid}/custom-fields/${customField.baseSettings.uid}`;

						await this.storage.deleteDocumentOnStorage(pathToDelete);
					}

					const url = await this.storage.uploadFile(
						item,
						path,
						customField.baseSettings.type === TypeCustomFields.FILE ? item.type : "image/png"
					);

					return url;
				} catch (error) {
					//console.error("🚀 ~ CustomFieldsEditComponent ~ switchMap ~ error:", error);
				}
			})
		);
	}

	getDocumentType(file: File) {
		const type = file.type.split("/")[1];
		return type;
	}

	/**
	 * preloadCustomFieldFileOrImage
	 * @param customField
	 */
	async preloadCustomFieldFileOrImage(customField: IFullCustomField) {
		try {
			const fileInput =
				customField.baseSettings.type === TypeCustomFields.FILE
					? (document.getElementById(customField.baseSettings.uid) as HTMLInputElement)
					: (document.getElementById(customField.baseSettings.uid + "_base") as HTMLInputElement);
			const fileName =
				customField.baseSettings.type === TypeCustomFields.FILE
					? customField.fieldDatas.field.file.name
					: customField.fieldDatas.field.image.name;
			const response = await fetch(
				customField.baseSettings.type === TypeCustomFields.FILE
					? customField.fieldDatas.field.file.url
					: customField.fieldDatas.field.image.url
			);
			const blob = await response.blob();
			const fileContent = [blob];

			const file = new File(fileContent, fileName);
			const dataTransfer = new DataTransfer();
			dataTransfer.items.add(file);

			const fileList = dataTransfer.files;

			fileInput.files = fileList;

			fileInput.dispatchEvent(new Event("input", { bubbles: true }));
			// this.loadCustomFieldFileImage({ target: { files: fileList } }, customField);
		} catch (error) {
			console.error("preloadCustomFieldFileOrImage ~ error:", error);
		}
	}

	/**
	 * loadCustomFieldFileImage
	 * @param event
	 * @param customField
	 */
	loadCustomFieldFileImage(event: any, customField: IFullCustomField) {
		const item: File = event.target.files[0];

		if (item.size > this.MAX_FILE_SIZE) {
			this.invalidSizeFile = true;
			return;
		}

		this.invalidSizeFile = false;
		this.customFieldMediaItems.push({
			uid: customField.baseSettings.uid,
			data: item
		});

		this.isRegisterMode && this.initFileOrImageData(customField);

		const urlCreator = window.URL || window.webkitURL;
		customField.baseSettings.type === TypeCustomFields.FILE
			? (customField.fieldDatas.field.file.url = urlCreator.createObjectURL(item))
			: (customField.fieldDatas.field.image.url = urlCreator.createObjectURL(item));

		if (customField.baseSettings.type === TypeCustomFields.FILE) {
			customField.fieldDatas.field.file.name =
				item.name?.toLocaleLowerCase() === "image.jpg"
					? customField.baseSettings.name[this.event.language]
					: item.name;
			customField.fieldDatas.field.file.lastModified = Date.now();
			customField.fieldDatas.field.file.lastModifiedDate = DateTime.fromMillis(Date.now()).toString();
			customField.fieldDatas.field.file.type = item.type;
			customField.fieldDatas.field.file.size = item.size;

			// this.formCustomFields.get(customField.baseSettings.uid)?.patchValue(item.name);
			// this.formCustomFields.get(customField.baseSettings.uid)?.updateValueAndValidity();
		} else {
			// IMAGE
			customField.fieldDatas.field.image.name =
				item.name?.toLocaleLowerCase() === "image.jpg"
					? customField.baseSettings.name[this.event.language]
					: item.name;
			customField.fieldDatas.field.image.lastModified = Date.now();
			customField.fieldDatas.field.image.lastModifiedDate = DateTime.fromMillis(Date.now()).toString();
			customField.fieldDatas.field.image.format = item.type;
			customField.fieldDatas.field.image.size = item.size;
			customField.fieldDatas.field.image.isExternalUrl = false;

			// Update control field content
			this.formCustomFields
				.get(customField.baseSettings.uid + "_URL")
				.patchValue(
					item.name?.toLocaleLowerCase() === "image.jpg"
						? customField.baseSettings.name[this.event.language]
						: item.name
				);

			// this.formCustomFields.get(customField.baseSettings.uid + "_base").patchValue(item.name);
			// this.formCustomFields.get(customField.baseSettings.uid + "_base").updateValueAndValidity();
		}
	}

	/**
	 * initFileOrImageData
	 */
	initFileOrImageData(customField: IFullCustomField) {
		if (customField.baseSettings.type === TypeCustomFields.FILE) {
			customField.fieldDatas = {
				uid: customField.baseSettings.uid,
				field: {
					file: {
						lastModified: null,
						lastModifiedDate: null,
						name: null,
						size: null,
						type: null,
						url: null
					} as IFile
				} as IField
			};
		} else if (customField.baseSettings.type === TypeCustomFields.IMAGE) {
			customField.fieldDatas = {
				uid: customField.baseSettings.uid,
				field: {
					image: {
						format: null,
						lastModified: null,
						lastModifiedDate: null,
						name: null,
						size: null,
						url: null,
						isExternalUrl: false
					} as IImage
				} as IField
			};
		}
	}

	/**
	 * deleteCustomFieldFieldImage
	 * @description delete custom field input file
	 * @param customField
	 */
	deleteCustomFieldFileOrImage(customField: IFullCustomField) {
		this.customFieldMediaItems.splice(
			this.customFieldMediaItems.findIndex((item) => item.uid === customField.baseSettings.uid)
		);

		if (customField.baseSettings.type === TypeCustomFields.FILE) {
			customField.fieldDatas.field.file = {
				lastModified: null,
				lastModifiedDate: null,
				name: null,
				type: null,
				url: null,
				size: null
			};

			// clear control
			this.formCustomFields.get(customField.baseSettings.uid).patchValue(null);
			this.formCustomFields.get(customField.baseSettings.uid)?.updateValueAndValidity();
		} else {
			customField.fieldDatas.field.image = {
				lastModified: null,
				format: null,
				lastModifiedDate: null,
				name: null,
				url: null,
				size: null,
				isExternalUrl: false
			};

			// clear control
			this.formCustomFields.get(customField.baseSettings.uid + "_URL").setValue("");
			this.formCustomFields.get(customField.baseSettings.uid + "_base").patchValue(null);
			this.formCustomFields.get(customField.baseSettings.uid + "_base").updateValueAndValidity();

			this.isValidUrl && (this.isValidUrl = false);
		}
	}

	/**
	 * openDocumentOrImage
	 * @param document
	 */
	async openDocumentOrImage(item: IFile | IImage) {
		try {
			await Browser.open({
				url: item.url,
				windowName: item.name,
				presentationStyle: "popover"
			});
		} catch (error) {
			this.SUtility.presentToast(
				this.STranslate.instant("errors.error.error-open-document"),
				3000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * checkSelectOptionLimit
	 * @param custom
	 * @param index
	 */
	checkSelectOptionLimit(custom: IFullCustomField, index: number): boolean {
		return (
			custom.moduleSettings.optionsCount &&
			custom.moduleSettings.optionsCount[index] &&
			custom.moduleSettings.optionsCount[index][`${this.currentLanguage}_Limit_${index}`] <
				custom.baseSettings.optionsLimit[index][`${this.currentLanguage}_Limit_${index}`]
		);
	}

	/**
	 * onCustomFieldOptionChange
	 * @param custom
	 * @param event
	 */
	onCustomFieldOptionChange(custom: IFullCustomField, event) {
		/**
		 * 1 -> Check if it's logged in case user or not
		 * 2 -> Logged in case
		 * 			2_1 -> check if the field (event user) has already a checked value
		 * 				if it's the case check if values changed then
		 * 				compute ('add'/'remove' operations) and update the 'chaneOperation' variable
		 * 3 -> Not logged in case
		 * 			3.1 -> just take the user choice and update the 'chaneOperation' variable ('add' operation)
		 */

		const checkedValue = event.target.value;

		// Init values
		this.changeOperation.forEach((ch) => {
			ch.operations = ch.operations.map((_) => "none");
		});

		if (this.eventUserProfile) {
			// get eventUser checked values if needed
			const indexOfCustomField = this.changeOperation.findIndex((op) => op.uid === custom.baseSettings.uid);

			if (indexOfCustomField === -1) return;

			if (custom.baseSettings.type === TypeCustomFields.SELECT) {
				const eventUserCus = this.eventUserProfile.customFields.find(
					(cus) =>
						cus.uid === custom.baseSettings.uid && cus.field.multiLanguageText[this.currentLanguage] !== ""
				);

				// if (!eventUserCus) return "no previous data checked";

				custom.baseSettings.options.forEach((__, i) => {
					// set old value to the operations variable
					let indexOldOption;
					if (eventUserCus) {
						indexOldOption = _.cloneDeep(custom).baseSettings.options.findIndex(
							(option) =>
								option[this.currentLanguage] ===
								eventUserCus.field.multiLanguageText[this.currentLanguage]
						);

						if (indexOldOption !== -1 && indexOldOption === i) {
							this.changeOperation[indexOfCustomField].operations[indexOldOption] = "remove";
						}
					}

					// set new value to the operations variable
					const indexNewOption = _.cloneDeep(custom).baseSettings.options.findIndex(
						(option) => option[this.currentLanguage] === checkedValue
					);

					if (indexNewOption !== -1 && indexNewOption === i) {
						this.changeOperation[indexOfCustomField].operations[indexNewOption] = "add";
					}

					// If value hasn't change
					if (indexOldOption === indexNewOption) {
						this.changeOperation[indexOfCustomField].operations[indexOldOption] = "none";
					}
				});
			}
		} else {
			const indexOption = _.cloneDeep(custom).baseSettings.options.findIndex(
				(option) => option[this.currentLanguage] === checkedValue
			);

			if (indexOption === -1) return;

			const indexOfOperation = this.changeOperation.findIndex((op) => op.uid === custom.baseSettings.uid);
			if (indexOfOperation === -1) return;

			this.changeOperation[indexOfOperation].operations[indexOption] = "add";
		}
	}

	/**
	 * updatemodulecustomfieldcount
	 */
	updateModuleCustomFieldCount(customField: ICustomField) {
		// const changeOperations = this.changeOperation.slice().find((ch) => ch.uid === customField.uid);
		// if (!changeOperations) throw new Error("No changes operations found");
		// if (changeOperations.operations.filter((op) => op.includes("add") || op.includes("remove")).length > 0) {
		// 	const data = await this.SCustomFields.updateModuleCustomFieldCount(
		// 		this.event.uid,
		// 		this.module.uid,
		// 		customField,
		// 		this.currentLanguage,
		// 		changeOperations.operations
		// 	);
		// }
	}

	/**
	 * sanitize
	 * @param url
	 * @returns
	 */
	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	/**
	 * getPlacesPredictionById
	 */
	getPlacePredictionsById(uid: string) {
		return this.placesPredictions.find((place) => place.controlId === uid)?.data;
	}

	/**
	 * getCustomFieldAddressState
	 */
	getCustomFieldAddressState(uid: string): IPlacesPredictions {
		return this.placesPredictions.find((place) => place.controlId === uid);
	}

	/**
	 * getPlacesPrediction
	 */
	async getPlacePredictions(value, uid: string) {
		const correspondingCtrlIndex = this.placesPredictions.findIndex((place) => place.controlId === uid);
		try {
			if (correspondingCtrlIndex !== -1) {
				const data: any = await this.SCustomFields.getPlacePredictions(value);
				this.placesPredictions[correspondingCtrlIndex].data = data.result.predictions;
				this.placesPredictions[correspondingCtrlIndex].loading = false;
			}
		} catch (error) {
			this.placesPredictions[correspondingCtrlIndex].loading = false;
		}
	}

	displayFn(selectedValue: string | any): string {
		return typeof selectedValue === "string" ? selectedValue : selectedValue.target.textContext;
	}

	/**
	 * showCoutryModal
	 * @param customField
	 */
	async showCoutryModal(customField: IFullCustomField) {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.coutryListModal,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: this.isMobile ? "full-sized-modal" : "confirm-register-modal"
			});
			await modal.present();

			const modalData: any = await modal.onWillDismiss();
			if (modalData && modalData.data) {
				customField.fieldDatas.field.country.name = modalData.data.data.name;
				customField.fieldDatas.field.country.dialCode = modalData.data.data.dial_code;
				customField.fieldDatas.field.country.code = modalData.data.data.code;
				customField.fieldDatas.field.country.flag = modalData.data.data.flag;

				// update corresponding form control
				this.formCustomFields.patchValue({
					[`${customField.baseSettings.uid}`]:
						customField.fieldDatas.field && customField.fieldDatas.field.country
							? customField.fieldDatas.field.country.name
							: ""
				});
			} else {
				// find country by code
				const country = this.countryList.find((c) => c.code === customField.fieldDatas.field.country.code);
				if (country) {
					customField.fieldDatas.field.country.name = country.name;
					customField.fieldDatas.field.country.dialCode = country.dial_code;
					customField.fieldDatas.field.country.code = country.code;
					customField.fieldDatas.field.country.flag = country.flag;
				}
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * updateFieldVisibility
	 * @param key
	 */
	async updateFieldVisibility(key: string) {
		if (this.eventUserProfile.updatedSettings) {
			if (!this.eventUserProfile.updatedSettings.fieldsVisibility) {
				// Retro-compatibility code
				try {
					this.eventUserProfile.updatedSettings["fieldsVisibility"] =
						await this.SEventUsers.buildFieldsVisibility(
							this.event.uid,
							this.eventUserProfile.moduleId,
							this.eventUserProfile
						);
				} catch (error) {
					//console.error("updateFieldVisibility => error:", error);
				}
			}
			const correspondingCus = this.computedCustomFields.find((cus) => cus.baseSettings.uid === key);

			if (correspondingCus) {
				if (this.eventUserProfile.updatedSettings.fieldsVisibility[key] === undefined) {
					this.eventUserProfile.updatedSettings.fieldsVisibility[key] =
						!correspondingCus.moduleSettings.hiding.default;
				} else {
					this.eventUserProfile.updatedSettings.fieldsVisibility[key] =
						!this.eventUserProfile.updatedSettings.fieldsVisibility[key];
				}
			}
		}
	}

	compareFn(o1: any, o2: any) {
		return typeof o1 === "string" && typeof o2 === "string"
			? o1?.toLocaleLowerCase() === o2.toLocaleLowerCase()
			: _.isEqual(JSON.parse(JSON.stringify(o1)), JSON.parse(JSON.stringify(o2)));
	}
}
