<!-- Untouched field icon -->
@if (
  form &&
  form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language) &&
  !form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language)?.touched
  ) {
  <ion-icon
    src="assets/icon/Check.svg"
    class="check-icon"
    slot="end"
    >
  </ion-icon>
}
<!-- Success icon -->
@if (
  form &&
  form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language) &&
  !form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language)?.errors &&
  form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language)?.touched
  ) {
  <ion-icon
    src="assets/icon/Check.svg"
    class="check-icon"
    slot="end"
    color="primary"
    >
  </ion-icon>
}
<!-- Errors icon -->
@if (
  form &&
  form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language) &&
  form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language)?.errors &&
  form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language)?.touched
  ) {
  <ion-icon
    src="assets/icon/Close.svg"
    class="check-icon"
    slot="end"
    color="danger"
    >
  </ion-icon>
}
