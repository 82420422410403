import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	Signal,
	ViewChild,
	WritableSignal,
	computed,
	effect,
	signal
} from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subscription, combineLatest, of, interval, Subject, BehaviorSubject } from "rxjs";
import { distinctUntilChanged, skipWhile, switchMap, take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IEvent, IEventUser, IFullCustomField, IGroup, IModule } from "src/app/shared/interfaces";
import { IFilteredItemFormat } from "src/app/shared/interfaces/custom-fields.interfaces";
import { IFavoriteFolder } from "src/app/shared/interfaces/folders.interfaces";
import { IModuleUpdatedSettings } from "src/app/shared/interfaces/modules.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getFavoritesFolders,
	getGroupsByOrder,
	getModulesCustomsFieldsOfModule
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByType, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { CustomFieldsService, EventUsersService, FirestoreService, UtilityService } from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { environment } from "src/environments/environment";
import { ISearchFilter } from "src/app/shared/interfaces/search.interfaces";
import { getNetworkStatus } from "src/app/shared/selectors/utility.selectors";
import { buildFiltersQuery, filterSearch } from "src/app/shared/helpers-functions/filter-search";
import { IFilters } from "src/app/shared/interfaces/filters.interfaces";
import { DateTime } from "luxon";

@Component({
	selector: "app-event-users",
	templateUrl: "./event-users.component.html",
	styleUrls: ["./event-users.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventUsersComponent implements OnDestroy {
	@ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

	subscriptions: Subscription[] = [];
	searchSub: Subscription;
	eventUserSub: Subscription;
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	loader: boolean = true;
	limit: number = 999999;
	p: number = 1;

	searchOpen: boolean = false;
	searchText: string = "";

	totalEventUsers: number = 0;

	eventId: string;
	event: IEvent;
	module: IModule;
	moduleId: string;
	moduleUpdatedSettings: IModuleUpdatedSettings;
	eventUser: IEventUser;
	allDatas: WritableSignal<IEventUser[]> = signal([]);
	datasFiltered: Signal<IEventUser[]> = computed(() => {
		const allDatas = this.allDatas();
		const filtersQuery = this.filtersQuery();
		const datasFiltered = filterSearch(filtersQuery, allDatas);
		return datasFiltered.datas;
	});

	datasFilteredInTwo: Signal<IEventUser[]> = computed(() => {
		let count = 0;
		let index = 0;
		const newFilteredEventUsersOnTwo = [];
		this.datasFiltered().forEach((eventUser) => {
			if (count === 2) {
				index++;
				count = 0;
			}
			if (!newFilteredEventUsersOnTwo[index]) {
				newFilteredEventUsersOnTwo[index] = [];
			}
			newFilteredEventUsersOnTwo[index].push(eventUser);
			count++;
		});
		return newFilteredEventUsersOnTwo;
	});
	groups: IGroup[] = [];
	customFields: IFullCustomField[] = [];
	tagCustomFields: IFullCustomField[] = [];

	// filter properties
	filterItems: IFilteredItemFormat[];

	typeOrder: string;

	showNoResultImage: boolean = false;

	allowFilterTag: boolean = false; // To show or hide filter tag
	isMobile = window.innerWidth < 768 ? true : false;
	selectedFilters: IFilteredItemFormat[] = [];

	groupedLetters: any;
	itemSize: number = 97;
	height: number = 873;

	dontBelongAnyGroups: boolean = false;

	init: boolean = false;
	navigationSaved: { moduleId: string; lastIndex: number }[] = [];
	currentLanguage: string = environment.platform.defaultLanguage;
	searchValue: string = "";
	searchValueUpdated: Subject<string> = new Subject<string>();

	filters: IFilters = {
		locations: [],
		tracks: [],
		groups: [],
		customFields: [],
		principalKey: ""
	};
	filtersLoader: boolean = true;

	filtersQuery: WritableSignal<ISearchFilter> = signal({
		equalityFields: [],
		inequalityFields: [],
		includeTextFields: [],
		includeOrTextFields: [],
		superiorFields: [],
		superiorOrEqualFields: [],
		inferiorFields: [],
		inferiorOrEqualFields: [],
		anyTextFields: [],
		arrayContainsAnyFields: [],
		arrayContainsAllFields: [],
		arrayContainsBlocAndOrFields: [],
		arrayNotContainsFields: [],
		elemMatchArrayFields: [],
		page: 1,
		itemsPerPage: this.limit,
		sortBy: []
	});

	favoriteModule: IModule;
	myEventUser: IEventUser;
	eventUserFavoritesState: any = {};
	favoriteFolder: IFavoriteFolder;
	languageSub: Subscription;

	networkStatus: boolean = false;

	initDatasSubject: BehaviorSubject<{
		initEvent: boolean;
		initModule: boolean;
		initEventUser: boolean;
		initEventUsers: boolean;
		initFavorites: boolean;
		initGroups: boolean;
		initCustomFields: boolean;
		initFilters: boolean;
	}> = new BehaviorSubject(null);
	initDatasChecks: {
		initEvent: boolean;
		initModule: boolean;
		initEventUser: boolean;
		initEventUsers: boolean;
		initFavorites: boolean;
		initGroups: boolean;
		initCustomFields: boolean;
		initFilters: boolean;
	} = {
		initEvent: false,
		initModule: false,
		initEventUser: false,
		initEventUsers: false,
		initFavorites: false,
		initGroups: false,
		initCustomFields: false,
		initFilters: false
	};

	firstTimeModuleAccess: boolean = true;
	lastTimeGetDatas: number = 0;

	filtersUuid: string = this.SUtility.generateUuid();
	mainLoader: boolean = true;

	constructor(
		private navCtrl: NavController,
		public platform: Platform,
		private SAnalytics: AnalyticsService,
		private store: Store,
		private SCustomFields: CustomFieldsService,
		private SEventUsers: EventUsersService,
		private SFirestore: FirestoreService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private cdr: ChangeDetectorRef
	) {
		this.searchSub = this.searchValueUpdated.pipe(distinctUntilChanged()).subscribe((evtValue) => {
			this.loader = true;
			if (evtValue.length >= 1) {
				this.searchValue = evtValue;
				this.buildFilterQuery();
			} else {
				this.resetFilter();
			}
		});

		effect(() => {
			const computeReachableDatas = this.SUtility.computeReachableDatas(
				this.event,
				this.allDatas(),
				this.datasFiltered(),
				this.customFields,
				this.filters,
				this.currentLanguage
			);

			this.filters = computeReachableDatas.filters;

			this.groupedLetters = this.datasFiltered().reduce((groups, eventUser) => {
				const letter = eventUser.queryName.charAt(0);
				if (!Object.values(groups).includes(letter)) {
					const uid = eventUser.uid;
					groups[uid] = letter;
				}
				return groups;
			}, {});

			if (this.init) {
				this.loader = false;
			}

			this.loader = false;

			this.cdr.markForCheck();
		});
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);

		this.subscriptions.push(
			this.store.select(getNetworkStatus).subscribe((status) => {
				this.networkStatus = status;
			})
		);

		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState ||
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initEventUser ||
						!initState.initEventUsers ||
						!initState.initCustomFields ||
						!initState.initGroups ||
						!initState.initFilters
				),
				take(1)
			)
			.subscribe(() => {
				this.mainLoader = false;
				this.init = true;
			});

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.init = false;
						this.filtersLoader = true;

						this.moduleId = params.moduleId;

						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_EVENT_USERS_MODULE
						);

						this.SEventUsers.initDatasOfAllModules(this.eventId);
						const interval = setInterval(() => {
							if (this.SEventUsers.lastTimeGetEventUsers()) {
								clearInterval(interval);
								if (this.checkNeedReloadDatas()) {
									this.firstTimeModuleAccess = true;

									this.unloadDatas();
									this.initDatas();
								} else {
									if (this.module) {
										this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
									}
									this.getEventUser();
								}
							}
						}, 200);
					});
			});
	}

	ionViewDidEnter() {
		interval(100)
			.pipe(
				skipWhile(() => this.loader || !this.virtualScroll),
				take(1)
			)
			.subscribe(() => {
				if (this.virtualScroll) {
					const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
					this.virtualScroll.scrollToIndex(nav ? nav.lastIndex : 0, "auto");
				}
			});
	}

	ionViewWillLeave() {
		this.allDatas.set([]);
		// this.datasFiltered.set([]);
		// this.datasFilteredInTwo.set([]);
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.store.dispatch(ResetHeaderState(null));
		this.loader = true;
		this.mainLoader = true;
		this.init = false;
		this.initDatasChecks = {
			initEvent: false,
			initModule: false,
			initEventUser: false,
			initEventUsers: false,
			initFavorites: false,
			initGroups: false,
			initCustomFields: false,
			initFilters: false
		};
		this.initDatasSubject.next(this.initDatasChecks);
		this.resetFilter();
	}

	ngOnDestroy() {
		this.subscriptions.concat([this.eventUserSub, this.searchSub]).forEach((sub) => sub.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getGroups();
		this.getCustomFields();
		this.getFilters();
		this.getEventUsers();
		this.getFavoriteModuleAndFolders();
	}

	checkNeedReloadDatas() {
		let needReloadDatas: boolean = false;
		if (
			this.firstTimeModuleAccess ||
			!this.moduleId ||
			(this.module &&
				this.module.type === TypeModule.ATTENDEE &&
				this.lastTimeGetDatas <= this.SEventUsers.lastTimeGetEventUsers())
		) {
			needReloadDatas = true;
		}
		return needReloadDatas;
	}

	/**
	 * unloadDatas
	 */
	unloadDatas() {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
		this.analyticsArraySub.forEach((analyticData) => analyticData.sub.unsubscribe());

		this.initDatasChecks = {
			initEvent: false,
			initModule: false,
			initEventUser: false,
			initEventUsers: false,
			initFavorites: false,
			initGroups: false,
			initCustomFields: false,
			initFilters: false
		};
		this.event = null;
		this.module = null;
		this.eventUser = null;
		this.favoriteFolder = null;
		this.favoriteModule = null;
		this.p = 1;
		this.searchValue = "";
		this.allDatas.set([]);
		// this.datasFiltered.set([]);
		// this.datasFilteredInTwo.set([]);
		this.firstTimeModuleAccess = true;
		this.initDatasSubject.next(this.initDatasChecks);
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
				this.initDatasChecks.initEvent = true;
				this.initDatasSubject.next(this.initDatasChecks);
			})
		);
	}

	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;

					this.initDatasChecks.initModule = true;
					this.initDatasSubject.next(this.initDatasChecks);
				}

				this.store.dispatch(
					GetHeaderTitle({ payload: this.module && this.module.name ? this.module.name : null })
				);
			})
		);
	}

	getEventUser() {
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}

		this.eventUserSub = this.store.select(getCurrentEventUser).subscribe((eventUser) => {
			if (!_.isEqual(this.eventUser, eventUser)) {
				this.eventUser = eventUser;
			}
			this.initDatasChecks.initEventUser = true;
			this.initDatasSubject.next(this.initDatasChecks);
			this.cdr.markForCheck();
		});
	}

	/**
	 * Get groups
	 */
	getGroups() {
		this.store
			.select(getGroupsByOrder("asc"))
			.pipe(take(1))
			.subscribe((groups) => {
				if (!_.isEqual(this.groups, groups)) {
					this.groups = groups;
				}
				this.initDatasChecks.initGroups = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	/**
	 * Get custom fields
	 */
	getCustomFields() {
		combineLatest([
			this.store.select(getBaseCustomFields),
			this.store.select(getModulesCustomsFieldsOfModule(this.moduleId))
		])
			.pipe(take(1))
			.subscribe((results) => {
				const baseCustomFields = results[0];
				const modulesCustomFields = results[1];
				this.customFields =
					modulesCustomFields.length > 0
						? modulesCustomFields.map((customField) => {
								const baseCustomFieldCorresponding = baseCustomFields.find(
									(custField) => custField.uid === customField.uid
								);
								return {
									baseSettings: baseCustomFieldCorresponding ? baseCustomFieldCorresponding : null,
									moduleSettings: customField,
									fieldDatas: {
										uid: "",
										field: {
											date: {
												day: 0,
												month: 0,
												year: 0,
												value: "",
												fullDateISO: "",
												zone: ""
											},
											file: {
												lastModifiedDate: "",
												name: "",
												type: "",
												url: "",
												size: 0
											},
											image: {
												format: "",
												lastModifiedDate: "",
												name: "",
												url: "",
												size: 0
											},
											module: {
												items: [],
												moduleType: -1
											},
											multiLanguageText: {
												ArAR: "",
												DeDE: "",
												EnUS: "",
												EsES: "",
												FrFR: "",
												PtBR: ""
											},
											multiLanguageSelectArray: [],
											multiLanguageTextArray: {
												ArAR: [],
												DeDE: [],
												EnUS: [],
												EsES: [],
												FrFR: [],
												PtBR: []
											},
											numeric: 0,
											phoneNumber: {
												countryCode: "",
												dialCode: "",
												e164Number: "",
												internationalNumber: "",
												nationalNumber: "",
												number: ""
											},
											text: ""
										}
									}
								};
						  })
						: [];
				this.tagCustomFields = this.customFields.filter((custom) => custom.moduleSettings);

				this.initDatasChecks.initCustomFields = true;
				this.initDatasSubject.next(this.initDatasChecks);
			});
	}

	/**
	 * getFavoriteModuleAndFolders
	 */
	getFavoriteModuleAndFolders() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getModulesByType(TypeModule.FAVORITES)),
				this.store.select(getFavoritesFolders)
			])
				.pipe(
					skipWhile(() => !this.module || this.module === null),
					take(1)
				)
				.subscribe(([modules, favoriteFolders]) => {
					if (modules && modules.length > 0) {
						this.favoriteModule = modules[0];
					}

					if (favoriteFolders && favoriteFolders.length > 0) {
						this.favoriteFolder = favoriteFolders.filter(
							(folder) => this.module && folder.moduleLinkedId === this.module.uid && !folder.hidden
						)?.[0];
					}
				})
		);
	}

	getFilters() {
		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initCustomFields ||
						!initState.initGroups
				),
				take(1),
				switchMap(() => {
					if (
						this.module &&
						this.module.options &&
						(this.module.options.showFilter || this.module.options.showSearchIcon)
					) {
						return this.SCustomFields.getFiltersForModule(
							this.module,
							this.eventUser,
							this.customFields,
							[],
							[],
							this.groups.map((group) => {
								return { name: group.name, uid: group.uid };
							}),
							this.currentLanguage,
							this.SEventUsers.eventUsersByModulesState()[this.moduleId]
						);
					} else {
						return of(null);
					}
				})
			)
			.subscribe({
				next: (filters: IFilters) => {
					if (filters) {
						this.integratePreviousFiltersState(filters);
					}

					this.initDatasChecks.initFilters = true;
					this.initDatasSubject.next(this.initDatasChecks);
					this.filtersLoader = false;
				},
				error: (error) => {
					this.filtersLoader = false;
				}
			});
	}

	integratePreviousFiltersState(filters: IFilters) {
		const newFilters: IFilters = _.cloneDeep(filters);

		this.filters.locations.forEach((filter) => {
			const checkFilter = newFilters.locations.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters.tracks.forEach((filter) => {
			const checkFilter = newFilters.tracks.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters.groups.forEach((filter) => {
			const checkFilter = newFilters.groups.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				checkFilter.checked = filter.checked;
				checkFilter.isReachable = filter.isReachable;
			}
		});
		this.filters.customFields.forEach((filter) => {
			const checkFilter = newFilters.customFields.find((specFilter) => specFilter.uid === filter.uid);
			if (checkFilter) {
				filter.values.forEach((value) => {
					const checkFilterValue = checkFilter.values.find((val) => val.value === value.value);
					if (checkFilterValue) {
						checkFilterValue.isSelected = value.isSelected;
						checkFilterValue.isReachable = value.isReachable;
					}
				});
			}
		});
		this.filters = newFilters;
	}

	/**
	 * Get event users
	 */
	getEventUsers() {
		this.initDatasSubject
			.pipe(
				skipWhile(
					(initState) =>
						!initState.initEvent ||
						!initState.initModule ||
						!initState.initEventUser ||
						!initState.initCustomFields ||
						!initState.initGroups ||
						!initState.initFilters
				),
				take(1),
				switchMap((init) => {
					return this.SEventUsers.getAttendeesOfModuleWithFilters(this.eventId, this.moduleId, null).pipe(
						take(1)
					);
				})
			)
			.subscribe({
				next: (res: { totalItems: number; datas: IEventUser[] }) => {
					this.allDatas.set(
						res.datas.sort((a, b) => {
							if (this.module.options.usersOrder === "asc") {
								return a.queryName > b.queryName ? 1 : a.queryName < b.queryName ? -1 : 0;
							} else if (this.module.options.usersOrder === "desc") {
								return a.queryName < b.queryName ? 1 : a.queryName > b.queryName ? -1 : 0;
							} else if (this.module.options.usersOrder === "oldest") {
								return a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0;
							} else if (this.module.options.usersOrder === "recent") {
								return a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0;
							} else {
								return a.identifier > b.identifier ? 1 : a.identifier < b.identifier ? -1 : 0;
							}
						})
					);
					this.totalEventUsers = res.totalItems;
					this.buildFilterQuery();

					this.lastTimeGetDatas = DateTime.local().toMillis();
				}
			});
	}

	/**
	 * canShowFilterBloc
	 * @description return true if the filter is at least one custom field that can be filtered
	 * @returns boolean
	 */

	canShowFilterBloc() {
		return (
			this.customFields.some((cus) => cus.moduleSettings.enableFilter) ||
			(this.module.options.requiredFields.group.filter && this.groups.length > 0)
		);
	}

	/**
	 * Get list of event users on two bloc
	 * @returns
	 */
	// getListOnTwoBloc() {
	// 	let count = 0;
	// 	let index = 0;
	// 	const newFilteredEventUsersOnTwo = [];
	// 	this.datasFiltered().forEach((eventUser) => {
	// 		if (count === 2) {
	// 			index++;
	// 			count = 0;
	// 		}
	// 		if (!newFilteredEventUsersOnTwo[index]) {
	// 			newFilteredEventUsersOnTwo[index] = [];
	// 		}
	// 		newFilteredEventUsersOnTwo[index].push(eventUser);
	// 		count++;
	// 	});
	// 	if (!_.isEqual(this.datasFilteredInTwo(), newFilteredEventUsersOnTwo)) {
	// 		this.datasFilteredInTwo.set(newFilteredEventUsersOnTwo);
	// 	}
	// }

	/**
	 * Filter datas
	 */
	buildFilterQuery() {
		this.loader = true;
		this.resetQueryFilters();
		let filtersQuery = this.filtersQuery();

		filtersQuery.itemsPerPage = 999999;

		if (this.filters && this.filters.customFields) {
			this.filters.customFields.forEach((filter) => {
				filter.values.forEach((value) => {
					if (value.isSelected) {
						const cus = this.customFields.find(
							(cus) => cus.baseSettings.uid === value.filterId
						).moduleSettings;
						const fieldKey =
							this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
							this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT
								? "multiLanguageText"
								: this.getCustomFieldType(cus.uid) === TypeCustomFields.URL ||
								  this.getCustomFieldType(cus.uid) === TypeCustomFields.EMAIL
								? "text"
								: this.getCustomFieldType(cus.uid) === TypeCustomFields.NUMERIC
								? "numeric"
								: this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT
								? "multiLanguageSelectArray"
								: false;

						const isMultilanguage: boolean =
							this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
							this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT ||
							this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT
								? true
								: false;

						const bloc = filtersQuery.arrayContainsBlocAndOrFields.find(
							(bloc) =>
								bloc.fieldKey ===
								"customFields|" +
									fieldKey +
									"|" +
									value.filterId +
									(isMultilanguage ? "|" + this.currentLanguage : "")
						);
						if (!bloc) {
							filtersQuery.arrayContainsBlocAndOrFields.push({
								fieldKey:
									"customFields|" +
									fieldKey +
									"|" +
									value.filterId +
									(isMultilanguage ? "|" + this.currentLanguage : ""),
								compareData: [value.isNotSpecified ? "" : value.value]
							});
						} else {
							bloc.compareData.push(value.isNotSpecified ? "" : value.value);
						}
					}
				});
			});
		}

		if (this.filters && this.filters.groups) {
			this.filters.groups.forEach((filter) => {
				if (filter.checked) {
					const bloc = filtersQuery.arrayContainsBlocAndOrFields.find((bloc) => bloc.fieldKey === "groups");
					if (!bloc) {
						filtersQuery.arrayContainsBlocAndOrFields.push({
							fieldKey: "groups",
							compareData: [filter.uid]
						});
					} else {
						bloc.compareData.push(filter.uid);
					}
				}
			});
		}

		if (
			this.module &&
			this.module.options &&
			this.module.options.viewOnlyGroupsContent &&
			this.eventUser &&
			this.eventUser.groups.length > 0
		) {
			this.eventUser.groups.forEach((groupId) => {
				filtersQuery.arrayContainsAnyFields.push({
					fieldKey: "groups",
					compareData: groupId
				});
			});
			if (this.eventUser?.groups.length === 0 && this.module.options.viewOnlyGroupsContent) {
				this.dontBelongAnyGroups = true;
			}
		}

		if (this.module.options.showOnlyLoggedUsers) {
			filtersQuery.equalityFields.push({
				fieldKey: "firstAccess",
				compareData: true
			});
		}

		// Filter on searchbar term if needed
		filtersQuery = this.buildSearchQueryFilters(filtersQuery);

		["asc", "desc"].includes(this.module.options.usersOrder)
			? filtersQuery.sortBy.push({
					fieldKey: "queryName",
					type: ["desc", "recent"].includes(this.module.options.usersOrder) ? "desc" : "asc"
			  })
			: ["recent", "oldest"].includes(this.module.options.usersOrder)
			? filtersQuery.sortBy.push({
					fieldKey: "creationDate",
					type: ["desc", "recent"].includes(this.module.options.usersOrder) ? "desc" : "asc"
			  })
			: filtersQuery.sortBy.push({
					fieldKey: "identifier",
					type: ["desc", "recent"].includes(this.module.options.usersOrder) ? "desc" : "asc"
			  });

		this.filtersQuery.set({ ...filtersQuery });

		this.initDatasChecks.initEventUsers = true;
		this.initDatasSubject.next(this.initDatasChecks);

		this.cdr.markForCheck();
	}

	resetQueryFilters() {
		const filtersQuery = this.filtersQuery();
		filtersQuery.arrayContainsAnyFields = [];
		filtersQuery.equalityFields = [];
		filtersQuery.includeTextFields = [];
		filtersQuery.includeOrTextFields = [];
		filtersQuery.inferiorFields = [];
		filtersQuery.inferiorOrEqualFields = [];
		filtersQuery.superiorFields = [];
		filtersQuery.superiorOrEqualFields = [];
		filtersQuery.arrayContainsBlocAndOrFields = [];
		filtersQuery.sortBy = [];
		filtersQuery.page = this.p;

		this.filtersQuery.set({ ...filtersQuery });
	}

	buildSearchQueryFilters(filtersQuery: ISearchFilter) {
		return buildFiltersQuery(
			[this.module],
			filtersQuery,
			this.searchValue,
			this.currentLanguage,
			this.customFields,
			[],
			[],
			[],
			[],
			this.groups,
			[],
			[]
		);
	}

	/**
	 * Reset filter
	 */
	resetFilter() {
		this.searchValue = "";
		this.buildFilterQuery();
		this.showNoResultImage = false;
		this.cdr.markForCheck();
	}

	/**
	 * getCustomFieldType
	 * @param customFieldId
	 * @returns
	 */
	getCustomFieldType(customFieldId: string): number {
		return this.customFields.find((cus) => cus.baseSettings.uid === customFieldId)?.baseSettings.type;
	}

	scrolledIndexChanged(index: number) {
		const nav = this.navigationSaved.find((navMod) => navMod.moduleId === this.moduleId);
		if (this.init) {
			if (!nav) {
				this.navigationSaved.push({
					moduleId: this.moduleId,
					lastIndex: index
				});
			} else {
				nav.lastIndex = index;
			}
		}
	}

	getTotalFiltersChecked() {
		let count: number = 0;

		this.filters.locations.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters.groups.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters.tracks.forEach((filter) => {
			if (filter.checked) {
				count++;
			}
		});
		this.filters.customFields.forEach((filter) => {
			filter.values.forEach((value) => {
				if (value.isSelected) {
					count++;
				}
			});
		});

		return count;
	}

	/**
	 * Navigate to path
	 * @param path
	 */
	navigateTo(data: IEventUser) {
		this.navCtrl.navigateForward(`/event/${data.eventId}/profile/${data.moduleId}/${data.uid}`);
	}
}
