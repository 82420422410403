import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { distinctUntilChanged, take } from "rxjs/operators";
import { IModule, IModuleUpdatedSettings } from "src/app/shared/interfaces/modules.interfaces";
import { GetAllModules } from "../actions/modules.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { checkSameEvent } from "../selectors/modules.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";
import * as _ from "lodash-es";

@Injectable({
	providedIn: "root"
})
export class ModulesManagerService {
	modulesSub: Subscription;

	constructor(private SFirestore: FirestoreService, private store: Store) {}

	unsubscribeAll() {
		if (this.modulesSub) {
			this.modulesSub.unsubscribe();
		}
	}

	/**
	 * Get modules of event
	 * @param eventId
	 */
	getModulesOfEvent(eventId: string) {
		if (eventId) {
			this.store
				.select(checkSameEvent(eventId))
				.pipe(take(1))
				.subscribe((sameEvent) => {
					if (sameEvent && this.modulesSub && !this.modulesSub.closed) {
						return;
					} else if (!sameEvent && this.modulesSub && !this.modulesSub.closed) {
						this.modulesSub.unsubscribe();
					}
					this.modulesSub = this.SFirestore.valueChangesDocuments(`events/${eventId}/modules`, [])
						.pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)))
						.subscribe({
							next: (modules: IModule[]) => {
								this.store.dispatch(GetAllModules({ payload: modules, eventId: eventId }));

								this.store
									.select(getInitSpecificEventDatasPart("initModules"))
									.pipe(take(1))
									.subscribe((init) => {
										if (!init) {
											this.store.dispatch(
												InitSpecificEventDatasPart({ part: "initModules", payload: true })
											);
										}
									});
							},
							error: (err) => {
								//
							}
						});
				});
		}
	}

	/**
	 *createModuleUpdatedSettings
	 * @param moduleUpdatedSettings
	 * @returns
	 */
	createModuleUpdatedSettings(moduleUpdatedSettings: IModuleUpdatedSettings) {
		return this.SFirestore.setDocument(
			`events/${moduleUpdatedSettings.eventId}/modules-updated-settings/${moduleUpdatedSettings.moduleId}`,
			moduleUpdatedSettings
		);
	}

	/**
	 * Update module updated settings
	 * @param moduleUpdatedSettings
	 * @returns
	 */
	updateModuleUpdatedSettings(eventId: string, moduleId: string, moduleUpdatedSettings: any) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules-updated-settings/${moduleId}`,
			_.omit(moduleUpdatedSettings, ["eventId", "moduleId", "uid"])
		);
	}
}
