import { Component } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { firstValueFrom } from "rxjs";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IEvent, IEventUser, IModule, ISchedule } from "src/app/shared/interfaces";
import { IAppointment } from "src/app/shared/interfaces/appointments.interfaces";
import { getSpecificLocation } from "src/app/shared/selectors/generics-modules-data.selectors";
import { AppointmentsService, SchedulesService } from "src/app/shared/services";

@Component({
	selector: "app-confirm-ics-download",
	templateUrl: "./confirm-ics-download.component.html",
	styleUrls: ["./confirm-ics-download.component.scss"]
})
export class ConfirmIcsDownloadComponent {
	event: IEvent;
	module: IModule;
	eventUser: IEventUser;
	session: ISchedule;
	allSessions: boolean = false;
	sessions: ISchedule[] = [];
	mode: string;
	language: string;
	appointment: IAppointment;
	isMobile: boolean;
	device: string;
	typeModule = TypeModule;
	icsLoader: boolean;

	constructor(
		private modalCtrl: ModalController,
		private platform: Platform,
		private SSchedule: SchedulesService,
		private SAppointments: AppointmentsService,
		private store: Store
	) {
		if (
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
		) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}

		this.device = this.platform.is("android") ? "android" : this.platform.is("ios") ? "ios" : "desktop";
	}

	/**
	 * Generate ics file
	 * @param session
	 * @returns
	 */
	generateTimeSlotIcsFile(appointment: IAppointment) {
		this.icsLoader = true;
		// Implement a loader
		if (appointment) {
			this.SAppointments.generateAppointmentIcsFile(appointment);
			this.icsLoader = false;
		}
	}

	/**
	 * linkToCalendar
	 * @param appointment
	 */
	async linkTimeSlotToCalendar(appointment: IAppointment) {
		this.icsLoader = true;
		let location: string = "";
		if (appointment.location) {
			location = (await firstValueFrom(this.store.select(getSpecificLocation(appointment.location))))?.address;
		}
		const link = this.SAppointments.linkToCalendar(this.event, this.eventUser, location, appointment);
		window.open(link, "_blank");

		this.icsLoader = false;
	}

	/**
	 * addSessionToCalendar
	 * @param type
	 */
	addSessionToCalendar(type: string) {
		this.SSchedule.addSessionToCalendar(this.session, type, this.eventUser, this.language);
	}

	/**
	 * Download schedule sessions ics
	 */
	downloadSchedule() {
		// Implement a loader
		event.stopPropagation();
		this.SSchedule.generateSessionsIcsFileFromClient(this.module, this.sessions, this.event.language);
	}

	closeModal() {
		this.modalCtrl.dismiss();
	}
}
