import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, WritableSignal, signal } from "@angular/core";
import { QueryConstraint, arrayRemove, arrayUnion, limit, where } from "@angular/fire/firestore";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Observable, Subscription, combineLatest, firstValueFrom, forkJoin, from, of } from "rxjs";
import { environment } from "src/environments/environment";
import { auditTime, debounceTime, distinctUntilChanged, map, skipWhile, switchMap, take } from "rxjs/operators";
import {
	CheckinsService,
	CustomFieldsService,
	FirestoreService,
	ModulesManagerService,
	StateManagerService,
	StorageService
} from ".";
import { GetMyEventUser } from "../actions/auth.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { TypeUser } from "../enums/type-user";
import { IEvent, IFullCustomField, IModuleCustomField } from "../interfaces";
import {
	IEventUser,
	IEventUserRulesAuthorized,
	IEventUserUpdatedSettings,
	IEventUserUpdatedSettingsRulesAuthorized
} from "../interfaces/event-users.interfaces";
import { IModule, IModuleUpdatedSettings } from "../interfaces/modules.interfaces";
import { getCurrentEventUser, getCurrentUser } from "../selectors/auth.selectors";
import { checkSameEvent } from "../selectors/event-users.selectors";
import { getCurrentEvent } from "../selectors/events.selectors";
import { getBaseCustomFields } from "../selectors/generics-modules-data.selectors";
import { getInitSpecificEventDatasPart, getNetworkStatus } from "../selectors/utility.selectors";
import { Counter } from "./distributed-counter.service";
import { Directory, Encoding } from "@capacitor/filesystem";
import { ISearchFilter } from "../interfaces/search.interfaces";
import { filterSearch } from "../helpers-functions/filter-search";
import { getModulesByTypes, getSpecificModule } from "../selectors/modules.selectors";
import { TypeModule } from "../enums/type-module";

@Injectable({
	providedIn: "root"
})
export class EventUsersService {
	headers: HttpHeaders;
	myEventUserSub: Subscription;
	initSub: Subscription;
	currentEventUser: IEventUser;

	datasStateSubs: { eventId: string; moduleId: string; datasSub: Subscription }[] = [];

	eventUsersByModulesState: WritableSignal<any> = signal({});
	eventUsersOfAllModules: WritableSignal<IEventUser[] | null> = signal(null);
	lastTimeGetEventUsers: WritableSignal<number> = signal(null);

	buildAllCount: number = 0;
	goAllSub: boolean = false;

	constructor(
		private http: HttpClient,
		private SCheckins: CheckinsService,
		private SCustomsFields: CustomFieldsService,
		private SFirestore: FirestoreService,
		private SModules: ModulesManagerService,
		private store: Store,
		private SStorage: StorageService,
		private SState: StateManagerService,
		private navCtrl: NavController
	) {
		this.headers = new HttpHeaders();
		this.headers.append("content-type", "application/json");
	}

	isSubscriptionOfModuleActivated(eventId: string, moduleId: string) {
		return this.datasStateSubs.find(
			(state) =>
				state.eventId === eventId && state.moduleId === moduleId && state.datasSub && !state.datasSub.closed
		)
			? true
			: false;
	}

	initDatasOfAllModules(eventId: string) {
		console.log("**** Init all modules");
		this.goAllSub = true;
		this.store.dispatch(
			InitSpecificEventDatasPart({
				part: "initAttendees",
				payload: false
			})
		);
		this.store.dispatch(
			InitSpecificEventDatasPart({
				part: "initSpeakers",
				payload: false
			})
		);
		this.checkSubOnWrongEvent(eventId);

		this.store
			.select(getModulesByTypes([TypeModule.ATTENDEE, TypeModule.SPEAKER]))
			.pipe(take(1))
			.subscribe((modules) => {
				this.buildAllCount =
					modules.filter((module) => module.type === TypeModule.ATTENDEE).length +
					modules.filter((module) => module.type === TypeModule.SPEAKER).length;
				if (modules.filter((module) => module.type === TypeModule.ATTENDEE).length === 0) {
					this.initAttendeesStore();
				}
				if (modules.filter((module) => module.type === TypeModule.SPEAKER).length === 0) {
					this.initSpeakersStore();
				}

				modules.forEach((module) => {
					this.initDatasOfOneModule(eventId, module.uid);
				});
			});
	}

	initDatasOfOneModule(eventId: string, moduleId: string) {
		console.log("**** Init one module: ", moduleId);
		this.checkSubOnWrongEvent(eventId);

		const checkModuleSub = this.datasStateSubs.find(
			(state) => state.eventId === eventId && state.moduleId === moduleId
		);
		if (!checkModuleSub) {
			combineLatest([
				this.store.select(getCurrentEvent).pipe(skipWhile((event) => !event)),
				this.store.select(getSpecificModule(moduleId))
			])
				.pipe(take(1))
				.subscribe((results) => {
					const event: IEvent = results[0];
					const module: IModule = results[1];
					if (module) {
						if (event && event.settings && event.settings.getDatasType) {
							console.log("**** Init one module cf 01: ", moduleId);

							this.http
								.post(
									"https://eventUsers-getEventUsers" + environment.platform.apiV2BaseUrl,
									{
										eventId: eventId,
										moduleId: module.uid,
										lastTimeGet: this.lastTimeGetEventUsers()
									},
									{
										headers: this.headers,
										observe: "body"
									}
								)
								.pipe(take(1))
								.subscribe((results: any) => {
									console.log("**** Init one module cf 02: ", moduleId);
									this.lastTimeGetEventUsers.set(DateTime.local().toMillis());
									if (results && results.code === 200) {
										this.rebuildModuleEventUsers(module.eventId, module.uid, results.result);
									}
								});
						} else {
							console.log("**** Init one module fire 01: ", moduleId);

							const datasSub = this.SFirestore.valueChangesDocuments(
								`events/${module.eventId}/modules/${module.uid}/event-users`,
								[]
							)
								.pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)))
								.subscribe((eventUsers) => {
									console.log("**** Init one module fire 02: ", moduleId);

									this.rebuildModuleEventUsers(module.eventId, module.uid, eventUsers);
								});
							this.datasStateSubs.push({
								eventId: eventId,
								moduleId: module.uid,
								datasSub: datasSub
							});
						}
					}
				});
		} else if (checkModuleSub && checkModuleSub.datasSub && checkModuleSub.datasSub.closed) {
			console.log("**** Init one module 03: ", moduleId);
			this.store
				.select(getSpecificModule(moduleId))
				.pipe(take(1))
				.subscribe((module) => {
					if (module) {
						checkModuleSub.datasSub = this.SFirestore.valueChangesDocuments(
							`events/${module.eventId}/modules/${module.uid}/event-users`,
							[]
						)
							.pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)))
							.subscribe((eventUsers) => {
								console.log("**** Init one module 04: ", moduleId);

								this.rebuildModuleEventUsers(eventId, module.uid, eventUsers);
							});
					}
				});
		}
	}

	rebuildModuleEventUsers(eventId: string, moduleId: string, eventUsers: IEventUser[]) {
		this.buildAllCount--;
		const state = this.eventUsersByModulesState();
		state[moduleId] = eventUsers;
		this.eventUsersByModulesState.set({ ...state });
		let allAttendees = this.eventUsersOfAllModules() ? this.eventUsersOfAllModules() : [];
		allAttendees = allAttendees.filter((attendee) => attendee.moduleId !== moduleId).concat(eventUsers);

		this.eventUsersOfAllModules.set([...allAttendees]);
		// this.updateLocalFile(eventId, "attendees.json", allAttendees);
		this.lastTimeGetEventUsers.set(DateTime.local().toMillis());

		this.initAttendeesStore();
		this.initSpeakersStore();
	}

	initAttendeesStore() {
		if (!this.goAllSub || (this.goAllSub && this.buildAllCount <= 0)) {
			console.log("**** Init done attendees");
			this.store
				.select(getInitSpecificEventDatasPart("initAttendees"))
				.pipe(take(1))
				.subscribe((init) => {
					if (!init) {
						this.store.dispatch(
							InitSpecificEventDatasPart({
								part: "initAttendees",
								payload: true
							})
						);
					}
				});
		}
	}

	initSpeakersStore() {
		if (!this.goAllSub || (this.goAllSub && this.buildAllCount <= 0)) {
			this.store
				.select(getInitSpecificEventDatasPart("initSpeakers"))
				.pipe(take(1))
				.subscribe((init) => {
					if (!init) {
						this.store.dispatch(
							InitSpecificEventDatasPart({
								part: "initSpeakers",
								payload: true
							})
						);
					}
				});
		}
	}

	updateLocalFile(eventId: string, fileName: string, datas: any[]) {
		const fileDatas = {
			lastTimeUpdated: DateTime.local().toMillis(),
			encrypted: true,
			eventId: eventId,
			version: environment.platform.saveFileVersion,
			datas: datas
		};
		from(
			this.SStorage.writeFile(
				`events/${eventId}/${fileName}`,
				this.SStorage.encryptUsingAES256(environment.platform.encryptKey, fileDatas),
				Directory.Data,
				Encoding.UTF8,
				true
			)
		);
	}

	cleanOneSubscription(moduleId: string) {
		const checkExist = this.datasStateSubs.find((state) => state.moduleId === moduleId);

		if (checkExist && checkExist.datasSub && !checkExist.datasSub.closed) {
			checkExist.datasSub.unsubscribe();
		}

		this.datasStateSubs = this.datasStateSubs.filter((state) => state.moduleId !== moduleId);
	}

	cleanAllSubscriptions() {
		this.datasStateSubs.forEach((state) => {
			if (state && state.datasSub && !state.datasSub.closed) {
				state.datasSub.unsubscribe();
			}
		});

		this.datasStateSubs = [];
	}

	checkSubOnWrongEvent(eventId: string) {
		this.datasStateSubs.forEach((state) => {
			if (state && state.eventId !== eventId) {
				if (state.datasSub && !state.datasSub.closed) {
					state.datasSub.unsubscribe();
				}
			}
		});

		this.datasStateSubs = this.datasStateSubs.filter((state) => state.datasSub && !state.datasSub.closed);
	}

	/**
	 *
	 *
	 * End of new functions
	 *
	 *
	 */

	unsubscribeAll() {
		[this.myEventUserSub, this.initSub].forEach((sub) => {
			if (sub) sub.unsubscribe();
		});
		this.cleanAllSubscriptions();
	}

	initDatasForEvent(eventId: string) {
		this.initDatasOfAllModules(eventId);
	}

	getAttendeesOfModuleWithFilters(eventId: string, moduleId: string, filters: ISearchFilter) {
		return combineLatest([
			this.store.select(getSpecificModule(moduleId)),
			this.store.select(getCurrentEventUser),
			this.eventUsersByModulesState() && this.eventUsersByModulesState()[moduleId]
				? of(
						this.eventUsersByModulesState()[moduleId].filter(
							(data) => data.visibility || data.visibility === undefined
						)
				  )
				: of([])
		]).pipe(
			switchMap((results) => {
				const module = results[0];
				const currentEventUser = results[1];
				const datas = results[2];
				if (datas) {
					const eventUsers: IEventUser[] = datas;
					const eventUsersFiltered = eventUsers.filter(
						(data) =>
							module &&
							data.moduleId === module.uid &&
							module.options &&
							(!module.options.viewOnlyGroupsContent ||
								(module.options.viewOnlyGroupsContent &&
									currentEventUser &&
									data.groups.some((grpId) => currentEventUser.groups.includes(grpId))))
					);
					return of(filterSearch(filters, eventUsersFiltered));
				} else {
					return of({
						totalItems: 0,
						datas: []
					});
				}
			})
		);
	}

	/**
	 * Init my event user subscription
	 */
	initMyEventUser() {
		if (this.initSub && !this.initSub.closed) {
			this.initSub.unsubscribe();
		}

		this.initSub = combineLatest([
			this.store.select(getCurrentUser),
			this.store.select(getCurrentEvent).pipe(
				skipWhile((event) => !event),
				take(1)
			)
		])
			// .pipe(take(1))
			.subscribe((results) => {
				const user = results[0];
				const event = results[1];
				if (!user) {
					this.store.dispatch(GetMyEventUser({ payload: null, eventId: event ? event.uid : null }));
					if (this.myEventUserSub && !this.myEventUserSub.closed) {
						this.myEventUserSub.unsubscribe();
					}

					this.store
						.select(getInitSpecificEventDatasPart("initEventUser"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initEventUser", payload: true })
								);
							}
						});
				} else {
					this.getMyEventUserDataOnStore(event, user.uid);
				}
			});
	}

	/**
	 * Get event user data of user for event
	 * @param eventId
	 * @param userId
	 */
	getMyEventUserDataOnStore(event: IEvent, userId: string) {
		let count = 0;
		if (event && userId) {
			this.store
				.select(checkSameEvent(event.uid))
				.pipe(take(1))
				.subscribe((sameEvent) => {
					if (sameEvent && this.myEventUserSub && !this.myEventUserSub.closed) {
						return;
					} else if (!sameEvent && this.myEventUserSub && !this.myEventUserSub.closed) {
						this.myEventUserSub.unsubscribe();
					}
					this.myEventUserSub = combineLatest([
						this.SFirestore.collectionGroupValueChangesDocuments("event-users", [
							where("eventId", "==", event.uid),
							where("uid", "==", userId)
						]),
						this.SFirestore.collectionGroupValueChangesDocuments("event-users-updated-settings", [
							where("eventId", "==", event.uid),
							where("userId", "==", userId)
						])
					])
						.pipe(debounceTime(200))
						.subscribe({
							next: (results) => {
								const eventUser: IEventUser = results[0].length > 0 ? _.cloneDeep(results[0][0]) : null;
								const eventUserUpdatedSettings: IEventUserUpdatedSettings =
									results[1].length > 0 ? _.cloneDeep(results[1][0]) : null;
								if (eventUser && eventUserUpdatedSettings) {
									if (!eventUserUpdatedSettings.typeUser && eventUser && eventUser.type) {
										eventUserUpdatedSettings.typeUser = eventUser.type;
										this.updateEventUserUpdatedSettings(
											eventUser.eventId,
											eventUser.moduleId,
											eventUser.uid,
											eventUserUpdatedSettings
										);
									}
									eventUser.updatedSettings = eventUserUpdatedSettings;
								}
								// Check if old event user exist and now not only for private event
								if (this.currentEventUser && !eventUser && event && !event.settings.visibility) {
									this.SState.unsubscribeEvent();
									this.SState.resetStateEvent();
									this.unsubscribeAll();
									this.currentEventUser = eventUser;
									this.navCtrl.navigateRoot("/events-lists/all");
								} else {
									if (eventUser && !eventUser.firstAccess && count === 0) {
										count++;
										// Update first access of event and date if false
										this.SFirestore.updateDocument(
											`events/${eventUser.eventId}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}`,
											{
												firstAccess: true,
												firstAccessDate: DateTime.local().toISO()
											}
										);

										// Update credits for first access
										this.SFirestore.getDocumentObs(`events/${eventUser.eventId}`).subscribe(
											(event) => {
												const eventData = event.data() as IEvent;
												this.SFirestore.getDocumentObs(`users/${eventData.clientId}`).subscribe(
													(user) => {
														if (user.get("isCreditsAllowed")) {
															const counterOnEvent = new Counter(
																this.SFirestore.docRef(
																	`events/${eventUser.eventId}/counters/event-users-firstaccess`
																),
																eventData.clientId,
																"count",
																eventData,
																"onEvent",
																this.SFirestore
															);

															const counterOnUser = new Counter(
																this.SFirestore.docRef(
																	`events/${eventUser.eventId}/counters/${eventUser.eventId}|event-users-firstaccess`
																),
																eventData.clientId,
																"count",
																eventData,
																"onUser",
																this.SFirestore
															);

															counterOnEvent.incrementBy(1);
															counterOnUser.incrementBy(1);
														}
													}
												);
											}
										);
									}

									this.store.dispatch(GetMyEventUser({ payload: eventUser, eventId: event.uid }));
									this.currentEventUser = eventUser;

									this.store
										.select(getInitSpecificEventDatasPart("initEventUser"))
										.pipe(take(1))
										.subscribe((init) => {
											if (!init) {
												this.store.dispatch(
													InitSpecificEventDatasPart({ part: "initEventUser", payload: true })
												);
											}
										});
								}
							},
							error: () => {
								//
							}
						});
				});
		}
	}

	/**
	 * Get specifics updated settings for event users
	 * @param eventId
	 * @param moduleId
	 * @param usersIds
	 * @returns
	 */
	getSpecificsEventUsersUpdatedSettings(eventId: string, moduleId: string, usersIds: string[]) {
		if (usersIds.length === 0) {
			return of([]);
		}
		const chunked = _.chunk(usersIds, 10);
		const obsUpdatedSettings: Observable<IEventUserUpdatedSettings[]>[] = [];

		if (moduleId) {
			chunked.forEach((chunk) => {
				obsUpdatedSettings.push(
					this.SFirestore.getDocumentsObs(
						`events/${eventId}/modules/${moduleId}/event-users-updated-settings`,
						[where("userId", "in", chunk)]
					).pipe(map((docs) => docs.docs.map((doc) => doc.data() as IEventUserUpdatedSettings)))
				);
			});
		} else {
			chunked.forEach((chunk) => {
				obsUpdatedSettings.push(
					from(
						this.SFirestore.getDocumentsCollectionGroup(`event-users-updated-settings`, [
							where("eventId", "==", eventId),
							where("userId", "in", chunk)
						])
					).pipe(map((docs) => docs.docs.map((doc) => doc.data() as IEventUserUpdatedSettings)))
				);
			});
		}

		return forkJoin(obsUpdatedSettings).pipe(
			take(1),
			switchMap((results) => {
				return of(_.flatten(results));
			})
		);
	}

	/**
	 * Get specifics updated settings for event users
	 * @param eventId
	 * @param usersIds
	 * @returns
	 */
	getSpecificsEventUsers(eventId: string, usersIds: string[]) {
		if (usersIds.length === 0) {
			return of([]);
		}
		const chunked = _.chunk(usersIds, 10);
		const obsEventUsers: Observable<IEventUser[]>[] = [];

		chunked.forEach((chunk) => {
			obsEventUsers.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup(`event-users`, [
						where("eventId", "==", eventId),
						where("uid", "in", chunk)
					])
				).pipe(map((docs) => docs.docs.map((doc) => doc.data() as IEventUser)))
			);
		});

		return forkJoin(obsEventUsers).pipe(
			take(1),
			switchMap((results) => {
				return of(_.flatten(results));
			})
		);
	}

	/**
	 * getSpecificsEventUsersPaginated
	 */
	getSpecificsEventUsersPaginated(eventId: string, usersIds: string[], queryConstraint: QueryConstraint[]) {
		if (usersIds.length === 0) {
			return of([]);
		}

		const chunked = _.chunk(usersIds, 10);
		const obsEventUsers: Observable<any>[] = [];

		chunked.forEach((chunk) => {
			obsEventUsers.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup(
						`event-users`,
						queryConstraint
							? [where("eventId", "==", eventId), where("uid", "in", chunk), ...queryConstraint]
							: [where("eventId", "==", eventId), where("uid", "in", chunk)]
					)
				).pipe(
					switchMap((docs) =>
						of({
							start: docs.docs[0],
							last: docs.docs[docs.docs.length - 1],
							docsSnapshots: docs.docs,
							docsDatas: docs.docs.map((doc) => doc.data() as IEventUser)
						})
					)
				)
			);
		});

		return forkJoin(obsEventUsers).pipe(
			take(1),
			switchMap((results) => {
				return of({
					start: results[0].start,
					last: results[results.length - 1].last,
					docsSnapshots: _.flatten(results.map((result) => result.docsSnapshots)),
					docsDatas: _.flatten(results.map((result) => result.docsDatas))
				});
			})
		);
	}

	/**
	 * Get specifics updated settings for event users
	 * @param eventId
	 * @param moduleId
	 * @param userId
	 * @returns
	 */
	getSpecificEventUserUpdatedSettings(eventId: string, moduleId: string, userId: string) {
		return this.store.select(getNetworkStatus).pipe(
			take(1),
			switchMap((status) => {
				if (status) {
					return from(
						this.SFirestore.getDocument(
							`events/${eventId}/modules/${moduleId}/event-users-updated-settings/${userId}`
						)
					).pipe(map((snapshot) => snapshot.data() as IEventUserUpdatedSettings));
				} else {
					return of(null);
				}
			})
		);
	}

	/**
	 * Get all attendees of an event
	 * @param eventId
	 * @returns
	 */
	getAllAttendeesForEvent(eventId: string) {
		return this.SFirestore.collectionGroupValueChangesDocuments("event-users", [
			where("eventId", "==", eventId),
			where("type", "==", TypeUser.ATTENDEE)
		]);
	}

	/**
	 * Get all attendees of an event
	 * @param eventId
	 * @returns
	 */
	getAllAttendeesForEventOneShot(eventId: string) {
		return from(
			this.SFirestore.getDocumentsCollectionGroup("event-users", [
				where("eventId", "==", eventId),
				where("type", "==", TypeUser.ATTENDEE)
			])
		).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)));
	}

	/**
	 * Get all event users of an event
	 * @param eventId
	 * @returns
	 */
	getAllEventUsersForEvent(eventId: string, type: "listen" | "oneshot") {
		if (type === "listen") {
			return this.SFirestore.collectionGroupValueChangesDocuments("event-users", [
				where("eventId", "==", eventId),
				limit(100)
			]);
		} else {
			return from(
				this.SFirestore.getDocumentsCollectionGroup("event-users", [where("eventId", "==", eventId)])
			).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)));
		}
	}

	/**
	 * Getting one specific event user
	 * @param eventId
	 * @param uid
	 * @returns
	 */
	getSpecificEventUser(eventId: string, uid: string) {
		return from(
			this.SFirestore.getDocumentsCollectionGroup("event-users", [
				where("eventId", "==", eventId),
				where("uid", "==", uid)
			])
		).pipe(
			map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)),
			switchMap((eventUsers) => {
				return of(eventUsers.length > 0 ? eventUsers[0] : null);
			})
		);
	}

	/**
	 * Get eventUsers for module
	 * @param eventId
	 * @param moduleId
	 * @param queryConstraints
	 * @returns
	 */
	getEventUsersWithConstraints(
		eventId: string,
		moduleId: string,
		queryConstraints: QueryConstraint[],
		additionalConstraints?: QueryConstraint[]
	) {
		if (moduleId) {
			return this.SFirestore.getDocumentsObs(
				`events/${eventId}/modules/${moduleId}/event-users`,
				additionalConstraints ? queryConstraints.concat(additionalConstraints) : queryConstraints
			).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)));
		} else {
			return from(
				this.SFirestore.getDocumentsCollectionGroup(
					`event-users`,
					additionalConstraints ? queryConstraints.concat(additionalConstraints) : queryConstraints
				)
			).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)));
		}
	}

	/**
	 * Get event users from search name
	 * @param eventId
	 * @param type
	 * @param term
	 * @returns
	 */
	getEventUsersFromSearchName(eventId: string, type: number, term: string) {
		// const queryConstraints: QueryConstraint[] = [where("eventId", "==", eventId)];

		// if (type) {
		// 	queryConstraints.push(where("type", "==", type));
		// }

		return of(
			this.eventUsersOfAllModules().filter(
				(user) =>
					user.queryName.includes(term.trim().toLocaleUpperCase()) ||
					user.identifier.includes(term.trim().toLocaleUpperCase())
			)
		);
		// combineLatest([
		// 	from(
		// 		this.SFirestore.getDocumentsCollectionGroup(
		// 			"event-users",
		// 			queryConstraints.concat([where("queryName", ">=", term.trim().toLocaleUpperCase())])
		// 		)
		// 	).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser))),
		// 	from(
		// 		this.SFirestore.getDocumentsCollectionGroup(
		// 			"event-users",
		// 			queryConstraints.concat([where("identifier", ">=", term.trim())])
		// 		)
		// 	).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)))
		// ]).pipe(
		// 	switchMap((results) => {
		// 		const eventUsersFound: IEventUser[] = _.uniqBy(_.flatten(results), "uid").sort((a, b) =>
		// 			a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" })
		// 		);
		// 		return of(eventUsersFound);
		// 	})
		// );
	}

	/**
	 * Get specific eventUsers of an event
	 * @param eventId
	 * @param eventUsersIds
	 * @returns
	 */
	getSpecificEventUsersForEvent(eventId: string, type: TypeUser, eventUsersIds: string[]) {
		if (eventUsersIds.length === 0) {
			return of([]);
		}
		const allIds = _.chunk(eventUsersIds, 10);
		const obsArray: Observable<IEventUser[]>[] = [];
		const queryConstraints: QueryConstraint[] = [where("eventId", "==", eventId)];

		if (type) {
			queryConstraints.push(where("type", "==", type));
		}

		allIds.forEach((ids) => {
			obsArray.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup(
						`event-users`,
						queryConstraints.concat([where("uid", "in", ids)])
					)
				).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IEventUser)))
			);
		});
		return combineLatest(obsArray).pipe(
			switchMap((results) => {
				const eventUsers: IEventUser[] = _.flatten(results);
				return of(eventUsers);
			})
		);
	}

	/**
	 * Get specific eventUsers of an event
	 * @param eventId
	 * @param eventUsersIds
	 * @returns
	 */
	valueChangesSpecificEventUsersForEvent(eventId: string, type: TypeUser, eventUsersIds: string[]) {
		if (eventUsersIds.length === 0) {
			return of([]);
		}
		const allIds = _.chunk(eventUsersIds, 10);
		const obsArray: Observable<IEventUser[]>[] = [];
		const queryConstraints: QueryConstraint[] = [where("eventId", "==", eventId)];

		if (type) {
			queryConstraints.push(where("type", "==", type));
		}

		allIds.forEach((ids) => {
			obsArray.push(
				this.SFirestore.collectionGroupValueChangesDocuments(
					`event-users`,
					queryConstraints.concat([where("uid", "in", ids)])
				)
			);
		});
		return combineLatest(obsArray).pipe(
			switchMap((results) => {
				const eventUsers: IEventUser[] = _.flatten(results);
				return of(eventUsers);
			})
		);
	}

	/**
	 * Get all speakers of an event
	 * @param eventId
	 * @returns
	 */
	getAllSpeakersForEvent(eventId: string) {
		return this.SFirestore.collectionGroupValueChangesDocuments("event-users", [
			where("eventId", "==", eventId),
			where("type", "==", TypeUser.SPEAKER)
		]);
	}

	/**
	 * Get user by email
	 * @param email
	 * @returns
	 */
	getEventUserByEmail(email: string, eventId: string, returnType: string) {
		return this.http
			.post(
				environment.platform.apiV2BaseUrl
					? "https://eventUsers-getEventUserByEmail" + environment.platform.apiV2BaseUrl
					: environment.platform.apiBaseUrl + "eventUsers-getEventUserByEmail",
				{ email: email, eventId: eventId, returnType: returnType },
				{ headers: this.headers }
			)
			.pipe(
				switchMap((results: any) => {
					if (results.code === 201 || results.code === 200) {
						return of(results.result);
					} else {
						return of(null);
					}
				})
			);
	}

	/**
	 * getEventUserFromModuleByEmail
	 * @param eventId
	 * @param moduleId
	 * @param email
	 * @returns
	 */
	checkEventUsersPresenceInModule(eventId: string, moduleId: string, email: string) {
		return this.SFirestore.getDocumentsObs(`events/${eventId}/modules/${moduleId}/event-users`, [
			where("email", "==", email)
		]).pipe(
			switchMap((res) => {
				return of(res.docs.map((doc) => doc.data() as IEventUser));
			})
		);
	}

	/**
	 * Get event user by identifier
	 * @param identifier
	 * @param eventId
	 * @returns
	 */
	getEventUserIdentifierByIdentifier(identifier: string, eventId) {
		return from(
			this.SFirestore.getDocumentsCollectionGroup("event-users", [
				where("eventId", "==", eventId),
				where("identifier", ">=", identifier.toUpperCase()),
				where("identifier", "<=", identifier.toUpperCase() + "\uf8ff")
			])
		).pipe(
			map((docs) => docs.docs.map((doc) => doc.data())),
			switchMap((eventUsers) => {
				if (eventUsers.length > 0) {
					return of(eventUsers);
				} else {
					return of([]);
				}
			})
		);
	}

	/**
	 * Get event user
	 * @param eventId
	 * @param userId
	 * @returns
	 */
	getEventUser(eventId: string, userId: string) {
		return this.store.select(getNetworkStatus).pipe(
			take(1),
			switchMap((status: boolean) => {
				if (status) {
					// Network ok
					return this.SFirestore.collectionGroupValueChangesDocuments("event-users", [
						where("eventId", "==", eventId),
						where("uid", "==", userId)
					]).pipe(
						auditTime(200),
						switchMap((eventUsers) => {
							if (eventUsers.length > 0) {
								return of(eventUsers[0]);
							} else {
								return of(null);
							}
						})
					);
				} else {
					// Network not ok
					const filtersQuery = _.cloneDeep(this.SStorage.baseFiltersQuery);
					filtersQuery.itemsPerPage = 1;
					filtersQuery.equalityFields.push({
						fieldKey: "uid",
						compareData: userId
					});
					return of(this.eventUsersOfAllModules()).pipe(
						take(1),
						switchMap((datas) => {
							const eventUsersFiltered = filterSearch(filtersQuery, datas).datas;
							return of(eventUsersFiltered.length > 0 ? eventUsersFiltered[0] : null);
						})
					);
				}
			})
		);
	}

	/**
	 * Get event users of moduless
	 * @param eventId
	 * @param modulesIds
	 * @returns
	 */
	getEventUsersOfModules(eventId: string, modulesIds: string[]) {
		const obsArray: Observable<IEventUser[]>[] = [];
		modulesIds.forEach((moduleId) => {
			obsArray.push(
				this.SFirestore.valueChangesDocuments(`events/${eventId}/modules/${moduleId}/event-users`, [])
			);
		});
		return combineLatest(obsArray).pipe(
			switchMap((results) => {
				if (results.length === 0) {
					return of([]);
				} else {
					return of(_.flatten(results));
				}
			})
		);
	}

	/**
	 * Create an event user
	 * @param eventId
	 * @param moduleId
	 * @param eventUser
	 */
	async createEventUser(
		eventId: string,
		moduleId: string,
		eventUser: IEventUser,
		eventUserUpdatedSettings: IEventUserUpdatedSettings
	) {
		const id = eventUser.uid
			? eventUser.uid
			: this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/event-users`);
		eventUser.uid = id;

		const baseCustomFields = await firstValueFrom(this.store.select(getBaseCustomFields));

		const fullCustomFields: IFullCustomField[] = [];
		baseCustomFields.forEach((baseCustom) => {
			const fieldDatas = eventUser.customFields.find((customData) => customData.uid === baseCustom.uid);
			if (fieldDatas) {
				fullCustomFields.push({
					baseSettings: baseCustom,
					moduleSettings: null,
					fieldDatas: fieldDatas
				});
			}
		});

		const searchStringDatas = this.SCustomsFields.getAllDatasOnArrayForSearch(
			fullCustomFields,
			eventUser.groups,
			[],
			["ArAR", "DeDE", "EnUS", "EsES", "FrFR", "PtBR"]
		);
		eventUser.searchStringDatas = searchStringDatas;

		// Update module with custom fields search term
		const moduleUpdatedSettingsDoc = await this.SFirestore.getDocument(
			`events/${eventId}/modules-updated-settings/${moduleId}`
		);
		const moduleUpdatedSettings = moduleUpdatedSettingsDoc.exists()
			? (moduleUpdatedSettingsDoc.data() as IModuleUpdatedSettings)
			: null;
		if (!moduleUpdatedSettings) {
			await this.SModules.createModuleUpdatedSettings({
				chatMembersArray: [],
				discussionGroupsMembersArray: [],
				eventId: eventId,
				moduleId: eventUser.moduleId,
				searchStringDatas: eventUser.searchStringDatas,
				uid: eventUser.moduleId
			});
		} else {
			moduleUpdatedSettings.searchStringDatas = moduleUpdatedSettings.searchStringDatas
				? _.uniq(moduleUpdatedSettings.searchStringDatas.concat(eventUser.searchStringDatas))
				: eventUser.searchStringDatas;
			await this.SModules.updateModuleUpdatedSettings(
				moduleUpdatedSettings.eventId,
				moduleUpdatedSettings.moduleId,
				_.omit(moduleUpdatedSettings, ["eventId", "moduleId", "uid"])
			);
		}
		let batch = this.SFirestore.getBatch();

		if (eventUserUpdatedSettings) {
			eventUserUpdatedSettings.userId = eventUser.uid;
			batch = this.SFirestore.setBatch(
				batch,
				this.SFirestore.docRef(`events/${eventId}/modules/${moduleId}/event-users-updated-settings/${id}`),
				eventUserUpdatedSettings
			);
		}

		batch = this.SFirestore.setBatch(
			batch,
			this.SFirestore.docRef(`events/${eventId}/modules/${moduleId}/event-users/${id}`),
			eventUser
		);
		return batch.commit();
	}

	/**
	 * Create an event user
	 * @param eventId
	 * @param moduleId
	 * @param eventUser
	 */
	async createAccompanyingEventUser(
		eventId: string,
		moduleId: string,
		eventUser: IEventUser,
		accompanyingEventUser: IEventUser,
		accompanyingEventUserUpdatedSettings: IEventUserUpdatedSettings
	) {
		const id = this.SFirestore.createId(
			`events/${eventId}/modules/${moduleId}/event-users/${eventUser}/accompanying-event-users`
		);
		accompanyingEventUser.uid = id;

		const baseCustomFields = await firstValueFrom(this.store.select(getBaseCustomFields));

		const fullCustomFields: IFullCustomField[] = [];
		baseCustomFields.forEach((baseCustom) => {
			const fieldDatas = accompanyingEventUser.customFields.find(
				(customData) => customData.uid === baseCustom.uid
			);
			if (fieldDatas) {
				fullCustomFields.push({
					baseSettings: baseCustom,
					moduleSettings: null,
					fieldDatas: fieldDatas
				});
			}
		});

		const searchStringDatas = this.SCustomsFields.getAllDatasOnArrayForSearch(
			fullCustomFields,
			accompanyingEventUser.groups,
			[],
			["ArAR", "DeDE", "EnUS", "EsES", "FrFR", "PtBR"]
		);
		accompanyingEventUser.searchStringDatas = searchStringDatas;

		// Update module with custom fields search term
		const moduleUpdatedSettingsDoc = await this.SFirestore.getDocument(
			`events/${eventId}/modules-updated-settings/${accompanyingEventUser.moduleId}`
		);
		const moduleUpdatedSettings = moduleUpdatedSettingsDoc.exists()
			? (moduleUpdatedSettingsDoc.data() as IModuleUpdatedSettings)
			: null;
		if (!moduleUpdatedSettings) {
			await this.SModules.createModuleUpdatedSettings({
				chatMembersArray: [],
				discussionGroupsMembersArray: [],
				eventId: eventId,
				moduleId: accompanyingEventUser.moduleId,
				searchStringDatas: accompanyingEventUser.searchStringDatas,
				uid: accompanyingEventUser.moduleId
			});
		} else {
			moduleUpdatedSettings.searchStringDatas = moduleUpdatedSettings.searchStringDatas
				? _.uniq(moduleUpdatedSettings.searchStringDatas.concat(accompanyingEventUser.searchStringDatas))
				: accompanyingEventUser.searchStringDatas;
			await this.SModules.updateModuleUpdatedSettings(
				moduleUpdatedSettings.eventId,
				moduleUpdatedSettings.moduleId,
				_.omit(moduleUpdatedSettings, ["eventId", "moduleId", "uid"])
			);
		}
		let batch = this.SFirestore.getBatch();

		if (accompanyingEventUserUpdatedSettings) {
			accompanyingEventUserUpdatedSettings.userId = accompanyingEventUser.uid;
			batch = this.SFirestore.setBatch(
				batch,
				this.SFirestore.docRef(`events/${eventId}/modules/${moduleId}/event-users-updated-settings/${id}`),
				accompanyingEventUserUpdatedSettings
			);
		}

		batch = this.SFirestore.setBatch(
			batch,
			this.SFirestore.docRef(
				`events/${eventId}/modules/${moduleId}/event-users/${eventUser.uid}/accompanying-event-users/${id}`
			),
			accompanyingEventUser
		);
		return batch.commit();
	}

	/**
	 * Create event user updated settings
	 * @param eventId
	 * @param moduleId
	 * @param eventUserUpdatedSettings
	 * @returns
	 */
	createEventUserUpdatedSettings(
		eventId: string,
		moduleId: string,
		eventUserUpdatedSettings: IEventUserUpdatedSettings
	) {
		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/event-users-updated-settings/${eventUserUpdatedSettings.userId}`,
			eventUserUpdatedSettings
		);
	}

	/**
	 * Update event user
	 * @param eventId
	 * @param moduleId
	 * @param user
	 * @returns
	 */
	async updateEventUser(eventId: string, moduleId: string, eventUser: IEventUser) {
		const results = await firstValueFrom(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.SCheckins.getCheckedsForEventUser(eventId, eventUser.uid)
			])
		);
		const baseCustomFields = results[0];
		const checkinChecked = results[1];
		const fullCustomFields: IFullCustomField[] = [];
		baseCustomFields.forEach((baseCustom) => {
			const fieldDatas = eventUser.customFields.find((customData) => customData.uid === baseCustom.uid);
			if (fieldDatas) {
				fullCustomFields.push({
					baseSettings: baseCustom,
					moduleSettings: null,
					fieldDatas: fieldDatas
				});
			}
		});

		const oldSearchStringDatas: string[] = eventUser.searchStringDatas
			? _.cloneDeep(eventUser.searchStringDatas)
			: [];

		const searchStringDatas = this.SCustomsFields.getAllDatasOnArrayForSearch(
			fullCustomFields,
			eventUser.groups,
			checkinChecked.map((checkinChecked) => checkinChecked.checkinId),
			["ArAR", "DeDE", "EnUS", "EsES", "FrFR", "PtBR"]
		);

		const diffBetweenSearch: string[] = _.difference(oldSearchStringDatas, searchStringDatas);

		eventUser.searchStringDatas = searchStringDatas;

		const moduleUpdatedSettingsDoc = await this.SFirestore.getDocument(
			`events/${eventId}/modules-updated-settings/${eventUser.moduleId}`
		);

		const moduleUpdatedSettings = moduleUpdatedSettingsDoc.exists()
			? (moduleUpdatedSettingsDoc.data() as IModuleUpdatedSettings)
			: null;

		if (!moduleUpdatedSettings) {
			await this.SModules.createModuleUpdatedSettings({
				chatMembersArray: [],
				discussionGroupsMembersArray: [],
				eventId: eventId,
				moduleId: eventUser.moduleId,
				searchStringDatas: eventUser.searchStringDatas,
				uid: eventUser.moduleId
			});
		} else {
			if (!diffBetweenSearch || diffBetweenSearch.length === 0) {
				moduleUpdatedSettings.searchStringDatas = moduleUpdatedSettings.searchStringDatas
					? _.uniq(moduleUpdatedSettings.searchStringDatas.concat(eventUser.searchStringDatas))
					: eventUser.searchStringDatas;
			} else {
				moduleUpdatedSettings.searchStringDatas = moduleUpdatedSettings.searchStringDatas
					? _.uniq(moduleUpdatedSettings.searchStringDatas.concat(eventUser.searchStringDatas))
					: eventUser.searchStringDatas;
				const promiseAll: Promise<number>[] = [];
				diffBetweenSearch.forEach((searchDiff) => {
					promiseAll.push(
						firstValueFrom(
							this.SFirestore.getCountOfQueryObs(
								`events/${eventId}/modules/${moduleUpdatedSettings.moduleId}/event-users`,
								"default",
								[where("searchStringDatas", "array-contains", searchDiff)]
							)
						)
					);
				});

				const results = await Promise.all(promiseAll);
				results.forEach((result, index) => {
					if (!result || result <= 1) {
						moduleUpdatedSettings.searchStringDatas = moduleUpdatedSettings.searchStringDatas.filter(
							(searchTerm) => searchTerm !== diffBetweenSearch[index]
						);
					}
				});
			}

			await this.SModules.updateModuleUpdatedSettings(
				moduleUpdatedSettings.eventId,
				moduleUpdatedSettings.moduleId,
				_.omit(moduleUpdatedSettings, ["eventId", "moduleId", "uid"])
			);
		}

		if (eventUser.updatedSettings) {
			const updatedSettings = _.cloneDeep(eventUser.updatedSettings);
			await this.updateEventUserUpdatedSettings(
				eventId,
				moduleId,
				eventUser.uid,
				_.omit(updatedSettings, ["eventId", "moduleId", "userId", "typeUser"])
			);
			delete eventUser.updatedSettings;
		} else {
			const fieldsVisibility = await this.buildFieldsVisibility(eventId, moduleId);
			const newUpdatedSettings: IEventUserUpdatedSettings = {
				accessModule: eventUser["accessModule"] ? eventUser["accessModule"] : {},
				accessModulesArray: eventUser["accessModulesArray"] ? eventUser["accessModulesArray"] : [],
				appointmentsSchedules: eventUser["appointmentsSchedules"] ? eventUser["appointmentsSchedules"] : [],
				connected: eventUser["connected"] ? eventUser["connected"] : false,
				eventId: eventUser.eventId,
				fieldsVisibility: fieldsVisibility,
				language: eventUser["language"] ? eventUser["language"] : environment.platform.defaultLanguage,
				lastAccessDate: eventUser["lastAccessDate"] ? eventUser["lastAccessDate"] : "",
				lastSeenNotifTime: eventUser["lastSeenNotifTime"] ? eventUser["lastSeenNotifTime"] : 0,
				moduleId: eventUser.moduleId,
				readNotifications: eventUser["readNotifications"] ? eventUser["readNotifications"] : "",
				timezoneType: eventUser["timezoneType"] ? eventUser["timezoneType"] : "local",
				userId: eventUser.uid,
				typeUser: eventUser["typeUser"] ? eventUser["typeUser"] : TypeUser.ATTENDEE
			};
			await this.createEventUserUpdatedSettings(eventId, moduleId, newUpdatedSettings);
		}

		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/event-users/${eventUser.uid}`,
			_.omit(eventUser, ["eventId", "moduleId", "uid", "identifier", "createdInApp", "type"])
		);
	}

	updatePartOfEventUser(eventId: string, moduleId: string, eventUserId: string, datas: IEventUserRulesAuthorized) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/event-users/${eventUserId}`,
			_.omit(datas, ["eventId", "moduleId", "uid", "identifier", "createdInApp", "type"])
		);
	}

	/**
	 * Update event user updated settings
	 * @param eventId
	 * @param moduleId
	 * @param eventUserUpdatedSettings
	 * @returns
	 */
	updateEventUserUpdatedSettings(
		eventId: string,
		moduleId: string,
		eventUserId: string,
		eventUserUpdatedSettings: IEventUserUpdatedSettingsRulesAuthorized
	) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/event-users-updated-settings/${eventUserId}`,
			_.omit(eventUserUpdatedSettings, ["eventId", "moduleId", "userId"])
		);
	}

	/**
	 * Set attendee/speaker notification config
	 * @param eventId
	 * @param user
	 * @param oneSignalId
	 * @returns
	 */
	setUserNotificationConfig(eventId: string, user: IEventUser, oneSignalId: any) {
		return this.SFirestore.updateDocument(`events/${eventId}/modules/${user.moduleId}/event-users/${user.uid}`, {
			options: { notifOneSignalConfig: oneSignalId }
		});
	}

	/**
	 * Update last access of event user
	 * @param eventId
	 * @param eventUserId
	 */
	updateLastAccessOfEventForEventUser(eventId: string, eventUserId: string) {
		firstValueFrom(
			from(
				this.SFirestore.getDocumentsCollectionGroup(`event-users`, [
					where("eventId", "==", eventId),
					where("uid", "==", eventUserId)
				])
			).pipe(
				switchMap((eventUsersDocs) => {
					if (eventUsersDocs.size > 0) {
						const eventUser = eventUsersDocs.docs[0].data() as IEventUser;
						return this.SFirestore.updateDocument(
							`events/${eventUser.eventId}/modules/${eventUser.moduleId}/event-users-updated-settings/${eventUser.uid}`,
							{
								lastAccessDate: DateTime.local().toISO()
							}
						);
					} else {
						return of(null);
					}
				})
			)
		);
	}

	/**
	 * compareEventUsersWithoutAField
	 * @param eventUser1
	 * @param eventUser2
	 * @returns
	 */
	compareEventUsersWithoutConnectedProp(eventUser1: any, eventUser2: any) {
		let result: boolean = false;

		if (!eventUser1 && !eventUser2) {
			return true;
		}

		const removeProp = "connected";

		if (eventUser1 && eventUser2 && eventUser1[removeProp] && eventUser2[removeProp]) {
			const { [removeProp]: statusEventUser1, ...copyEventUser1 } = eventUser1;
			const { [removeProp]: statusEventUser2, ...copyEventUser2 } = eventUser2;

			result = _.isEqual(copyEventUser1, copyEventUser2) && copyEventUser1.moduleId === copyEventUser2.moduleId;
		}

		return result;
	}

	/**
	 * addToFavorite
	 * @param eventId
	 * @param eventUserId
	 * @param moduleId
	 * @returns
	 */
	addToFavorite(eventId: string, moduleId: string, eventUserId: string) {
		return this.SFirestore.updateDocument(`events/${eventId}/modules/${moduleId}/event-users/${eventUserId}`, {
			favorites: arrayUnion(eventUserId)
			// editedProfile: true
		});
	}

	removeFromFavorite(eventId: string, moduleId: string, eventUserId: string) {
		return this.SFirestore.updateDocument(`events/${eventId}/modules/${moduleId}/event-users/${eventUserId}`, {
			favorites: arrayRemove(eventUserId)
			// editedProfile: true
		});
	}

	/**
	 * buildFieldsVisibility
	 * @returns
	 */
	async buildFieldsVisibility(eventId: string, moduleId: string, eventUser?: IEventUser) {
		try {
			const results = await Promise.all([
				this.SFirestore.getDocument(`events/${eventId}/modules/${moduleId}`),
				this.SFirestore.getDocuments(`events/${eventId}/modules/${moduleId}/custom-fields`, [])
			]);

			const [module, moduleCustomFields] = [
				results[0].data() as IModule,
				results[1].docs.map((doc) => doc.data() as IModuleCustomField)
			];

			if (!module || !moduleCustomFields) {
				throw new Error("Can't find module or custom fields !");
			}

			return Object.entries(module.options.requiredFields)
				.map((obj) => {
					return {
						[obj[0]]:
							!eventUser || (eventUser && !eventUser.editedProfile)
								? obj[1]?.["hiding"]?.["default"]
								: eventUser && eventUser.editedProfile && eventUser.updatedSettings?.fieldsVisibility
								? eventUser.updatedSettings?.fieldsVisibility?.[obj[0]]
								: false
					};
				})
				.concat(
					moduleCustomFields.map((cus) => {
						return {
							[cus.uid]:
								!eventUser || (eventUser && !eventUser.editedProfile)
									? cus.hiding.default !== undefined
										? cus.hiding.default
										: false
									: eventUser &&
									  eventUser.editedProfile &&
									  eventUser.updatedSettings?.fieldsVisibility &&
									  eventUser.updatedSettings?.fieldsVisibility?.[cus.uid]
									? eventUser.updatedSettings?.fieldsVisibility?.[cus.uid]
									: false
						};
					})
				)
				.reduce((result, obj) => {
					Object.keys(obj).forEach((key) => {
						result[key] = obj[key];
					});
					return result;
				}, {});
		} catch (error) {
			//console.error("🚀 => buildFieldsVisibility Error:", error);
		}
	}

	/**
	 * isUserOrModuleFieldsVisibilityHidden
	 * @param key
	 */
	isUserOrModuleFieldsVisibilityHidden(
		datas: { eventUser: IEventUser; eventUserModule: IModule; customFields: IFullCustomField[] },
		key: string,
		type: "baseFields" | "customFields"
	) {
		if (type === "baseFields") {
			return datas.eventUserModule.options.enableUserFieldsHideAbility &&
				(datas.eventUser.updatedSettings.fieldsVisibility?.[key] === undefined ||
					(datas.eventUser.updatedSettings.fieldsVisibility?.[key] !== undefined &&
						datas.eventUser.updatedSettings.fieldsVisibility?.[key] !==
							datas.eventUserModule.options.requiredFields?.[key]?.hiding?.default &&
						!datas.eventUser.editedProfile))
				? datas.eventUserModule.options.requiredFields?.[key]?.hiding?.default
				: true
				? datas.eventUser.updatedSettings.fieldsVisibility?.[key]
				: true
				? false
				: false;
		} else {
			const correspondingCus = datas.customFields.find((cus) => cus.baseSettings.uid === key);

			if (correspondingCus) {
				return datas.eventUserModule.options.enableUserFieldsHideAbility &&
					(datas.eventUser.updatedSettings.fieldsVisibility?.[key] === undefined ||
						(datas.eventUser.updatedSettings.fieldsVisibility?.[key] !== undefined &&
							datas.eventUser.updatedSettings.fieldsVisibility?.[key] !==
								correspondingCus.moduleSettings.hiding?.default &&
							!datas.eventUser.editedProfile))
					? correspondingCus.moduleSettings.hiding?.default
					: true
					? datas.eventUser.updatedSettings.fieldsVisibility?.[key]
					: true
					? false
					: false;
			}
		}
	}
}
