/* eslint-disable no-empty */
/* eslint-disable max-len */
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription, combineLatest, firstValueFrom, take } from "rxjs";
import { Loading } from "src/app/shared/actions/utility.actions";
import { TriggerType } from "src/app/shared/enums/type-automation";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IAutomation, IEvent, IEventUser, ILanguage, IModule, IUser } from "src/app/shared/interfaces";
import {
	ICustomField,
	IField,
	IModuleCustomField,
	IPhoneNumber
} from "src/app/shared/interfaces/custom-fields.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentUser, getMyEventUser } from "src/app/shared/selectors/auth.selectors";
import { getAutomationsOfEvent } from "src/app/shared/selectors/automations.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getGroups,
	getModulesCustomsFieldsOfModule
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByType, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import {
	AnalyticsService,
	EventUsersService,
	FormsService,
	StorageService,
	TinyService,
	UtilityService
} from "src/app/shared/services";
import { AutomationsService } from "src/app/shared/services/automations.service";
import { environment } from "src/environments/environment";
import { IFullCustomField } from "../../../../../../../shared/interfaces/custom-fields.interfaces";
import { CustomFieldsEditComponent } from "../../components/custom-fields/custom-fields-edit/custom-fields-edit.component";
import { ProfileGeneralInfoComponent } from "../components/profile-general-info/profile-general-info.component";
import { selectUrl, selectRouteNestedParams } from "src/app/shared/selectors/router.selectors";

@Component({
	selector: "app-profile-edit",
	templateUrl: "./profile-edit.page.html",
	styleUrls: ["./profile-edit.page.scss"]
})
export class ProfileEditPage implements OnInit, OnDestroy {
	@ViewChild(CustomFieldsEditComponent)
	customFieldComponent: CustomFieldsEditComponent;
	@ViewChild(ProfileGeneralInfoComponent)
	profileGeneralInfoComponent: ProfileGeneralInfoComponent;

	// Tiny API Key

	tinyInit: any = environment.platform.tinyMce.initNothing;

	getTinyInit() {
		return {
			...environment.platform.tinyMce.initNothing,
			setup: (ed) => {
				ed.on("init", () => {
					console.warn("Tiny instance of " + this.constructor.name + "component is loaded : " + ed.id);
					// this.STiny.trackTinyMceLoad();
					setTimeout(() => {
						// hide '.tox-notifications-container' if it exists
						const element = document.querySelector(".tox-notifications-container");
						if (element) {
							element.setAttribute("style", "display: none !important");
						}
					}, 50);
				});
			}
		};
	}

	@Input() eventId: string;
	@Input() moduleId: string;
	@Input() eventUserProfileId: string;
	@Input() mode: string;

	subscriptions: Subscription[] = [];
	languageSubscription: Subscription;
	eventUserSub: Subscription;
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	loader: boolean = true;

	typeModule = TypeModule;

	// eventId: string;
	event: IEvent;
	// eventUserProfileId: string;
	eventUserProfile: IEventUser;
	eventUser: IEventUser;
	// moduleId: string;
	module: IModule;
	user: IUser;
	scheduleId: string;

	currentLanguage: string = environment.platform.defaultLanguage;

	canRequestAppointment: boolean = false;
	formEditEventUser: UntypedFormGroup;
	computedCustomFields: IFullCustomField[];
	baseCustomFields: ICustomField[];
	modulesCustomsFields: IModuleCustomField[];
	customFieldMediaToUpload: IFullCustomField[] = [];
	customFieldFormValid: boolean = false;
	attendeeModuleIds: string[];
	groupIds: string[];

	isMobile: boolean = false;
	firstTimePatchForm: boolean = false;
	automations: IAutomation[] = [];
	showCustomFieldEditForm: boolean;

	constructor(
		private SAnalytics: AnalyticsService,
		private fb: UntypedFormBuilder,
		private store: Store,
		private SUtility: UtilityService,
		private SEventUsers: EventUsersService,
		public STranslate: TranslateService,
		private snackbar: MatSnackBar,
		private storage: StorageService,
		private modalCtrl: ModalController,
		private SForms: FormsService,
		private platform: Platform,
		private SAutomation: AutomationsService,
		private STiny: TinyService
	) {
		this.isMobile =
			(this.platform.is("ios") || this.platform.is("android") || this.platform.is("mobileweb")) &&
			window.innerWidth < 768
				? true
				: false;

		this.formEditEventUser = this.fb.group({
			name: [""],
			email: [{ value: "", disabled: true }],
			description: [""],
			website: [""],
			linkedin: [""],
			facebook: [""],
			instagram: [""],
			twitter: [""]
		});
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSubscription = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
	}

	ionViewWillEnter() {
		this.subscriptions.push(
			this.store.select(selectUrl).subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						if (
							!this.moduleId ||
							!this.eventUserProfileId ||
							params.moduleId !== this.moduleId ||
							params.userId !== this.eventUserProfileId
						) {
							this.subscriptions.forEach((sub) => sub.unsubscribe());

							if (!this.mode) {
								this.moduleId = params.moduleId;
								this.eventUserProfileId = params.userId;
							}

							this.getDatas();
						}
					});
			})
		);
	}

	ngOnInit() {
		this.subscriptions.push(
			this.store.select(getAutomationsOfEvent).subscribe((automations) => {
				if (!_.isEqual(this.automations, automations)) {
					this.automations = automations;
				}
			})
		);
	}

	ionViewWillLeave() {
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.customFieldComponent.destroyComponent();
	}

	// ionWillViewLeave() {
	// 	this.showCustomFieldEditForm = false;
	// 	this.subscriptions.forEach((sub) => sub.unsubscribe());
	// 	this.customFieldComponent.destroyComponent();
	// }

	ngOnDestroy() {
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.customFieldComponent.destroyComponent();
	}

	/**
	 * Get basic datas
	 */
	getDatas() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getCurrentEvent),
				this.store.select(getCurrentUser),
				this.store.select(getSpecificModule(this.moduleId)),
				this.store.select(getMyEventUser),
				this.store.select(getGroups),
				this.store.select(getModulesByType(TypeModule.ATTENDEE))
			]).subscribe(
				(results) => {
					this.event = results[0];
					this.user = results[1];
					this.module = results[2];
					this.eventUser = results[3];
					this.groupIds = results[4].map((group) => group.uid);
					this.attendeeModuleIds = results[5].map((module) => module.uid);

					if (this.event && this.module && this.eventUser) {
						this.getEventUserProfile();
					}
				},
				(error) => {
					console.error("🚀  ~ error while getting datas:", error);
					this.loader = false;
				}
			)
		);
	}

	/**
	 * Get event user profile
	 */
	getEventUserProfile() {
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}
		this.eventUserSub = combineLatest([
			this.SEventUsers.getEventUser(this.event.uid, this.eventUserProfileId),
			this.SEventUsers.getSpecificEventUserUpdatedSettings(this.event.uid, this.moduleId, this.eventUserProfileId)
		]).subscribe((results) => {
			if (results[0]) {
				const tmpEventUserProfile: IEventUser = results[0];
				if (results[1]) {
					tmpEventUserProfile.updatedSettings = results[1];
				} else {
					tmpEventUserProfile.updatedSettings = {
						accessModule: {},
						accessModulesArray: [],
						appointmentsSchedules: [],
						connected: true,
						eventId: tmpEventUserProfile.eventId,
						language: this.currentLanguage,
						lastAccessDate: "",
						lastSeenNotifTime: 0,
						moduleId: tmpEventUserProfile.moduleId,
						readNotifications: "",
						timezoneType: "local",
						userId: tmpEventUserProfile.uid,
						typeUser: tmpEventUserProfile.type
					};

					this.SEventUsers.createEventUserUpdatedSettings(
						this.event.uid,
						tmpEventUserProfile.moduleId,
						tmpEventUserProfile.updatedSettings
					);
				}

				this.eventUserProfile = _.cloneDeep(tmpEventUserProfile);

				// init user visibility ability if needed
				if (this.module.options.enableUserFieldsHideAbility && this.eventUserProfile) {
					this.initFieldsVisibility();
				}

				if (!this.firstTimePatchForm) {
					this.patchEditForm();
					this.firstTimePatchForm = true;
				}
				this.getCustomFields();
			} else {
				this.loader = false;
			}
		});
	}

	/**
	 * Get event groups
	 */
	getGroupIds() {
		this.subscriptions.push(
			this.store.select(getGroups).subscribe((groups) => {
				this.groupIds = groups.map((group) => group.uid);
			})
		);
	}

	/**
	 * getCustomFields
	 */
	getCustomFields() {
		this.computedCustomFields = [];

		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(getModulesCustomsFieldsOfModule(this.module.uid))
			])
				// .pipe(skipWhile(() => !this.eventUserProfile))
				.subscribe((results) => {
					this.baseCustomFields = results[0];
					results[0];
					results[1];

					if (results[1].length > 0) {
						results[1].forEach((customField) => {
							const baseCustomFieldCorresponding = results[0].find(
								(custField) => custField.uid === customField.uid
							);

							if (baseCustomFieldCorresponding) {
								this.computedCustomFields.push({
									baseSettings: baseCustomFieldCorresponding,
									moduleSettings: customField,
									fieldDatas: {
										uid: "",
										field: {
											date: {
												day: 0,
												month: 0,
												year: 0,
												value: "",
												fullDateISO: "",
												zone: ""
											},
											file: {
												lastModifiedDate: "",
												name: "",
												type: "",
												url: "",
												size: 0
											},
											image: {
												format: "",
												lastModifiedDate: "",
												name: "",
												url: "",
												size: 0,
												isExternalUrl: false
											},
											module: {
												items: [],
												itemsIdentifier: [],
												moduleType: -1
											},
											multiLanguageText: {
												ArAR: "",
												DeDE: "",
												EnUS: "",
												EsES: "",
												FrFR: "",
												PtBR: ""
											},
											multiLanguageSelectArray: [],
											multiLanguageTextArray: {
												ArAR: [],
												DeDE: [],
												EnUS: [],
												EsES: [],
												FrFR: [],
												PtBR: []
											},
											numeric: 0,
											phoneNumber: {
												countryCode: "",
												dialCode: "",
												e164Number: "",
												internationalNumber: "",
												nationalNumber: "",
												number: ""
											},
											text: ""
										}
									}
								});
							}
						});
					}
					this.loader = false;
					this.showCustomFieldEditForm = true;
				})
		);
	}

	/**
	 * addRequiredValidators
	 * @description Adding validators from the module options
	 */
	addRequiredValidators() {
		this.formEditEventUser.get("name").setValidators([Validators.minLength(3), Validators.required]);
		this.formEditEventUser
			.get("description")
			.setValidators(
				this.module.options.requiredFields.description.required
					? [Validators.required, Validators.minLength(15), Validators.maxLength(5000)]
					: [Validators.minLength(0), Validators.maxLength(5000)]
			);

		this.formEditEventUser.get("website").setValidators(
			this.module.options.requiredFields.website.required
				? [
						Validators.required
						// Validators.pattern(
						// 	// eslint-disable-next-line no-useless-escape, max-len
						// 	this.SForms.urlRegexString
						// )
				  ]
				: [
						// Validators.pattern(
						// 	// eslint-disable-next-line no-useless-escape, max-len
						// 	this.SForms.urlRegexString
						// )
				  ]
		);

		this.formEditEventUser.get("facebook").setValidators(
			this.module.options.requiredFields.facebook.required
				? [
						Validators.required
						// Validators.pattern(
						// 	// eslint-disable-next-line max-len
						// 	this.SForms.urlRegexString
						// )
				  ]
				: [
						// Validators.pattern(
						// 	// eslint-disable-next-line max-len
						// 	this.SForms.urlRegexString
						// )
				  ]
		);

		this.formEditEventUser.get("linkedin").setValidators(
			this.module.options.requiredFields.linkedin.required
				? [
						Validators.required
						// Validators.pattern(
						// 	// eslint-disable-next-line max-len
						// 	this.SForms.urlRegexString
						// )
				  ]
				: [
						// Validators.pattern(
						// 	// eslint-disable-next-line max-len
						// 	this.SForms.urlRegexString
						// )
				  ]
		);

		const test = new RegExp(this.SForms.urlRegexString);

		this.formEditEventUser.get("twitter").setValidators(
			this.module.options.requiredFields.twitter.required
				? [
						Validators.required
						// Validators.pattern(
						// 	// eslint-disable-next-line max-len
						// 	this.SForms.urlRegexString
						// )
				  ]
				: [
						// Validators.pattern(
						// 	// eslint-disable-next-line max-len
						// 	this.SForms.urlRegexString
						// )
				  ]
		);

		this.formEditEventUser.get("instagram").setValidators(
			this.module.options.requiredFields.instagram.required
				? [
						Validators.required
						// Validators.pattern(
						// 	// eslint-disable-next-line max-len
						// 	this.SForms.urlRegexString
						// )
				  ]
				: [
						// Validators.pattern(
						// 	// eslint-disable-next-line max-len
						// 	this.SForms.urlRegexString
						// )
				  ]
		);
	}

	/**
	 * Patch form
	 */
	patchEditForm() {
		this.addRequiredValidators();
		this.formEditEventUser.patchValue({
			description: this.eventUserProfile.description[this.event.language],
			name: this.eventUserProfile.name,
			email: this.eventUserProfile.email,
			website: this.eventUserProfile.socialMedias.website,
			facebook: this.eventUserProfile.socialMedias.facebook,
			instagram: this.eventUserProfile.socialMedias.instagram,
			linkedin: this.eventUserProfile.socialMedias.linkedin,
			twitter: this.eventUserProfile.socialMedias.twitter
		});
	}

	/**
	 * Edit attendee
	 */
	async editEventUser() {
		try {
			this.loader = true;
			this.store.dispatch(Loading({ payload: true }));
			const data = this.formEditEventUser.getRawValue();
			const updatedEventUser = Object.assign({}, this.eventUserProfile);

			/* ---- Pre-treatment for custom fields before sending them to firestore ---- */

			// Retreiving datas from the 'customFieldsGeneric' component
			const customFieldsDatas = this.customFieldComponent?.getFormValues();

			const customFieldsArray = this.getCustomFieldsData(customFieldsDatas);
			/* ---- END OF "Pre-treatment for custom fields before sending them to firestore" ---- */

			// Update eventUser groups if needed

			if (!this.getCustomFieldSelectGroups(updatedEventUser)) {
				return;
			}

			// Assigning data
			updatedEventUser.name = data.name;
			updatedEventUser.email = this.eventUserProfile.email; // Can't be updated
			updatedEventUser.editedProfile = true;
			updatedEventUser.description = {
				ArAR: "ArAR" === this.event.language ? data.description : "",
				DeDE: "DeDE" === this.event.language ? data.description : "",
				EsES: "EsES" === this.event.language ? data.description : "",
				EnUS: "EnUS" === this.event.language ? data.description : "",
				FrFR: "FrFR" === this.event.language ? data.description : "",
				PtBR: "PtBR" === this.event.language ? data.description : ""
			};
			updatedEventUser.updatedDate = DateTime.local().toMillis();

			// Social medias
			const socialMedias = {
				facebook: data.facebook,
				twitter: data.twitter,
				instagram: data.instagram,
				linkedin: data.linkedin,
				website: data.website
			};
			updatedEventUser.socialMedias = { ...socialMedias };
			updatedEventUser.customFields = updatedEventUser.customFields
				.filter(
					(cus) =>
						this.computedCustomFields
							.filter((cus) => !cus.moduleSettings.editionScope)
							.find((c) => c.baseSettings.uid === cus.uid) !== undefined
				)
				.concat(customFieldsArray);

			// If there's media to upload
			await this.uploadCustomFieldMedia(updatedEventUser);

			// Update event user
			await this.SEventUsers.updateEventUser(this.eventId, this.moduleId, updatedEventUser);

			// update event user profile picture if needed
			await this.profileGeneralInfoComponent.saveProfilPicture();

			// update select option counter if needed
			for (const computedCus of this.customFieldComponent.computedCustomFields) {
				if (
					computedCus.baseSettings.type === TypeCustomFields.SELECT &&
					computedCus.baseSettings.enableSelectLimit
				) {
					this.customFieldComponent.updateModuleCustomFieldCount(computedCus.baseSettings);
				}
			}

			// Analytics
			this.SAnalytics.editEventUserProfile(this.eventUser);

			// Check automations
			const triggers = this.SAutomation.getAutomationTriggersByType(
				this.automations,
				TriggerType.MODULE_USER_EDITED_PROFIL
			);

			if (triggers.length > 0) {
				for (const trigger of triggers) {
					// Check if eventUser meet the trigger conditions
					if (
						this.SAutomation.checkAutomationTriggerConditions(trigger, updatedEventUser, {
							event: this.event,
							customFields: this.baseCustomFields,
							groupIds: this.groupIds,
							moduleIds: this.attendeeModuleIds
						})
					) {
						this.SAutomation.excecuteAutomationTrigger(trigger, {
							eventUser: this.eventUserProfile,
							event: this.event
						});
					}
				}
			}

			this.loader = false;
			this.store.dispatch(Loading({ payload: false }));
			this.snackbar.open(this.STranslate.instant("snackbar.update_successfull"), "", {
				duration: 3000,
				panelClass: "success-snackbar"
			});
			this.mode ? this.close("fill-profil-modal") : this.navigateBack();
		} catch (error) {
			// delete custom field image and file from the storage if needed
			// this.deleteCustomFieldFilesImagesFromStorage();

			this.loader = false;
			this.store.dispatch(Loading({ payload: false }));
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * getCustomFieldsData
	 */
	getCustomFieldsData(customsFieldsData: any) {
		return this.customFieldComponent.computedCustomFields
			.filter((cus) => cus.moduleSettings.editionScope)
			.map((custom) => {
				if (
					custom.baseSettings.type === TypeCustomFields.TEXT ||
					custom.baseSettings.type === TypeCustomFields.HMTL
				) {
					// multi language txt value
					return {
						uid: custom.baseSettings.uid,
						field: {
							multiLanguageText: {
								ArAR: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									: "",
								DeDE: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									: "",

								EnUS: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									: "",

								EsES: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									: "",

								FrFR: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									: "",

								PtBR: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
									: ""
							} as ILanguage
						} as IField
					};
				}
				if (custom.baseSettings.type === TypeCustomFields.SELECT) {
					// multi language select txt value
					return {
						uid: custom.baseSettings.uid,
						field: {
							multiLanguageText: {
								ArAR: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.ArAR
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.ArAR
									: "",
								DeDE: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.DeDE
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.DeDE
									: "",

								EnUS: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.EnUS
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.EnUS
									: "",

								EsES: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.EsES
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.EsES
									: "",

								FrFR: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.FrFR
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.FrFR
									: "",

								PtBR: customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.PtBR
									? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.PtBR
									: ""
							} as ILanguage
						} as IField
					};
				} else if (
					custom.baseSettings.type === TypeCustomFields.URL ||
					custom.baseSettings.type === TypeCustomFields.EMAIL
				) {
					// all "single language" text value
					return {
						uid: custom.baseSettings.uid,
						field: {
							text: customsFieldsData[`${custom.baseSettings.uid}`]
						} as IField
					};
				} else if (custom.baseSettings.type === TypeCustomFields.DATE) {
					// "Date" value
					const dateISO =
						DateTime.fromJSDate(customsFieldsData[`${custom.baseSettings.uid}`]).toISO() !== null
							? DateTime.fromJSDate(customsFieldsData[`${custom.baseSettings.uid}`]).toISO()
							: customsFieldsData[`${custom.baseSettings.uid}`];

					return {
						uid: custom.baseSettings.uid,
						field: {
							date: {
								day: parseInt(dateISO.split("T")?.[0]?.split("-")?.[2]),
								month: parseInt(dateISO.split("T")?.[0]?.split("-")?.[1]),
								year: parseInt(dateISO.split("T")?.[0]?.split("-")?.[0]),
								value: this.SUtility.getDateFormat(dateISO, this.currentLanguage),
								fullDateISO: dateISO,
								zone: environment.platform.defaultTimezone
							}
						} as IField
					};
				} else if (custom.baseSettings.type === TypeCustomFields.NUMERIC) {
					// "Number" type value
					return {
						uid: custom.baseSettings.uid,
						field: {
							numeric: customsFieldsData[`${custom.baseSettings.uid}`]
						} as IField
					};
				} else if (custom.baseSettings.type === TypeCustomFields.PHONE) {
					// "Phone Number" type value

					// formating data to corrspond with the back-office
					const phone = customsFieldsData[`${custom.baseSettings.uid}`] as IPhoneNumber;

					if (phone) {
						phone.number = phone.nationalNumber.split(" ")?.join().replace(/,/g, "");
						phone.internationalNumber = phone.internationalNumber?.split(" ").join().replace(/,/g, "");
						phone.nationalNumber = phone.nationalNumber?.split(" ").join().replace(/,/g, "");
						// phone.countryCode = phone.countryCode;
					}

					return {
						uid: custom.baseSettings.uid,
						field: {
							phoneNumber: phone
								? phone
								: {
										countryCode: "",
										dialCode: "",
										e164Number: "",
										internationalNumber: "",
										nationalNumber: "",
										number: ""
								  }
						} as IField
					};
				} else if (custom.baseSettings.type === TypeCustomFields.MULTI_TEXT) {
					// all array "multi language" text value
					return {
						uid: custom.baseSettings.uid,
						field: {
							multiLanguageTextArray:
								this.customFieldComponent.multipleTextValues[
									this.customFieldComponent.getMultiTextInputIndex(custom.baseSettings.uid)
								]?.multiLanguageTextArray
						} as IField
					};
				} else if (custom.baseSettings.type === TypeCustomFields.MULTI_SELECT) {
					// all array "multi language" select value
					const choices: string[] = customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`];
					return {
						uid: custom.baseSettings.uid,
						field: {
							multiLanguageSelectArray: choices.map((ch, i) => {
								return {
									ArAR:
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`] &&
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
											?.length > 0
											? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`][i]
													?.ArAR
											: "",
									DeDE:
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`] &&
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
											?.length > 0
											? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`][i]
													?.DeDE
											: "",
									EnUS:
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`] &&
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]?.length
											? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`][i]
													?.EnUS
											: "",
									EsES:
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`] &&
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
											?.length > 0
											? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`][i]
													?.EsES
											: "",
									FrFR:
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`] &&
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
											?.length > 0
											? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`][i]
													?.FrFR
											: "",
									PtBR:
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`] &&
										customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`]
											?.length > 0
											? customsFieldsData[`${custom.baseSettings.uid}_${this.currentLanguage}`][i]
													?.PtBR
											: ""
								} as ILanguage;
							})
						} as IField
					};
				} else if (custom.baseSettings.type === TypeCustomFields.IMAGE) {
					// all "image" field control

					this.customFieldMediaToUpload.push(custom);

					return {
						uid: custom.baseSettings.uid,
						field: {
							image: custom.fieldDatas.field.image
						}
					};
				} else if (custom.baseSettings.type === TypeCustomFields.FILE) {
					// all "file" field control

					this.customFieldMediaToUpload.push(custom);

					return {
						uid: custom.baseSettings.uid,
						field: {
							file: custom.fieldDatas.field.file
						}
					};
				} else if (custom.baseSettings.type === TypeCustomFields.ADDRESS) {
					// all "address" field control
					const string_address = customsFieldsData[`${custom.baseSettings.uid}`]
						? customsFieldsData[`${custom.baseSettings.uid}`]
						: "";

					return {
						uid: custom.baseSettings.uid,
						field: {
							address: {
								fullAddress: string_address,
								street: "",
								number: "",
								city: "",
								country: "",
								postalCode: "",
								state: "",
								region: "",
								url: ""
							}
						}
					};
				} else if (custom.baseSettings.type === TypeCustomFields.CHECKBOX) {
					// Checkbox field control
					return {
						uid: custom.baseSettings.uid,
						field: {
							checkbox: customsFieldsData[`${custom.baseSettings.uid}`]
						}
					};
				} else if (custom.baseSettings.type === TypeCustomFields.COUNTRY) {
					// COUNTRY field control
					return {
						uid: custom.baseSettings.uid,
						field: {
							country: custom.fieldDatas.field.country
						}
					};
				} else if (custom.baseSettings.type === TypeCustomFields.MODULE) {
					// "Module" field control
					return {
						uid: custom.baseSettings.uid,
						field: {
							module: {
								items: this.eventUserProfile.customFields.find(
									(cus) => cus.uid === custom.baseSettings.uid
								)
									? this.eventUserProfile.customFields.find(
											(cus) => cus.uid === custom.baseSettings.uid
									  ).field.module.items
									: [],
								itemsIdentifier: this.eventUserProfile.customFields.find(
									(cus) => cus.uid === custom.baseSettings.uid
								)
									? this.eventUserProfile.customFields.find(
											(cus) => cus.uid === custom.baseSettings.uid
									  ).field.module.itemsIdentifier
									: [],
								moduleType: custom.baseSettings.customFieldModuleType
							}
						} as IField
					};
				}
			});
	}

	/**
	 * getCustomFieldSelectGroups
	 * @returns string[]
	 */
	getCustomFieldSelectGroups(eventUser: IEventUser): boolean {
		const customsFieldsData = this.customFieldComponent.getFormValues();

		const selectCustomFields = this.customFieldComponent.computedCustomFields.filter(
			(custom) =>
				custom.baseSettings.type === TypeCustomFields.SELECT ||
				custom.baseSettings.type === TypeCustomFields.MULTI_SELECT
		);

		selectCustomFields.forEach((selectCustomField) => {
			let groups: string[] = [];
			const baseOptions = selectCustomField.baseSettings.options.map((opt) => opt[this.event.language]);
			let baseOptionGroups = [];

			// Check if "Group association is enabled"
			if (selectCustomField.baseSettings.enableGroupAssociation) {
				if (
					customsFieldsData[`${selectCustomField.baseSettings.uid}_${this.currentLanguage}`] &&
					selectCustomField.baseSettings.optionsGroup &&
					selectCustomField.baseSettings.optionsGroup.length > 0
				) {
					if (selectCustomField.baseSettings.type === TypeCustomFields.SELECT) {
						groups = this.getCustomFieldSelectGroupValues(selectCustomField, customsFieldsData);
						baseOptionGroups = this.getCustomFieldSelectGroupValues(selectCustomField, null, baseOptions);
					} else {
						groups = this.getCustomFieldMultiSelectGroupValues(selectCustomField, customsFieldsData);
						baseOptionGroups = this.getCustomFieldMultiSelectGroupValues(
							selectCustomField,
							null,
							baseOptions
						);
					}
				}

				/**
				 *  Remove groups that are present in eventUser.groups and baseOptions
				 *  but not in groups selected by eventUser
				 */
				let groupToRemove = [];
				let otherGroups = [];

				if (groups.length === 0) {
					eventUser.groups = _.uniq(
						eventUser.groups.filter(
							(grp) => !baseOptionGroups.filter((baseOptGr) => !groups.includes(baseOptGr)).includes(grp)
						)
					);
				} else {
					groupToRemove = eventUser.groups.filter((grp) =>
						baseOptionGroups.filter((baseOptGr) => !groups.includes(baseOptGr)).includes(grp)
					);

					otherGroups = eventUser.groups.filter(
						(grp) => !baseOptionGroups.filter((baseOptGr) => !groups.includes(baseOptGr)).includes(grp)
					);

					eventUser.groups = _.uniq(otherGroups.concat(groups));
				}
			}
		});

		// prevent maximum group in eventUser
		if (eventUser.groups.length > 10) {
			this.loader = false;
			this.store.dispatch(Loading({ payload: false }));
			this.snackbar.open(this.STranslate.instant("snackbar.limit_groups_exced"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
			return false;
		}

		return true;
	}

	/**
	 * getCustomFieldMultiSelectGroupValues
	 * @param customField
	 * @param customsFieldsData
	 * @returns
	 */
	getCustomFieldMultiSelectGroupValues(
		customField: IFullCustomField,
		customsFieldsData: any,
		customDatas?: string[]
	): string[] {
		let groups = [];
		let options: ILanguage[] = [];
		if (customDatas) {
			options = customField.baseSettings.options.filter((opt) => {
				return customDatas?.includes(opt[this.event.language]);
			});
		} else {
			options = customField.baseSettings.options.filter((opt) => {
				return customsFieldsData[
					`${customField.baseSettings.name[this.event.language]}_${customField.baseSettings.uid}_${
						this.event.language
					}`
				]?.includes(opt[this.event.language]);
			});
		}

		if (options.length > 0) {
			options.forEach((option) => {
				const optionIndex = customField.baseSettings.options.findIndex(
					(opt) => opt[this.event.language] === option[this.event.language]
				);

				if (optionIndex !== -1) {
					this.groupIds.some((grpId) =>
						customField.baseSettings.optionsGroup[optionIndex][
							`${this.event.language}_Group_${optionIndex}`
						].includes(grpId)
					) &&
						(groups = _.uniq(
							groups.concat(
								customField.baseSettings.optionsGroup[optionIndex][
									`${this.event.language}_Group_${optionIndex}`
								]
							)
						));
				}
			});
		}

		return groups;
	}

	/**
	 * getCustomFieldSelectGroupValues
	 * @param customField
	 * @param customsFieldsData
	 * @param options
	 * @returns
	 */
	getCustomFieldSelectGroupValues(customField: IFullCustomField, customsFieldsData: any, options?: ILanguage[]) {
		let groups = [];
		if (!options) {
			const optionIndex = customField.baseSettings.options.findIndex(
				(opt) =>
					opt[this.event.language] ===
						(customsFieldsData[
							`${customField.baseSettings.name[this.event.language]}_${customField.baseSettings.uid}_${
								this.event.language
							}`
						] ||
							customsFieldsData[
								`${customField.baseSettings.name[this.event.language]}_${customField.baseSettings.uid}`
							]) && customField.baseSettings.type === TypeCustomFields.SELECT
			);

			if (optionIndex !== -1) {
				this.groupIds.some((grpId) =>
					customField.baseSettings.optionsGroup[optionIndex][
						`${this.event.language}_Group_${optionIndex}`
					]?.includes(grpId)
				) &&
					(groups = _.uniq(
						groups.concat(
							customField.baseSettings.optionsGroup[optionIndex][
								`${this.event.language}_Group_${optionIndex}`
							]
						)
					));
			}
		} else {
			options.forEach((option) => {
				const optionIndex = customField.baseSettings.options.findIndex(
					(opt) => opt[this.event.language] === option
				);

				if (optionIndex !== -1) {
					this.groupIds.some((grpId) => {
						return customField.baseSettings.optionsGroup[optionIndex][
							`${this.event.language}_Group_${optionIndex}`
						]?.includes(grpId);
					}) &&
						(groups = _.uniq(
							groups.concat(
								customField.baseSettings.optionsGroup[optionIndex][
									`${this.event.language}_Group_${optionIndex}`
								]
							)
						));
				}
			});
		}

		return groups;
	}
	/**
	 * uploadCustomFieldMedia
	 */
	async uploadCustomFieldMedia(eventUser: IEventUser) {
		try {
			if (!eventUser) throw new Error("No event user !");

			if (this.customFieldMediaToUpload.length > 0) {
				for (const customMedia of this.customFieldMediaToUpload) {
					if (customMedia.baseSettings.type === TypeCustomFields.IMAGE) {
						if (
							!customMedia.fieldDatas.field.image.isExternalUrl &&
							customMedia.fieldDatas &&
							!customMedia.fieldDatas.field.image.url
						) {
							try {
								this.deleteCustomFieldMediaFromStorage(customMedia);
							} catch (error) {}
						} else {
							const url = await firstValueFrom(
								this.customFieldComponent.importCustomFieldFileImage(eventUser, customMedia)
							);

							if (url) {
								eventUser.customFields.find(
									(cus) => cus.uid === customMedia.baseSettings.uid
								).field.image.url = url;
							}
						}
					} else {
						// file
						if (
							(customMedia.fieldDatas && !customMedia.fieldDatas.field.file.url) ||
							customMedia.fieldDatas.field.file.url === ""
						) {
							try {
								this.deleteCustomFieldMediaFromStorage(customMedia);
							} catch (error) {}
						} else {
							const url = await firstValueFrom(
								this.customFieldComponent.importCustomFieldFileImage(eventUser, customMedia)
							);

							if (url) {
								eventUser.customFields.find(
									(cus) => cus.uid === customMedia.baseSettings.uid
								).field.file.url = url;
							}
						}
					}
				}
			}
		} catch (error) {
			if (error.error.code !== 404 && error.error.message !== "Not Found.") {
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		}
	}

	/**
	 * deleteCustomFieldFilesImagesFromStorage
	 * @desc Delete (if necessary) customField files and images from the storage
	 * @param customField
	 */
	async deleteCustomFieldFilesImagesFromStorage() {
		if (this.customFieldMediaToUpload.length > 0) {
			for (const customMedia of this.customFieldMediaToUpload) {
				try {
					const path = `/events/${this.event.uid}/modules/${this.module.uid}/event-users/${
						this.eventUserProfile.uid
					}/${
						customMedia.baseSettings.type === TypeCustomFields.FILE
							? customMedia.fieldDatas.field.file.name
							: customMedia.fieldDatas.field.image.name
					}`;

					await this.storage.deleteDocumentOnStorage(path);
				} catch (error) {}
			}
		}
	}

	/**
	 * deleteCustomFieldFilesImagesFromStorage
	 * @desc Delete (if necessary) customField files and images from the storage
	 * @param customField
	 */
	async deleteCustomFieldMediaFromStorage(customField: IFullCustomField) {
		if (customField) {
			try {
				const path =
					`/events/${this.event.uid}/modules/${this.module.uid}` +
					`/event-users/${this.eventUserProfile.uid}/` +
					`${
						customField.baseSettings.type === TypeCustomFields.FILE
							? customField.fieldDatas.field.file.name
							: customField.fieldDatas.field.image.name
					}`;

				try {
					const fileExist = await this.storage.checkIfFileExists(path);
					fileExist && (await this.storage.deleteDocumentOnStorage(path));
				} catch (error) {}
			} catch (error) {}
		}
	}

	/**
	 * navigateBack
	 */
	navigateBack() {
		this.showCustomFieldEditForm = false;
		this.SUtility.callGoBackOnHeader();
	}

	/**
	 * Close modal
	 */
	async close(modalId: string) {
		try {
			await this.modalCtrl.dismiss(null, null, modalId);
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open fill profil modal
	 */
	async openAccompanyingForm() {
		try {
			const modal = await this.modalCtrl.create({
				id: "accompanying-form-modal",
				component: PathComponents.registerForm,
				componentProps: {
					eventId: this.event.uid,
					moduleId: this.eventUser.moduleId,
					eventUserProfileId: this.eventUser.uid,
					mode: "accompanying-form-modal"
				},
				cssClass: "full-sized-modal"
			});

			await modal.present();
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * updateFieldVisibility
	 * @param key
	 */
	async updateFieldVisibility(key: string) {
		if (this.eventUserProfile.updatedSettings) {
			if (!this.eventUserProfile.updatedSettings.fieldsVisibility) {
				// Retro-compatibility code
				try {
					this.eventUserProfile.updatedSettings["fieldsVisibility"] =
						await this.SEventUsers.buildFieldsVisibility(
							this.event.uid,
							this.eventUserProfile.moduleId,
							this.eventUserProfile
						);
				} catch (error) {
					this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
				}
			}

			if (this.eventUserProfile.updatedSettings.fieldsVisibility[key] === undefined) {
				this.eventUserProfile.updatedSettings.fieldsVisibility[key] =
					!this.module.options.requiredFields[key].hiding.default;
			} else {
				this.eventUserProfile.updatedSettings.fieldsVisibility[key] =
					!this.eventUserProfile.updatedSettings.fieldsVisibility[key];
			}
		}
	}

	/**
	 * initFieldsVisibility
	 */
	async initFieldsVisibility() {
		try {
			if (this.eventUserProfile.updatedSettings.fieldsVisibility) {
				this.eventUserProfile.updatedSettings.fieldsVisibility = await this.SEventUsers.buildFieldsVisibility(
					this.event.uid,
					this.module.uid,
					this.eventUserProfile
				);
			} else {
				const visibilityDatas = await this.SEventUsers.buildFieldsVisibility(this.event.uid, this.module.uid);
				this.eventUserProfile.updatedSettings = {
					...this.eventUserProfile.updatedSettings,
					fieldsVisibility: visibilityDatas
				};
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * showUserOrModuleVisibilityOptions
	 * @param key
	 */
	showUserOrModuleVisibilityOptions(key: string) {
		return (
			this.module.options.enableUserFieldsHideAbility &&
			this.eventUserProfile.updatedSettings.fieldsVisibility?.[key]
		);
	}

	checkCustomFieldFormValid(evt: boolean) {
		this.customFieldFormValid = evt;
	}
}
