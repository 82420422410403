import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { Browser } from "@capacitor/browser";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { AlertController, ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { Observable, Subscription, combineLatest, firstValueFrom, of } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { GetHeaderState, Loading, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import {
	ICustomField,
	IEvent,
	IEventUser,
	IFullCustomField,
	ILocation,
	IModule,
	IModuleCustomField,
	IVisio
} from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getModulesByType, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	AnalyticsService,
	AppointmentsService,
	ChatsService,
	FirestoreService,
	UtilityService,
	VisiosService
} from "src/app/shared/services";
import { ConfirmIcsDownloadComponent } from "../../../modals/confirm-ics-download/confirm-ics-download.component";
import { IAppointment, IAppointmentRule } from "src/app/shared/interfaces/appointments.interfaces";
import { AppointmentTimeSlotStatus } from "src/app/shared/enums/type-appointments";
import { DateTime } from "luxon";
import { where } from "@angular/fire/firestore";
import {
	getBaseCustomFields,
	getLocations,
	getModulesCustomFields,
	getSpecificLocation
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { MatSnackBar } from "@angular/material/snack-bar";
import { getChatOfTwo } from "src/app/shared/selectors/chats.selectors";
import { IChat } from "src/app/shared/interfaces/chats.interfaces";
import { TypeModule } from "src/app/shared/enums/type-module";
import { PathComponents } from "src/app/shared/paths/path-components";
import { DOCUMENT } from "@angular/common";
import { VisioComponent } from "../../components/visio/visio.component";

@Component({
	selector: "app-list-appointments",
	templateUrl: "./list-appointments.page.html",
	styleUrls: ["./list-appointments.page.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListAppointmentsPage implements OnDestroy {
	languageSub: Subscription;
	appointmentsSub: Subscription;
	subscriptions: Subscription[] = [];

	loader: boolean = true;
	limit: number = 10;

	event: IEvent;
	eventId: string;
	module: IModule;
	moduleId: string;
	eventUser: IEventUser = null;
	locations: ILocation[] = [];
	baseCustomFields: ICustomField[] = [];
	modulesCustomsFields: IModuleCustomField[] = [];

	appointments: IAppointment[] = [];
	filteredAppointments: IAppointment[] = [];
	showedAppointments: IAppointment[] = [];

	AppointmentTimeSlotStatus = AppointmentTimeSlotStatus;

	segmentSelected: number = AppointmentTimeSlotStatus.APPOINTMENT_PENDING;

	isMobile: boolean = false;
	icsLoader: boolean = false;
	appointmentDownloadButtonLoader: boolean = false;

	currentLanguage: string;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private SAnalytics: AnalyticsService,
		private store: Store,
		private platform: Platform,
		private SAppointments: AppointmentsService,
		private SChats: ChatsService,
		private SFirestore: FirestoreService,
		public SUtility: UtilityService,
		private alertCtrl: AlertController,
		private modalController: ModalController,
		private navCtrl: NavController,
		private snackbar: MatSnackBar,
		private modalCtrl: ModalController,
		private STranslate: TranslateService,
		private SVisios: VisiosService,
		private cd: ChangeDetectorRef
	) {
		this.isMobile = this.platform.is("ios") || this.platform.is("android") ? true : false;
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSub = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
	}

	ionViewWillEnter() {
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.subscriptions.forEach((sub) => sub.unsubscribe());

						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_APPOINTMENT_MODULE
						);

						this.initDatas();
					});
			});
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getLocations();
		this.getCustomFields();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		if (this.languageSub && !this.languageSub.closed) {
			this.languageSub.unsubscribe();
		}
		if (this.appointmentsSub && !this.appointmentsSub.closed) {
			this.appointmentsSub.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				this.module = module;
				if (this.module) {
					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: null,
								module: this.module,
								type: this.module.type,
								title: this.module.name
							}
						})
					);
				}

				this.cd.markForCheck();
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.store
			.select(getCurrentEventUser)
			.pipe(take(1))
			.subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
					this.getAppointments();
				}
			});
	}

	getLocations() {
		this.subscriptions.push(
			this.store.select(getLocations("asc")).subscribe((locations) => {
				if (!_.isEqual(this.locations, locations)) {
					this.locations = locations;
				}
			})
		);
	}

	/**
	 * getCustomFields
	 */
	getCustomFields() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(getModulesCustomFields)
			]).subscribe((results) => {
				this.baseCustomFields = results[0];
				this.modulesCustomsFields = results[1];
				this.cd.markForCheck();
			})
		);
	}

	getAppointments() {
		if (this.appointmentsSub && !this.appointmentsSub.closed) {
			this.appointmentsSub.unsubscribe();
		}
		this.appointmentsSub = this.SAppointments.getAppointmentsOfEventUser(
			this.eventId,
			this.moduleId,
			this.eventUser.uid
		)
			.pipe(
				switchMap((appointments: IAppointment[]) => {
					const eventUsersIds: string[] = [];

					// Filter appointments who don't have rules
					appointments = appointments.filter((appointment) => {
						if (appointment.status !== AppointmentTimeSlotStatus.APPOINTMENT_PENDING) {
							return true;
						}
						const checkRule = this.module.options.rules.find(
							(rule) => rule.uid === appointment.sourceRuleId
						);
						return checkRule ? true : false;
					});

					appointments.forEach((appointment) => {
						if (!eventUsersIds.includes(appointment.recipient.uid)) {
							eventUsersIds.push(appointment.recipient.uid);
						}
						if (!eventUsersIds.includes(appointment.applicant.uid)) {
							eventUsersIds.push(appointment.applicant.uid);
						}
					});

					if (eventUsersIds.length === 0) {
						return of(
							appointments.map((appointment) => {
								return {
									recipientDatas: null,
									...appointment
								};
							})
						);
					} else {
						const obs: Observable<IEventUser>[] = [];
						eventUsersIds.forEach((uid) => {
							obs.push(
								this.SFirestore.collectionGroupValueChangesDocuments(`event-users`, [
									where("eventId", "==", this.eventId),
									where("uid", "==", uid)
								]).pipe(
									take(1),
									switchMap((results) => {
										if (results.length > 0) {
											return of(results[0] as IEventUser);
										} else {
											return of(null);
										}
									})
								)
							);
						});
						return combineLatest(obs).pipe(
							take(1),
							switchMap((results) => {
								const eventUsers = _.flatten(results.filter((data) => data));
								appointments = appointments.map((appointment) => {
									const recipient = eventUsers.find(
										(eventUser) => eventUser.uid === appointment.recipient.uid
									);
									const applicant = eventUsers.find(
										(eventUser) => eventUser.uid === appointment.applicant.uid
									);

									return {
										applicantDatas: applicant ? applicant : null,
										recipientDatas: recipient ? recipient : null,
										...appointment
									};
								});
								return of(appointments);
							})
						);
					}
				})
			)
			.subscribe((appointments) => {
				if (!_.isEqual(this.appointments, appointments)) {
					this.appointments = appointments;
				}

				this.filterAppointments();
			});
	}

	filterAppointments() {
		this.filteredAppointments = this.appointments
			.filter((appointment) => {
				const dateNow = DateTime.local();
				const dateEndAppointment = DateTime.fromISO(appointment.startDate).plus({
					minutes: appointment.duration
				});
				if (
					this.segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_ENDED &&
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED &&
					dateEndAppointment < dateNow
				) {
					return true;
				} else if (
					this.segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED &&
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED &&
					dateEndAppointment > dateNow
				) {
					return true;
				} else if (
					this.segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_PENDING &&
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING &&
					dateEndAppointment > dateNow
				) {
					return true;
				} else if (
					this.segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED &&
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED
				) {
					return true;
				}

				return false;
			})
			.sort((a, b) =>
				Math.abs(DateTime.fromISO(a.startDate).diffNow("second").seconds) >
				Math.abs(DateTime.fromISO(b.startDate).diffNow("second").seconds)
					? 1
					: Math.abs(DateTime.fromISO(a.startDate).diffNow("second").seconds) <
					  Math.abs(DateTime.fromISO(b.startDate).diffNow("second").seconds)
					? -1
					: 0
			);

		this.showedAppointments = this.filteredAppointments.slice(0, this.limit);
		this.cd.markForCheck();
	}

	filterModulesCustomFields(eventUser: IEventUser) {
		return this.modulesCustomsFields.filter((module) => module.moduleId === eventUser.moduleId);
	}

	buildCustomFieldsForEventUser(eventUser: IEventUser) {
		const modulesCustomsFields: IModuleCustomField[] = this.filterModulesCustomFields(eventUser);
		const customFields: IFullCustomField[] = [];
		if (modulesCustomsFields.filter((cus) => cus.canBeTag).length > 0) {
			modulesCustomsFields
				.filter((cus) => cus.canBeTag)
				.forEach((customField) => {
					const baseCustomFieldCorresponding = this.baseCustomFields.find(
						(custField) => custField.uid === customField.uid
					);

					if (baseCustomFieldCorresponding) {
						const data = eventUser.customFields.find((data) => data.uid === customField.uid);
						customFields.push({
							baseSettings: baseCustomFieldCorresponding,
							moduleSettings: customField,
							fieldDatas: data
								? data
								: {
										uid: "",
										field: {}
								  }
						});
					}
				});
		}
		return customFields;
	}

	getLocationsOfRule(appointment: IAppointment) {
		const rule: IAppointmentRule =
			this.module.options && this.module.options.rules && this.module.options.rules.length > 0
				? this.module.options.rules.find((rule) => rule.uid === appointment.sourceRuleId)
				: null;

		if (rule) {
			return this.locations.filter((location) => rule.localisations.includes(location.uid));
		} else {
			return [];
		}
	}

	getLocation(locationId: string) {
		return this.locations.find((location) => location.uid === locationId);
	}

	/**
	 * Get part of date
	 * @param date
	 * @param unit
	 * @returns
	 */
	getPartOfDate(date: string, unit: string) {
		return this.SUtility.formatDate(this.event, this.eventUser, date, unit);
	}

	getEndScheduleDate(date: string, duration: number) {
		return this.SUtility.getDateWithTimezoneType(this.event, this.eventUser.updatedSettings.timezoneType, date)
			.plus({ minutes: duration })
			.toISO();
	}

	segmentChanged(evt: any) {
		this.segmentSelected = parseInt(evt.detail.value);
		this.filterAppointments();
	}

	getWhoOnAppointment(appointment: IAppointment, userId: string) {
		if (appointment.recipient.uid === userId) {
			return appointment.recipient.name;
		} else if (appointment.applicant.uid === userId) {
			return appointment.applicant.name;
		}
	}

	moreAppointments(evt: any) {
		if (evt) {
			this.limit += 10;
			if (this.showedAppointments.length <= this.filteredAppointments.length) {
				this.showedAppointments = this.filteredAppointments.slice(0, this.limit);
				evt.target.complete();
			} else {
				evt.target.disabled;
			}
		}
	}

	showLastSlot(appointment: IAppointment) {
		const dateNow = DateTime.local();
		const dateEndAppointment = DateTime.fromISO(appointment.startDate).plus({
			minutes: appointment.duration
		});
		if (
			appointment.status === this.AppointmentTimeSlotStatus.APPOINTMENT_PENDING ||
			appointment.status === this.AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
			appointment.status === this.AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED
		) {
			return true;
		} else {
			return false;
		}
	}

	appointmentIsEnded(appointment: IAppointment) {
		let dateNow = this.SUtility.getDateWithTimezoneType(
			this.event,
			this.eventUser.updatedSettings.timezoneType,
			DateTime.local().toISO()
		);
		let dateEndAppointment = this.SUtility.getDateWithTimezoneType(
			this.event,
			this.eventUser.updatedSettings.timezoneType,
			appointment.startDate
		).plus({
			minutes: appointment.duration
		});
		return (
			appointment.status === this.AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED && dateEndAppointment < dateNow
		);
	}

	canAcceptAppointment(appointment: IAppointment) {
		const appointmentsAcceptedOfRule = this.appointments.filter(
			(app) =>
				app.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED &&
				app.sourceRuleId === appointment.sourceRuleId
		);
		const rule =
			this.module.options && this.module.options.rules
				? this.module.options.rules.find((rule) => rule.uid === appointment.sourceRuleId)
				: null;
		if (!rule) {
			return false;
		}
		if (appointmentsAcceptedOfRule.length >= rule.maximumAppointmentsByUserForRecipients) {
			return false;
		}

		return true;
	}

	async acceptAppointment(appointment: IAppointment) {
		try {
			if (!this.canAcceptAppointment(appointment)) {
				this.SUtility.presentToast(
					this.STranslate.instant("appointments.alerts.appointment-accepted-limit"),
					2500,
					"bottom",
					"danger"
				);
				return;
			}
			if (appointment.location) {
				this.store.dispatch(Loading({ payload: true }));
				// appointment.location = this.locationSelected;
				const res = await firstValueFrom(
					this.SAppointments.changeAppointmentStatus(
						this.eventUser,
						appointment,
						AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED
					)
				);
				this.store.dispatch(Loading({ payload: false }));
			}
		} catch (error) {
			this.store.dispatch(Loading({ payload: false }));
		}
	}

	async refuseAppointment(appointment: IAppointment) {
		try {
			this.store.dispatch(Loading({ payload: true }));
			await firstValueFrom(
				this.SAppointments.changeAppointmentStatus(
					this.eventUser,
					appointment,
					AppointmentTimeSlotStatus.APPOINTMENT_REJECTED
				)
			);
			this.store.dispatch(Loading({ payload: false }));
		} catch (error) {
			this.store.dispatch(Loading({ payload: false }));
		}
	}

	async cancelAppointment(appointment: IAppointment) {
		try {
			this.store.dispatch(Loading({ payload: true }));
			await firstValueFrom(
				this.SAppointments.changeAppointmentStatus(
					this.eventUser,
					appointment,
					AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED
				)
			);
			this.store.dispatch(Loading({ payload: false }));
		} catch (error) {
			this.store.dispatch(Loading({ payload: false }));
		}
	}

	/**
	 * Open chat
	 */
	async openChat(eventUserProfile: IEventUser) {
		try {
			if (this.event.blocked) {
				this.SUtility.presentToast(
					this.STranslate.instant("alerts.blocked-event-info"),
					2500,
					"bottom",
					"danger"
				);
				return;
			}

			if (!this.eventUser) {
				this.alertLoginRegister(this.STranslate.instant("chat.chat"));
			} else if (!eventUserProfile.firstAccess) {
				this.alertLoginRegister(
					this.STranslate.instant("chat.chat"),
					this.STranslate.instant("alerts.user_chat_not_available"),
					true
				);
			} else {
				this.store.dispatch(Loading({ payload: true }));
				const chats = await firstValueFrom(
					this.store
						.select(
							getChatOfTwo({
								myId: this.eventUser.uid,
								userId: eventUserProfile.uid,
								eventId: this.eventId
							})
						)
						.pipe(take(1))
				);
				const chatsModules = await firstValueFrom(this.store.select(getModulesByType(TypeModule.CHAT)));
				const chatsModule = chatsModules.length > 0 ? chatsModules[0] : null;

				if (chats.length === 0 && chatsModule) {
					// Chat don't exist so create it
					const chat: IChat = {
						uid: this.SFirestore.createId(`events/${this.eventId}/modules/${chatsModule.uid}/chats`),
						allowNotifs: true,
						creationDate: DateTime.local().toISO(),
						createdFrom: "app",
						disabled: false,
						eventId: this.eventId,
						lastAccess: "",
						lastMessageId: "",
						lastMessageUser: null,
						lastUpdated: "",
						members: [this.eventUser.uid, eventUserProfile.uid],
						moduleId: chatsModule.uid,
						options: {
							allowVisio: this.event.settings.allowVisioForTwo
						},
						type: 0,
						visibility: true
					};
					await this.SChats.createChat(this.eventId, chatsModule.uid, chat);
					this.navCtrl.navigateForward(`/event/${this.eventId}/chats/${chatsModule.uid}/chat/${chat.uid}`);
				} else {
					this.navCtrl.navigateForward(
						`/event/${this.eventId}/chats/${chatsModule.uid}/chat/${chats[0].uid}`
					);
				}
				this.store.dispatch(Loading({ payload: false }));
			}
		} catch (error) {
			this.store.dispatch(Loading({ payload: false }));
		}
	}

	openAppointment(appointment: IAppointment) {
		this.navCtrl.navigateForward(
			`event/${appointment.eventId}/appointments/${appointment.moduleId}/details/${appointment.uid}`
		);
	}

	// Generate ics file part

	/**
	 * openDownloadModal
	 * @param session
	 */
	async openDownloadModal(appointment: IAppointment) {
		try {
			const modal = await this.modalController.create({
				component: ConfirmIcsDownloadComponent,
				componentProps: {
					event: this.event,
					module: this.module,
					eventUser: this.eventUser,
					appointment: appointment,
					language: this.event.language
				},
				cssClass: this.isMobile ? "confirm-ics-download-modal-mobile" : "confirm-ics-download-modal"
			});

			modal.present();
		} catch (error) {
			// snackbar error
		}
	}

	/**
	 * Generate ics file
	 * @param session
	 * @returns
	 */
	generateIcsFile(appointment: IAppointment) {
		this.icsLoader = true;
		// Implement a loader
		if (appointment) {
			this.SAppointments.generateAppointmentIcsFile(appointment);
			this.icsLoader = false;
		}
	}

	/**
	 * linkToCalendar
	 * @param timeSlot
	 */
	async linkToCalendar(appointment: IAppointment) {
		this.icsLoader = true;
		let location: string = "";
		if (appointment.location) {
			location = (await firstValueFrom(this.store.select(getSpecificLocation(appointment.location))))?.address;
		}
		const link = this.SAppointments.linkToCalendar(this.event, this.eventUser, location, appointment);
		if (this.isMobile) {
			try {
				await Browser.open({
					url: link
				});
			} catch (error) {
				//
			}
		} else {
			window.open(link, "_blank");
		}
		this.icsLoader = false;
	}

	downloading: boolean = false;
	/**
	 * Download schedule sessions ics
	 */
	async downloadAppointments() {
		if (!this.downloading) {
			this.downloading = true;
			this.store.dispatch(Loading({ payload: true }));
			const filteredAppointments = this.appointments.filter((appointment) => {
				const dateNow = DateTime.local();
				const dateEndAppointment = DateTime.fromISO(appointment.startDate).plus({
					minutes: appointment.duration
				});
				if (
					this.segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_ENDED &&
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED &&
					dateEndAppointment < dateNow
				) {
					return true;
				} else if (
					this.segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED &&
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED &&
					dateEndAppointment > dateNow
				) {
					return true;
				} else if (
					this.segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_PENDING &&
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING &&
					dateEndAppointment > dateNow
				) {
					return true;
				} else if (
					this.segmentSelected === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED &&
					appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED
				) {
					return true;
				}
			});
			try {
				await this.SAppointments.generateAppointmentsIcsFile(filteredAppointments);
				this.downloading = false;
				this.store.dispatch(Loading({ payload: false }));
			} catch (error) {
				this.downloading = false;
				this.store.dispatch(Loading({ payload: false }));
			}
		}
	}

	/**
	 * Download a file
	 * @param filename
	 * @param text
	 */
	async downloadFile(filename: string, text) {
		try {
			if (!this.isMobile) {
				const element = document.createElement("a");
				element.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURIComponent(text));
				element.setAttribute("download", filename);
				element.setAttribute("target", "_blank");
				element.style.display = "none";
				element.click();
			} else {
				if (this.platform.is("ios")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: filename,
						data: encodeURIComponent(text),
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				} else if (this.platform.is("android")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: `download/${filename}`,
						data: encodeURIComponent(text),
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				}
			}
			this.appointmentDownloadButtonLoader = false;
		} catch (error) {
			this.appointmentDownloadButtonLoader = false;
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.download_error"),
				2000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 *
	 * Alerts part
	 *
	 */

	/**
	 * alertLoginRegister
	 * @param alertTitle
	 * @param message
	 */
	async alertLoginRegister(alertTitle: string, message?: string, isLoggedIn?: boolean) {
		const alert = await this.alertCtrl.create({
			header: alertTitle,
			message: message ? message : this.STranslate.instant("texts.need_login_to_use"),
			buttons: !isLoggedIn
				? [
						{
							text: this.STranslate.instant("buttons.authenticate"),
							handler: async () => this.openLogin()
						},
						{
							text: this.STranslate.instant("buttons.cancel"),
							role: "cancel",
							handler: () => {
								//
							}
						}
				  ]
				: [
						{
							text: this.STranslate.instant("buttons.ok"),
							role: "cancel",
							handler: () => {
								//
							}
						}
				  ],
			backdropDismiss: true,
			cssClass: "center-alert-items"
		});

		alert.present();
	}

	/**
	 * Open login modal
	 * @returns
	 */
	async openLogin() {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.loginModal,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openRegister) {
				this.openRegister();
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open register modal
	 * @returns
	 */
	async openRegister() {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.registerForm,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openLogin) {
				this.openLogin();
			}

			<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0] &&
				((<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0]).style.visibility =
					"hidden");
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 *
	 * Visio part
	 *
	 */

	showVisioWebApp: boolean = false;
	@ViewChild("visioConference") visioConferenceComponent: VisioComponent;
	visioLoading: boolean = false;
	visio: IVisio;

	checkVisioAllowed(appointment: IAppointment) {
		const rule =
			this.module.options && this.module.options.rules
				? this.module.options.rules.find((rule) => rule.uid === appointment.sourceRuleId)
				: null;
		return !rule ||
			(rule && !rule.authorizeVisio) ||
			!this.event.extraFeatures.visio ||
			!this.event.extraFeatures.visioForTwo
			? false
			: true;
	}

	/**
	 * Join visio
	 * @returns
	 */
	async joinVisio(appointment: IAppointment) {
		if (
			appointment.status !== AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED ||
			!this.checkVisioAllowed(appointment)
		) {
			return;
		}
		if (!this.visioLoading) {
			this.visioLoading = true;
			this.store.dispatch(Loading({ payload: true }));
			try {
				if (!appointment.urlVisio) {
					this.visio = await firstValueFrom(
						this.SAppointments.createVisioForAppointment(this.eventId, this.moduleId, appointment)
					);
				} else {
					// Get visio if url exist
					this.visio = await firstValueFrom(
						this.SVisios.getChatVisio(this.eventId, this.moduleId, appointment.uid).pipe(take(1))
					);
				}
				this.showVisioWebApp = true;
				let visioOpen = false;
				this.visioLoading = false;
				this.store.dispatch(Loading({ payload: false }));
				if (this.platform.is("ios") || this.platform.is("android")) {
					const intervalRef = setInterval(() => {
						if (this.visioConferenceComponent) {
							this.visioConferenceComponent.openVisio();
							visioOpen = true;
						}
						if (visioOpen) {
							this.showVisioWebApp = false;
							clearInterval(intervalRef);
							this.cd.markForCheck();
						}
					}, 50);
				}

				this.SAnalytics.visioAccess(this.eventId, this.eventUser, this.visio, TypeModule.APPOINTMENTS);

				this.cd.markForCheck();
			} catch (error) {
				this.visioLoading = false;
				this.showVisioWebApp = false;
				this.store.dispatch(Loading({ payload: false }));
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				this.cd.markForCheck();
			}
		}
	}

	onCloseVisio() {
		this.visioLoading = false;
		this.showVisioWebApp = false;
	}
}
