/* eslint-disable no-empty */
/* eslint-disable max-len */
import { BreakpointObserver } from "@angular/cdk/layout";
import { StepperOrientation } from "@angular/cdk/stepper";
import { DOCUMENT } from "@angular/common";
import { Component, ElementRef, Inject, OnDestroy, OnInit, signal, ViewChild, WritableSignal } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalController, NavController, PickerController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { where } from "firebase/firestore";
import * as _ from "lodash";
import { DateTime } from "luxon";
import { ReCaptchaV3Service, RecaptchaComponent } from "ng-recaptcha";
import {
	BehaviorSubject,
	Observable,
	Subscription,
	combineLatest,
	firstValueFrom,
	interval,
	lastValueFrom,
	map,
	skipWhile,
	take
} from "rxjs";
import { InitAuth, InitClaims, InitEvents, InitUser } from "src/app/shared/actions/utility.actions";
import { TriggerType } from "src/app/shared/enums/type-automation";
import { TypeCustomFields, TypeCustomFieldsDataCorrespondence } from "src/app/shared/enums/type-custom-fields";
import { TypeLogin } from "src/app/shared/enums/type-login";
import { TypeModule } from "src/app/shared/enums/type-module";
import { TypeCaptchaMethod } from "src/app/shared/enums/type-register";
import { FormItemType } from "src/app/shared/enums/type-register-forms";
import { TypeUser } from "src/app/shared/enums/type-user";
import {
	IAutomation,
	IContainer,
	ICustomField,
	IEvent,
	IEventUser,
	IFullCustomField,
	IModule,
	ISchedule,
	ITrigger,
	IUser
} from "src/app/shared/interfaces";
import { ICustomFieldData, IField, IMultipleTextField } from "src/app/shared/interfaces/custom-fields.interfaces";
import { IEventUserUpdatedSettings } from "src/app/shared/interfaces/event-users.interfaces";
import { IRegisterFields } from "src/app/shared/interfaces/register-fields.interfaces";
import { IRegisterForm } from "src/app/shared/interfaces/register-form.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getAutomationsOfEvent } from "src/app/shared/selectors/automations.selectors";
import { getContainersState } from "src/app/shared/selectors/containers.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getGroups,
	getModulesCustomsFieldsOfModule
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByType, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { getRegisterForms } from "src/app/shared/selectors/register-form.selectors";
import { selectRouteNestedParams } from "src/app/shared/selectors/router.selectors";
// import { getAllSessions } from "src/app/shared/selectors/schedules.selectors";
import { getInitAuth, getInitEvents, getInitUser } from "src/app/shared/selectors/utility.selectors";
import {
	AnalyticsService,
	AuthService,
	CustomFieldsService,
	EventUsersService,
	FirestoreService,
	SchedulesService,
	StorageService,
	UtilityService
} from "src/app/shared/services";
import { AutomationsService } from "src/app/shared/services/automations.service";
import { environment } from "src/environments/environment";
import { FormFieldsComponent } from "./components/form-fields/form-fields.component";
import { User } from "@angular/fire/auth";
import { v4 as uuidv4 } from "uuid";
//
@Component({
	selector: "app-register-form",
	templateUrl: "./register-form.component.html",
	styleUrls: ["./register-form.component.scss"]
})
export class RegisterFormComponent implements OnInit, OnDestroy {
	@ViewChild(FormFieldsComponent)
	formFieldsComponent: FormFieldsComponent;

	@ViewChild("recaptcha") recaptchaRef: RecaptchaComponent;
	@ViewChild("main") mainDiv: ElementRef;

	stepperOrientation: Observable<StepperOrientation>;

	currentLanguage: WritableSignal<string> = signal(this.STranslate.currentLang);
	languages: string[] = [];
	language: string = this.currentLanguage().slice(0, 2).toLowerCase();

	moduleId: string;
	eventUserProfileId: string;

	recaptchaV3Service?: ReCaptchaV3Service;

	// Tiny API Key

	tinyInitDesktop: any = environment.platform.tinyMce.init;
	tinyInitMobile: any = environment.platform.tinyMce.initMobile;

	subscriptions: Subscription[] = [];
	interval: Subscription;
	closeModalSub: Subscription;
	modalState$: BehaviorSubject<{
		shouldClose: boolean;
		force?: boolean;
		data?: { reOpenModal: { [modalId: string]: boolean } };
		modalId: string;
	}> = new BehaviorSubject({
		shouldClose: false,
		modalId: null
	});

	loader: boolean = true;
	formLoader: boolean = false;
	activeLoader: boolean = false;
	captchaLoader: boolean = true;

	user: IUser;
	eventId: string;
	event: IEvent;
	container: IContainer;
	codeNumber: string = "";
	eventUser: IEventUser;
	totalModuleEventUsers: number;
	count: number = 180;
	timeRemaining: string;

	createPasswordView: boolean = false;
	passwordView: boolean = false;
	codeView: boolean = false;

	isMobile: boolean = false;

	showPwd: boolean = false;
	showConfirmPwd: boolean = false;
	showCodeView: boolean = false;
	showFillFormView: boolean = false;

	showPasswordView: boolean = false;
	showCreatePasswordView: boolean = false;

	formMessage: {
		errorMsg: string;
		successMsg: string;
		warningMsg: string;
	} = { errorMsg: "", successMsg: "", warningMsg: "" };

	addToEventMode: boolean = false;
	isModal: boolean = false;

	registerForm: IRegisterForm;
	form: FormGroup;

	module: IModule;
	customFields: IFullCustomField[] = [];
	registerFields: IRegisterFields[];
	selectCusToUpdate: ICustomField[] = [];
	customFieldMediaToUpload: IFullCustomField[] = [];

	// MULTIPLE TEXT PROPERTIES
	multipleTextValues: IMultipleTextField[] = [];
	multipleTextInputError: {
		controlId: string;
		msg: string;
	}[] = [];

	// FILES/ IMAGES PROPERTIES
	readonly MAX_FILE_SIZE = 5242880; // 5Mo
	isFileCustomEmpty: boolean;
	invalidSizeImg: boolean;
	isValidUrl: boolean;
	actualStep: number = 0;
	steps: [];
	groupIds: string[];
	recaptchaStatus: boolean = false;
	typeCaptchaMethod = TypeCaptchaMethod;
	recaptchaResponse: string;
	recaptchaKey: string;
	device: string;
	mode: string;
	manualOpen: boolean = false;
	fillFormView: boolean;
	computedCustomFields: IFullCustomField[] = [];
	previousDividerIndexArray: number[] = [];
	checkinView: boolean;

	sessions: ISchedule[] = [];
	automations: IAutomation[] = [];
	attendeeModuleIds: string[];
	lockStep: boolean = false;
	adminEventUser: IEventUser;
	totalAccompanyingUsers: number = 0;
	totalEventUsers: number;
	formDatas: any;
	scheduleModules: IModule[];
	newRegisteredUser: IEventUser;
	loggedInUser: User;
	authDataRes: any;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private platform: Platform,
		private pickerCtrl: PickerController,
		private SFirestore: FirestoreService,
		private SAuth: AuthService,
		private SEventUsers: EventUsersService,
		public STranslate: TranslateService,
		private store: Store,
		private fb: FormBuilder,
		private snackbar: MatSnackBar,
		private modalCtrl: ModalController,
		public SCustomFields: CustomFieldsService,
		public SSchedules: SchedulesService,
		private SAnalytics: AnalyticsService,
		private storage: StorageService,
		private breakpointObserver: BreakpointObserver,
		private SAutomations: AutomationsService,
		private SUtility: UtilityService,
		private navCtrl: NavController
	) {
		this.stepperOrientation = this.breakpointObserver
			.observe("(min-width: 800px)")
			.pipe(map(({ matches }) => (matches ? "horizontal" : "vertical")));
	}

	ngOnInit() {
		this.subscriptions.concat(this.closeModalSub).forEach((sub) => sub?.unsubscribe());
		this.loader = true;
		this.formLoader = true;

		if (!this.mode) {
			this.SUtility.hideMenuAndHeader();
			this.isModal = false;
			this.mode = "register-modal";

			this.subscriptions.push(
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params?.eventId;
						this.moduleId = params?.moduleId;
					})
			);
		} else {
			this.isModal = true;
			// if (this.mode === "register-modal") {
			// 	this.isModal = true;
			// }
		}
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage.set(lang.lang);
				this.language = lang.lang.slice(0, 2).toLowerCase();
			})
		);

		this.form = this.fb.group({
			password: ["", [Validators.required, Validators.minLength(6)]],
			confirmPassword: ["", [Validators.required, Validators.minLength(6)]],
			codeNumber: [""],
			termCheck: [false]
		});

		if ((this.platform.is("mobile") && window.innerWidth < 768) || window.innerWidth < 768) {
			this.isMobile = true;
		}
		this.device = this.platform.is("android") ? "android" : this.platform.is("ios") ? "ios" : "desktop";

		this.getContainer();
		this.getEvent();
		this.getModule();
		this.getRegisterForm();
		this.getAutomations();
		this.getSessions();
		this.getScheduleModules();
		this.getGroupIds();
		this.getAttendeeModuleIds();
		this.closeModalWatcher();
		if (
			this.mode === "fill-form-modal" ||
			this.mode === "fill-profil-modal" ||
			this.mode === "accompanying-form-modal" ||
			this.mode === "checkin"
		) {
			this.getCurrentEventUser();
		}
	}

	ngOnDestroy() {
		this.SUtility.showMenuAndHeader();
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		if (this.interval && !this.interval.closed) {
			this.interval.unsubscribe();
		}
	}

	getContainer() {
		this.subscriptions.push(
			this.store.select(getContainersState).subscribe((containersState) => {
				this.container =
					!environment.platform.containerId ||
					environment.platform.containerId === "defaultContainer" ||
					!containersState.currentContainer
						? containersState.defaultContainer
						: containersState.currentContainer;
			})
		);
	}

	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe(async (event) => {
				this.event = event;
				this.event && this.setRecaptchaKey();
				this.loader = false;

				if (this.event) {
					this.languages = [];

					try {
						this.totalEventUsers = await firstValueFrom(
							this.SFirestore.getCountOfQueryObs("event-users", "group", [
								where("eventId", "==", this.event.uid)
							])
						);

						for (const key of Object.keys(this.event.languagesSettings)) {
							if (this.event.languagesSettings[key]) {
								this.languages.push(key);
							}
						}
					} catch (error) {
						console.error("🚀 ~ RegisterFormComponent ~ this.store.select ~ error:", error);
					}
				}
			})
		);
	}

	/**
	 * getCurrentEventUser
	 */
	getCurrentEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe(async (eventUser) => {
				this.adminEventUser = eventUser;

				try {
					this.totalAccompanyingUsers = await firstValueFrom(
						this.SFirestore.getCountOfQueryObs(
							`events/${this.event.uid}/modules/${this.adminEventUser.moduleId}/event-users`,
							"default",
							[where("isAccompanying", "==", true), where("registeredBy", "==", this.adminEventUser.uid)]
						)
					);
				} catch (error) {
					console.error("RegisterFormComponent => error:", error);
				}
			})
		);
	}

	/**
	 * getRegisterForm
	 */
	getRegisterForm() {
		this.subscriptions.push(
			this.store.select(getRegisterForms()).subscribe((registerForms) => {
				this.registerForm = registerForms.find(
					(registerForm) => registerForm.eventId === this.eventId && registerForm.moduleId === this.moduleId
				);

				if (!this.registerForm) {
					// try to get the default register form
					this.registerForm = registerForms.find(
						(registerForm) =>
							registerForm.eventId === this.eventId &&
							registerForm.moduleId === this.event.settings.defaultAttendeesModule
					);
				}

				// Injecting dynamicly the ReCaptchaV3Service
				// if (this.registerForm && this.registerForm.formSettings.enableCaptcha) {
				// 	this.recaptchaV3Service = this.injector.get<ReCaptchaV3Service>(ReCaptchaV3Service);

				// 	<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0] &&
				// 		((<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0]).style.visibility =
				// 			"visible");

				// 	this.captchaLoader = false;
				// }

				this.formLoader = false;
				this.loader = false;
			})
		);
	}

	getScheduleModules() {
		this.subscriptions.push(
			this.store.select(getModulesByType(TypeModule.SCHEDULE)).subscribe((modules) => {
				this.scheduleModules = modules;
			})
		);
	}

	getSessions() {
		this.sessions = this.SSchedules.sessionsOfAllModules();
		// this.subscriptions.push(
		// 	this.store.select(getAllSessions).subscribe((sessions) => {
		// 		if (!_.isEqual(this.sessions, sessions)) {
		// 			this.sessions = sessions;
		// 		}
		// 	})
		// );
	}

	getModule() {
		this.subscriptions.push(
			this.store
				.select(getSpecificModule(this.moduleId))
				.pipe(skipWhile(() => !this.event))
				.subscribe((module) => {
					if (!_.isEqual(this.module, module)) {
						this.module = module;

						// get all eventUser of modules number
						this.SFirestore.getCountOfQueryObs(
							`events/${this.eventId}/modules/${this.moduleId}/event-users`,
							"default",
							[]
						)
							.pipe(take(1))
							.subscribe({
								next: (total) => {
									this.totalModuleEventUsers = total;
								},
								error: (error) => {
									//
								}
							});
						this.getCustomFields();
					}
				})
		);
	}

	getAutomations() {
		this.subscriptions.push(
			this.store.select(getAutomationsOfEvent).subscribe((automations) => {
				if (!_.isEqual(this.automations, automations)) {
					this.automations = automations;
				}
			})
		);
	}

	/**
	 * setRecaptchaKey
	 */
	setRecaptchaKey() {
		this.recaptchaKey = environment.platform.recaptchaSettings.hard.siteKey;
	}

	/**
	 * Get event groups
	 */
	getGroupIds() {
		this.subscriptions.push(
			this.store.select(getGroups).subscribe((groups) => {
				this.groupIds = groups.map((group) => group.uid);
			})
		);
	}

	/**
	 * getAttendeeModuleIds
	 */
	getAttendeeModuleIds() {
		this.subscriptions.push(
			this.store.select(getModulesByType(TypeModule.ATTENDEE)).subscribe((modules) => {
				this.attendeeModuleIds = modules.map((module) => module.uid);
			})
		);
	}

	/**
	 * getCustomFields
	 */
	getCustomFields() {
		this.computedCustomFields = [];

		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(getModulesCustomsFieldsOfModule(this.module.uid))
			])
				// .pipe(skipWhile(() => !this.eventUserProfile))
				.subscribe((results) => {
					results[0];
					results[1];

					if (results[1].length > 0) {
						results[1].forEach((customField) => {
							const baseCustomFieldCorresponding = results[0].find(
								(custField) => custField.uid === customField.uid
							);

							if (baseCustomFieldCorresponding) {
								this.computedCustomFields.push({
									baseSettings: baseCustomFieldCorresponding,
									moduleSettings: customField,
									fieldDatas: {
										uid: "",
										field: {
											date: {
												day: 0,
												month: 0,
												year: 0,
												value: "",
												fullDateISO: "",
												zone: ""
											},
											file: {
												lastModifiedDate: "",
												name: "",
												type: "",
												url: "",
												size: 0
											},
											image: {
												format: "",
												lastModifiedDate: "",
												name: "",
												url: "",
												size: 0,
												isExternalUrl: false
											},
											module: {
												items: [],
												itemsIdentifier: [],
												moduleType: -1
											},
											multiLanguageText: {
												ArAR: "",
												DeDE: "",
												EnUS: "",
												EsES: "",
												FrFR: "",
												PtBR: ""
											},
											multiLanguageSelectArray: [],
											multiLanguageTextArray: {
												ArAR: [],
												DeDE: [],
												EnUS: [],
												EsES: [],
												FrFR: [],
												PtBR: []
											},
											numeric: 0,
											phoneNumber: {
												countryCode: "",
												dialCode: "",
												e164Number: "",
												internationalNumber: "",
												nationalNumber: "",
												number: ""
											},
											text: "",
											address: {
												fullAddress: "",
												street: "",
												number: "",
												city: "",
												country: "",
												postalCode: "",
												state: "",
												region: "",
												url: ""
											},
											checkbox: false,
											country: {
												name: "",
												dialCode: "",
												code: "",
												flag: ""
											}
										}
									}
								});
								this.computedCustomFields;
							}
						});
					}
				})
		);
	}

	/**
	 * First step verif email and send code if necessary
	 */
	async firstStepVerifEmail() {
		this.activeLoader = true;
		this.lockStep = false;
		this.formMessage.errorMsg = "";
		this.formMessage.warningMsg = "";
		this.formMessage.successMsg = "";
		this.addToEventMode = false;

		const datas = this.formFieldsComponent.getFormsValues();

		const baseDatas = this.SCustomFields.getRegisterFormBaseFields(
			this.registerForm.formContent.sections,
			datas,
			this.currentLanguage()
		);

		try {
			const email = baseDatas["email"];
			if (email) {
				const results: any[] = await firstValueFrom(
					combineLatest([
						this.SAuth.checkAuthAccountExist(email, "", "email"),
						this.SAuth.getUserByEmail(email.toLowerCase()),
						this.SEventUsers.getEventUserByEmail(email.toLowerCase(), this.eventId, "complete")
					])
				);

				const authAccountResult = results[0];
				this.authDataRes = authAccountResult;
				this.user = results[1];
				this.eventUser = results[2];
				if (
					this.eventUser &&
					authAccountResult &&
					authAccountResult.code === 200 &&
					this.user &&
					authAccountResult.result &&
					authAccountResult.result.uid === this.user.uid &&
					authAccountResult.result &&
					authAccountResult.result.email === this.user.email
				) {
					// If user already registered for this event ask for connection
					if (this.event.settings.visibility) {
						if (this.container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM) {
							this.createCodeNumber();
						}

						this.formMessage.errorMsg =
							this.eventUser.eventId === this.eventId
								? this.STranslate.instant("login.event_user_already_exist_in_event")
								: this.STranslate.instant("login.account_already_exist_with_email_public_event");

						this.lockStep = true;
					} else {
						this.formMessage.errorMsg = this.STranslate.instant(
							"login.account_already_exist_with_email_private_event"
						);

						this.lockStep = true;
					}
				} else if (
					this.user &&
					!this.eventUser &&
					authAccountResult &&
					authAccountResult.code === 200 &&
					authAccountResult.result &&
					authAccountResult.result.uid === this.user.uid &&
					authAccountResult.result &&
					authAccountResult.result.email === this.user.email
				) {
					// Change view to email view and password view
					if (this.event.settings.visibility) {
						if (this.container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM) {
							this.createCodeNumber();
						}
						this.addToEventMode = true;

						this.formMessage.warningMsg = this.STranslate.instant(
							"login.account_already_exist_with_email_public_event"
						);
					} else {
						this.formMessage.errorMsg = this.STranslate.instant(
							"login.account_already_exist_with_email_private_event"
						);
						this.lockStep = true;
					}
				} else if (this.user && !this.eventUser && (!authAccountResult || authAccountResult.code === 204)) {
					// Change view to first access view or create pass view
					if (this.container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM) {
						this.createCodeNumber();
					}
				} else if (this.user && this.eventUser && (!authAccountResult || authAccountResult.code === 204)) {
					// Change view to first access view or create pass view
					if (this.container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM) {
						this.createCodeNumber();
					}

					if (this.event.settings.visibility) {
						this.formMessage.errorMsg = this.STranslate.instant("login.event_user_already_exist_in_event");
						this.lockStep = true;
					}
				} else {
					// User not found
					// Change view to first access view or create pass view

					if (this.container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM) {
						this.createCodeNumber();
					}
				}
			}

			this.activeLoader = false;
		} catch (error) {
			console.error("🚀 ~ firstStepVerifEmail ~ error:", error);
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured_on_verif_email"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
			this.activeLoader = false;
		}
	}

	/**
	 * onSubmitForm
	 */
	onSubmitForm(datas: any) {
		this.formDatas = datas?.formDatas;

		if (this.isModal) {
			if (this.mode === "register-modal") {
				this.addToEventMode ? this.login() : this.createAccount();
			} else if (this.mode === "fill-form-modal") {
				this.updateEventUser();
			} else if (this.mode === "checkin" || this.mode === "accompanying-form-modal") {
				this.clientAdminCreateUserAccount();
			}
		} else {
			// Component mode
			this.addToEventMode ? this.login() : this.createAccount();
		}
	}

	/**
	 * Create code number
	 */
	async createCodeNumber() {
		try {
			const result = await firstValueFrom(this.SAuth.sendCodeNumber(this.user.uid));

			if (result.code === 200 && result.user && result.user.codeNumber) {
				// If sending code ok
				this.codeNumber = result.user.codeNumber;

				this.count = 20;
				this.interval = interval(1000).subscribe(() => {
					const date = new Date(null);
					date.setSeconds(this.count);
					this.timeRemaining = date.toISOString().substring(14, 19);
					this.count--;

					if (this.count === 0) {
						if (this.interval && !this.interval.closed) {
							this.interval.unsubscribe();
						}
						this.snackbar.open(this.STranslate.instant("login.resend_code_number"), "", {
							duration: 3000,
							panelClass: "warning-snackbar"
						});
					}
				});
				this.activeLoader = false;
			} else {
				this.snackbar.open(this.STranslate.instant("login.error_send_email_code"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				this.activeLoader = false;
			}
		} catch (error) {
			console.error("🚀 ~ createCodeNumber ~ error:", error);
			this.snackbar.open(this.STranslate.instant("login.error_send_email_code"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
			this.activeLoader = false;
		}
	}

	/**
	 * createAccount
	 */
	async createAccount() {
		this.activeLoader = true;

		const datas = this.formDatas;

		const baseDatas = this.SCustomFields.getRegisterFormBaseFields(
			this.registerForm.formContent.sections,
			datas,
			this.currentLanguage()
		);

		const credentials = baseDatas?.["credentials"];

		try {
			// Check for code confirm
			if (this.codeView) {
				if (this.count > 0) {
					if (credentials.codeNumber) {
						const checkCode = await firstValueFrom(
							this.SAuth.verifyCodeNumber(credentials.codeNumber?.toString(), this.user.uid)
						);
						if (checkCode) {
							if (this.interval && !this.interval.closed) {
								this.interval.unsubscribe();
							}
						} else {
							this.snackbar.open(this.STranslate.instant("login.invalid_code"), "", {
								duration: 3000,
								panelClass: "error-snackbar"
							});
							this.activeLoader = false;
							return;
						}
					} else {
						this.snackbar.open(this.STranslate.instant("login.invalid_code"), "", {
							duration: 3000,
							panelClass: "error-snackbar"
						});
						this.activeLoader = false;
						return;
					}
				} else {
					this.activeLoader = false;
					this.snackbar.open(this.STranslate.instant("login.error_send_email_code"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					return;
				}
			}

			if (credentials.password && credentials.password?.length >= 6) {
				const eventUserName = baseDatas.name;
				const eventUserEmail = baseDatas.email;
				const matchIds: number = (
					await firstValueFrom(
						this.SEventUsers.getEventUserIdentifierByIdentifier(eventUserName.toUpperCase(), this.eventId)
					)
				)?.length;
				/* ---- Pre-treatment for custom fields before sending them to firestore ---- */

				// Retreiving datas from the 'customFieldsGeneric' component

				const customFieldsDatas = this.registerForm.formContent.sections
					.map((section) => {
						return this.SCustomFields.getRegisterFormDatas(
							this.event,
							datas,
							section.uid,
							section.items,
							this.formFieldsComponent,
							this.selectCusToUpdate,
							this.customFieldMediaToUpload,
							this.currentLanguage()
						).reduce((a, b) => a.concat(b), []);
					})
					.reduce((a, b) => a.concat(b), []);

				const groups = this.getCustomFieldSelectGroups();

				/* ---- END OF "Pre-treatment for custom fields before sending them to firestore" ---- */

				// Build fieldVisibility
				const fieldsVisibility = this.buildFieldsVisibility();

				const eventUser: IEventUser = {
					checkins: {
						checkinsChecked: [],
						checkinsNotChecked: []
					},
					createdInApp: true,
					creationDate: DateTime.local().toISO(),
					createMode: "Registration",
					customFields: customFieldsDatas,
					description: {
						ArAR: "",
						DeDE: "",
						EsES: "",
						EnUS: "",
						FrFR: "",
						PtBR: ""
					},
					documents: [],
					editedProfile: false,
					email: eventUserEmail.toLowerCase(),
					emailRecovery: "",
					eventId: this.event.uid,
					favorites: [],
					firstAccess: false,
					firstAccessDate: "",
					groups: groups ? groups : [],
					groupsDatas: [],
					hasFilledRegisterForm: true,
					identifier:
						matchIds > 0 ? `${eventUserName.toUpperCase()} - ${matchIds}` : eventUserName.toUpperCase(),
					isClientAdmin: false,
					moduleId: this.moduleId ? this.moduleId : this.event.settings.defaultAttendeesModule,
					name: eventUserName,
					options: {
						chatsNotifs: {},
						feedNewsNotifs: {},
						notifOneSignalConfig: {}
					},
					photoUrl: "",
					points: 0,
					queryName: eventUserName.toUpperCase(),
					searchStringDatas: [],
					sessions: [],
					socialMedias: {
						website: "",
						facebook: "",
						instagram: "",
						linkedin: "",
						twitter: ""
					},
					type: TypeUser.ATTENDEE,
					uid: this.user ? this.user.uid : "",
					visibility: true
				};

				const newEventUserUpdatedSettings: IEventUserUpdatedSettings = {
					accessModule: {},
					accessModulesArray: [],
					appointmentsSchedules: [],
					connected: true,
					eventId: this.eventId,
					fieldsVisibility: fieldsVisibility,
					language: this.currentLanguage(),
					lastAccessDate: "",
					lastSeenNotifTime: 0,
					moduleId: this.moduleId ? this.moduleId : this.event.settings.defaultAttendeesModule,
					readNotifications: "",
					timezoneType: "local",
					userId: eventUser && eventUser.uid ? eventUser.uid : "",
					typeUser: eventUser.type
				};

				// For creating auth account + event user case
				if (credentials.password === credentials.confirmPassword) {
					if (credentials.termCheck) {
						this.eventUser = eventUser;
						if (!this.user) {
							const user: IUser = {
								company: "",
								connectedPlatformToken: "",
								creationDate: DateTime.local().toISO(),
								creditsHistory: [],
								creditsQuantity: 0,
								description: "",
								editedProfile: false,
								email: eventUserEmail.toLowerCase(),
								emailRecovery: "",
								eventsFavoris: [],
								firstAccess: false,
								integrations: {
									apiKey: uuidv4()
								},
								language: this.currentLanguage(),
								lastConnectedDate: "",
								name: eventUserName,
								orders: {
									clientsOrder: "asc",
									clientEventsOrder: "asc",
									employeesOrder: "asc",
									godsOrder: "asc",
									internalEventsOrder: "asc",
									superGodsOrders: "asc"
								},
								photoUrl: "",
								queryName: eventUserName.toUpperCase(),
								job: "",
								type: TypeUser.ATTENDEE,
								uid: this.SFirestore.createId(`users`)
							};

							// Create auth account + user data
							await this.SAuth.createUserWithAuth(user, credentials.password);
							eventUser.uid = user.uid;
							// Create event user data
							await this.SEventUsers.createEventUser(
								this.event.uid,
								this.moduleId ? this.moduleId : this.event.settings.defaultAttendeesModule,
								eventUser,
								newEventUserUpdatedSettings
							);
						} else {
							eventUser.uid = this.user.uid;

							if (!this.authDataRes || this.authDataRes.code === 204) {
								await firstValueFrom(this.SAuth.createOnlyAuthAccount(this.user, credentials.password));
							}

							await this.SEventUsers.createEventUser(
								this.event.uid,
								this.moduleId ? this.moduleId : this.event.settings.defaultAttendeesModule,
								eventUser,
								newEventUserUpdatedSettings
							);
						}

						// Options limit feature
						const selectFieldWithOptLimit = _.uniq(
							this.computedCustomFields.filter(
								(cus) =>
									cus.baseSettings.type === TypeCustomFields.SELECT &&
									cus.baseSettings.optionsLimit &&
									this.registerForm.formContent.sections.find(
										(sec) =>
											sec.items.find((item) => item.uid === cus.baseSettings.uid) &&
											sec.items.find((item) => item.uid === cus.baseSettings.uid)?.uid ===
												cus.baseSettings.uid
									)
							)
						);

						if (selectFieldWithOptLimit.length > 0) {
							for (const computedCus of selectFieldWithOptLimit) {
								try {
									await this.formFieldsComponent.updateModuleCustomFieldCount(
										computedCus.baseSettings
									);
									await this.formFieldsComponent.resetTempOptionCount(computedCus.baseSettings);
								} catch (error) {}
							}
						}

						// Register user for sessions if necessary
						try {
							await this.registerToSession(eventUser);
						} catch (error) {
							console.error("🚀 ~ createAccount ~ registerToSession ~ error:", error);
						}
						this.login();
					} else {
						this.activeLoader = false;
						this.snackbar.open(this.STranslate.instant("login.term_check_required"), "", {
							duration: 3000,
							panelClass: "error-snackbar"
						});
						return;
					}
				} else {
					this.activeLoader = false;
					this.snackbar.open(this.STranslate.instant("login.password_not_match"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					return;
				}
			} else {
				this.activeLoader = false;
				this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}
		} catch (error) {
			console.error("🚀 ~ createAccount ~ error:", error);
			this.activeLoader = false;
			this.snackbar.open(this.STranslate.instant("snackbar.cannot_create_account"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * clientAdminCreateUserAccount
	 */
	async clientAdminCreateUserAccount() {
		const sections = this.registerForm.formContent.accompanyingSections;
		const datas = this.formDatas;
		const baseDatas = this.SCustomFields.getRegisterFormBaseFields(sections, datas, this.currentLanguage());

		try {
			this.activeLoader = true;

			const eventUserName = baseDatas["name"];
			const eventUserEmail = baseDatas["email"];
			const matchIds: number = (
				await firstValueFrom(
					this.SEventUsers.getEventUserIdentifierByIdentifier(eventUserName.toUpperCase(), this.eventId)
				)
			).length;

			if (!this.adminEventUser) {
				throw new Error("No admin event user found");
			}

			const existedUser = await firstValueFrom(this.SAuth.getUserByEmail(eventUserEmail.toLowerCase()));
			const existedEventUser = await firstValueFrom(
				this.SEventUsers.getEventUserByEmail(eventUserEmail.toLowerCase(), this.eventId, "complete")
			);

			/* ---- Pre-treatment for custom fields before sending them to firestore ---- */

			// Retreiving datas from the 'customFieldsGeneric' component

			const customFieldsDatas = sections
				.map((section) => {
					return this.SCustomFields.getRegisterFormDatas(
						this.event,
						datas,
						section.uid,
						section.items,
						this.formFieldsComponent,
						this.selectCusToUpdate,
						this.customFieldMediaToUpload,
						this.currentLanguage()
					).reduce((a, b) => a.concat(b), []);
				})
				.reduce((a, b) => a.concat(b), []);

			const groups = this.getCustomFieldSelectGroups();

			const fieldsVisibility = this.buildFieldsVisibility();

			/* ---- END OF "Pre-treatment for custom fields before sending them to firestore" ---- */

			const eventUser: IEventUser = {
				checkins: {
					checkinsChecked: [],
					checkinsNotChecked: []
				},
				createdInApp: true,
				creationDate: DateTime.local().toISO(),
				createMode: "Chekin",
				customFields: customFieldsDatas,
				description: {
					ArAR: "",
					DeDE: "",
					EsES: "",
					EnUS: "",
					FrFR: "",
					PtBR: ""
				},
				documents: [],
				editedProfile: false,
				email: eventUserEmail.toLowerCase(),
				emailRecovery: "",
				eventId: this.event.uid,
				favorites: [],
				firstAccess: false,
				firstAccessDate: "",
				groups: groups ? groups : [],
				groupsDatas: [],
				hasFilledRegisterForm: false,
				identifier: matchIds > 0 ? `${eventUserName.toUpperCase()} - ${matchIds}` : eventUserName.toUpperCase(),
				isClientAdmin: false,
				moduleId: this.moduleId ? this.moduleId : this.event.settings.defaultAttendeesModule,
				name: eventUserName,
				options: {
					chatsNotifs: {},
					feedNewsNotifs: {},
					notifOneSignalConfig: {}
				},
				photoUrl: "",
				points: 0,
				queryName: eventUserName.toUpperCase(),
				searchStringDatas: [],
				sessions: [],
				socialMedias: {
					website: "",
					facebook: "",
					instagram: "",
					linkedin: "",
					twitter: ""
				},
				isAccompanying: this.mode === "accompanying-form-modal",
				registeredBy:
					this.mode === "accompanying-form-modal" || this.mode === "checkin" ? this.adminEventUser.uid : null,
				type: TypeUser.ATTENDEE,
				uid: "",
				visibility: true
			};

			const user: IUser = {
				company: "",
				connectedPlatformToken: "",
				creationDate: DateTime.local().toISO(),
				creditsHistory: [],
				creditsQuantity: 0,
				description: "",
				editedProfile: false,
				email: eventUserEmail.toLowerCase(),
				emailRecovery: "",
				eventsFavoris: [],
				firstAccess: false,
				integrations: {
					apiKey: uuidv4()
				},
				language: this.currentLanguage(),
				lastConnectedDate: "",
				name: eventUserName,
				orders: {
					clientsOrder: "asc",
					clientEventsOrder: "asc",
					employeesOrder: "asc",
					godsOrder: "asc",
					internalEventsOrder: "asc",
					superGodsOrders: "asc"
				},
				photoUrl: "",
				queryName: eventUserName.toUpperCase(),
				job: "",
				type: TypeUser.ATTENDEE,
				uid: this.SFirestore.createId(`users`)
			};

			const newEventUserUpdatedSettings: IEventUserUpdatedSettings = {
				accessModule: {},
				accessModulesArray: [],
				appointmentsSchedules: [],
				connected: true,
				eventId: this.eventId,
				fieldsVisibility: fieldsVisibility,
				language: this.currentLanguage(),
				lastAccessDate: "",
				lastSeenNotifTime: 0,
				moduleId: this.moduleId ? this.moduleId : this.event.settings.defaultAttendeesModule,
				readNotifications: "",
				timezoneType: "local",
				userId: eventUser && eventUser.uid ? eventUser.uid : "",
				typeUser: eventUser.type
			};

			// For creating auth account + event user case
			if (!existedUser && !existedEventUser) {
				// this.eventUser = eventUser;

				const id = !user.uid ? this.SFirestore.createId(`users`) : user.uid;

				user.uid = id;

				// Create auth account + user data
				await this.SAuth.createUserWithoutAuth(user);

				// newUserId && (eventUser.uid = newUserId);

				eventUser.uid = user.uid;

				if (this.mode === "accompanying-form-modal" || this.mode === "checkin") {
					this.newRegisteredUser = eventUser;
				}

				// if (this.mode === "checkin") {
				// Create event user data

				await this.SEventUsers.createEventUser(
					this.event.uid,
					this.moduleId ? this.moduleId : this.event.settings.defaultAttendeesModule,
					eventUser,
					newEventUserUpdatedSettings
				);

				if (this.mode === "accompanying-form-modal" || this.mode === "checkin") {
					this.newRegisteredUser.updatedSettings = newEventUserUpdatedSettings;
				}

				await this.modalCtrl.dismiss(null, null, this.mode ? this.mode : "accompanying-form-modal");
			} else if (existedUser && !existedEventUser) {
				eventUser.uid = existedUser.uid;
				await this.SEventUsers.createEventUser(
					this.event.uid,
					this.moduleId ? this.moduleId : this.event.settings.defaultAttendeesModule,
					eventUser,
					newEventUserUpdatedSettings
				);
				if (this.mode === "accompanying-form-modal" || this.mode === "checkin") {
					this.newRegisteredUser.updatedSettings = newEventUserUpdatedSettings;
				}

				// await this.close(this.mode ? this.mode : "accompanying-form-modal");

				if (this.mode === "acompanying-form-modal") {
					const canRegisterAccompagnyingUsers = await this.canRegisterAccompanyingUsers();
					this.modalState$.next({
						shouldClose: true,
						data: canRegisterAccompagnyingUsers
							? {
									reOpenModal: {
										"accompanying-form-modal": true
									}
							  }
							: null,
						modalId: this.mode ? this.mode : "accompanying-form-modal"
					});
				}
			} else if (!existedUser && existedEventUser) {
				user.uid = existedEventUser.uid;
				await this.SAuth.createUserWithoutAuth(user);
				// await this.close(this.mode ? this.mode : "accompanying-form-modal");
				this.modalState$.next({
					shouldClose: true,
					modalId: this.mode ? this.mode : "accompanying-form-modal"
				});
			} else {
				this.activeLoader = false;
				this.snackbar.open(this.STranslate.instant("login.account_already_exist_with_email"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}

			// Upload profil picture if necessary
			if (this.formFieldsComponent.actualImage) {
				try {
					await this.formFieldsComponent.saveProfilPicture(eventUser);
				} catch (error) {}
			}

			// Upload event user media to firebase storage et set the url
			this.customFieldMediaToUpload = this.customFieldMediaToUpload.filter((media) => media);
			if (eventUser && this.customFieldMediaToUpload.length > 0) {
				try {
					await this.uploadCustomFieldMedia();
					await this.SEventUsers.updateEventUser(this.eventId, this.module.uid, this.newRegisteredUser);
				} catch (error) {}
			}

			// Options limit feature
			const selectFieldWithOptLimit = _.uniq(
				this.computedCustomFields.filter(
					(cus) => cus.baseSettings.type === TypeCustomFields.SELECT && cus.baseSettings.optionsLimit
				)
			);

			if (selectFieldWithOptLimit.length > 0) {
				for (const computedCus of selectFieldWithOptLimit) {
					try {
						await this.formFieldsComponent.updateModuleCustomFieldCount(computedCus.baseSettings);
						await this.formFieldsComponent.resetTempOptionCount(computedCus.baseSettings);
					} catch (error) {}
				}
			}

			// Register user for sessions if necessary (TODO: will be done in the future)
			try {
				await this.registerToSession(eventUser);
			} catch (error) {
				console.error("🚀 ~ createAccount ~ registerToSession ~ error:", error);
			}

			// Show invite accompanying modal
			if (this.registerForm.formSettings.enableAccompanyingUsers && this.mode !== "checkin") {
				const canRegisterAccompagnying = await this.canRegisterAccompanyingUsers();
				if (
					canRegisterAccompagnying &&
					(this.mode === "register-modal"
						? !this.eventUser.isAccompanying
						: !this.adminEventUser.isAccompanying)
				) {
					await this.showInviteModal();
				}
			}
		} catch (error) {
			console.error("🚀 ~ clientAdminCreateUserAccount ~ error:", error);
			this.activeLoader = false;
			this.snackbar.open(this.STranslate.instant("snackbar.cannot_create_account"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Login user
	 */
	login() {
		const datas = this.formDatas;
		const baseDatas = this.SCustomFields.getRegisterFormBaseFields(
			this.registerForm.formContent.sections,
			datas,
			this.currentLanguage()
		);

		const eventUserName = baseDatas?.["name"];
		const eventUserEmail = baseDatas?.["email"];
		const credentials = baseDatas?.["credentials"];

		try {
			this.activeLoader = true;

			if (credentials.password) {
				if (credentials.termCheck) {
					this.store.dispatch(InitAuth({ payload: false }));
					this.store.dispatch(InitClaims({ payload: false }));
					this.store.dispatch(InitEvents({ payload: false }));
					this.store.dispatch(InitUser({ payload: false }));
					this.SAuth.loginUser(eventUserEmail.toLowerCase(), credentials.password).subscribe(
						async (user) => {
							if (user && user.user && user.user.uid) {
								this.user = await firstValueFrom(
									this.SAuth.getUserByEmail(eventUserEmail.toLowerCase())
								);
								this.loggedInUser = user.user;

								if (this.addToEventMode) {
									// If not create password (user account exist)
									// create event user after login for verifying the password
									const matchIds: number = (
										await firstValueFrom(
											this.SEventUsers.getEventUserIdentifierByIdentifier(
												eventUserName.toUpperCase(),
												this.eventId
											)
										)
									).length;

									/* ---- Pre-treatment for custom fields before sending them to firestore ---- */
									// Retreiving datas from the 'customFieldsGeneric' component
									// const customFieldsValues = this.registerFieldsComponent.getFormValues();

									const customFieldsDatas = this.registerForm.formContent.sections
										.map((section) => {
											return this.SCustomFields.getRegisterFormDatas(
												this.event,
												datas,
												section.uid,
												section.items,
												this.formFieldsComponent,
												this.selectCusToUpdate,
												this.customFieldMediaToUpload,
												this.currentLanguage()
											).reduce((a, b) => a.concat(b), []);
										})
										.reduce((a, b) => a.concat(b), []);

									const groups = this.getCustomFieldSelectGroups();

									/* - END OF "Pre-treatment for custom fields before sending them to firestore" - */

									const eventUser: IEventUser = {
										checkins: {
											checkinsChecked: [],
											checkinsNotChecked: []
										},
										createdInApp: true,
										creationDate: DateTime.local().toISO(),
										createMode: "Registration",
										customFields: customFieldsDatas,
										description: {
											ArAR: "",
											DeDE: "",
											EsES: "",
											EnUS: "",
											FrFR: "",
											PtBR: ""
										},
										documents: [],
										editedProfile: false,
										email: eventUserEmail.toLowerCase(),
										emailRecovery: "",
										eventId: this.event.uid,
										favorites: [],
										firstAccess: false,
										firstAccessDate: "",
										groups: groups ? groups : [],
										groupsDatas: [],
										hasFilledRegisterForm: true,
										identifier:
											matchIds > 0
												? `${eventUserName.toUpperCase()} - ${matchIds}`
												: eventUserName.toUpperCase(),
										isClientAdmin: false,
										moduleId: this.moduleId
											? this.moduleId
											: this.event.settings.defaultAttendeesModule,
										name: eventUserName,
										options: {
											chatsNotifs: {},
											feedNewsNotifs: {},
											notifOneSignalConfig: {}
										},
										photoUrl: "",
										points: 0,
										queryName: eventUserName.toUpperCase(),
										searchStringDatas: [],
										sessions: [],
										socialMedias: {
											website: "",
											facebook: "",
											instagram: "",
											linkedin: "",
											twitter: ""
										},
										type: TypeUser.ATTENDEE,
										uid: user.user.uid,
										visibility: true
									};

									const fieldsVisibility = this.buildFieldsVisibility();

									const newEventUserUpdatedSettings: IEventUserUpdatedSettings = {
										accessModule: {},
										accessModulesArray: [],
										appointmentsSchedules: [],
										connected: true,
										eventId: this.eventId,
										fieldsVisibility: fieldsVisibility,
										language: this.currentLanguage(),
										lastAccessDate: DateTime.local().toISO(),
										lastSeenNotifTime: 0,
										moduleId: this.moduleId
											? this.moduleId
											: this.event.settings.defaultAttendeesModule,
										readNotifications: "",
										timezoneType: "local",
										userId: eventUser && eventUser.uid ? eventUser.uid : "",
										typeUser: eventUser.type
									};

									await this.SEventUsers.createEventUser(
										this.event.uid,
										this.moduleId ? this.moduleId : this.event.settings.defaultAttendeesModule,
										eventUser,
										newEventUserUpdatedSettings
									);

									// Options limit feature
									const selectFieldWithOptLimit = _.uniq(
										this.computedCustomFields.filter(
											(cus) =>
												cus.baseSettings.type === TypeCustomFields.SELECT &&
												cus.baseSettings.optionsLimit
										)
									);

									if (selectFieldWithOptLimit.length > 0) {
										for (const computedCus of selectFieldWithOptLimit) {
											try {
												await this.formFieldsComponent.updateModuleCustomFieldCount(
													computedCus.baseSettings
												);
												await this.formFieldsComponent.resetTempOptionCount(
													computedCus.baseSettings
												);
											} catch (error) {}
										}
									}

									this.eventUser = eventUser;

									if (this.user) {
										// Security
										this.store.select(getInitAuth).subscribe((init) => {
											if (!init) {
												this.store.dispatch(InitAuth({ payload: true }));
											}
										});
									}
								} else {
									this.user = await firstValueFrom(
										this.SAuth.getUserByEmail(eventUserEmail.toLowerCase())
									);

									// Update last access date property
									this.SEventUsers.updateLastAccessOfEventForEventUser(this.event.uid, this.user.uid);
								}

								if (this.user) {
									this.user.connectedPlatformToken = this.SAuth.deviceId ? this.SAuth.deviceId : "";
								}
								if (this.user && !this.user.firstAccess) {
									// Update first access if not true
									this.user.firstAccess = true;
								}
								this.SAuth.updateUser(this.user);

								// Upload event user media to firebase storage et set the url
								this.customFieldMediaToUpload = this.customFieldMediaToUpload.filter((media) => media);
								if (this.eventUser && this.customFieldMediaToUpload.length > 0) {
									await this.uploadCustomFieldMedia();
									this.SEventUsers.updateEventUser(this.eventId, this.module.uid, this.eventUser);
								}

								// Upload profil picture
								if (this.eventUser) {
									try {
										const photoUrl = await this.formFieldsComponent.saveProfilPicture(
											this.eventUser
										);
										if (photoUrl) {
											this.eventUser.photoUrl = photoUrl;
											await this.SEventUsers.updatePartOfEventUser(
												this.eventId,
												this.eventUser.moduleId,
												this.eventUser.uid,
												{
													photoUrl: photoUrl
												}
											);
										}
									} catch (error) {
										this.snackbar.open(
											this.STranslate.instant("snackbar.error_uploading_photo"),
											"",
											{
												duration: 3000,
												panelClass: "error-snackbar"
											}
										);
									}
								}

								// Check automations
								const triggers = this.SAutomations.getAutomationTriggersByType(
									this.automations,
									TriggerType.GENERIC_REGISTER_EVENTUSER
								);

								this.checkAndExcuteAutomations(triggers, this.eventUser);

								// Register eventuser to group when the latest register to a session
								await this.groupRegisterWhenRegisterToSession(this.eventUser);

								combineLatest([
									this.store.select(getInitAuth).pipe(skipWhile((init) => init === false)),
									this.store.select(getInitUser).pipe(skipWhile((init) => init === false)),
									this.store.select(getInitEvents).pipe(skipWhile((init) => init === false))
								])
									.pipe(take(1))
									.subscribe(() => {
										setTimeout(async () => {
											try {
												// Confirm alert
												if (
													this.registerForm.formSettings.enableConfirmationMsg &&
													this.mode === "register-modal"
												) {
													this.openConfirmRegisterModal(
														this.registerForm.formSettings?.confirmationSettings
															?.confirmationText?.[this.currentLanguage()]
													);
												}

												// Show invite accompanying modal
												if (
													this.registerForm.formSettings.enableAccompanyingUsers &&
													this.eventUser &&
													this.mode === "register-modal"
												) {
													const canRegisterAccompagnying =
														await this.canRegisterAccompanyingUsers();
													if (canRegisterAccompagnying && !this.eventUser.isAccompanying) {
														await this.showInviteModal();
													}
												}
											} catch (error) {
												console.error("🚀 ~ error:", error);
											}
										}, 1000);

										if (this.isModal) {
											this.modalState$.next({
												shouldClose: true,
												modalId: this.mode
											});
										} else {
											this.SUtility.showMenuAndHeader();
											this.navCtrl.navigateRoot(this.event.homePage);
										}
									});
							} else {
								this.activeLoader = false;
								this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							}
						},
						(error) => {
							this.activeLoader = false;
							if (error.code === "auth/wrong-password") {
								this.snackbar.open(this.STranslate.instant("login.incorrect_pass"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							} else if (error.code == "auth/too-many-requests") {
								this.snackbar.open(this.STranslate.instant("login.many_pass_errors"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							} else if (error.code == "auth/argument-error") {
								this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							} else {
								this.snackbar.open(this.STranslate.instant("snackbar.cannot_login"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							}
						}
					);
				} else {
					this.activeLoader = false;
					this.snackbar.open(this.STranslate.instant("login.term_check_required"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
				}
			} else {
				this.activeLoader = false;
				this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		} catch (error) {
			console.error("🚀 => login => error:", error);

			this.activeLoader = false;
			this.snackbar.open(this.STranslate.instant("snackbar.cannot_login"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Update eventUser
	 * Used for "fill-form-modal" mode
	 */
	async updateEventUser() {
		try {
			// this.loader = true;
			this.activeLoader = true;
			const datas = this.formDatas;
			const baseDatas = this.SCustomFields.getRegisterFormBaseFields(
				this.registerForm.formContent.sections,
				datas,
				this.currentLanguage()
			);

			const name = baseDatas["name"];

			if (this.adminEventUser) {
				const eventUser = this.adminEventUser;
				this.user = await firstValueFrom(this.SAuth.getUserByEmail(eventUser.email.toLowerCase()));

				this.eventUser = eventUser;

				const updatedEventUser = Object.assign({}, eventUser);

				const customFieldsArray = this.registerForm.formContent.sections
					.map((section) => {
						return this.SCustomFields.getRegisterFormDatas(
							this.event,
							datas,
							section.uid,
							section.items,
							this.formFieldsComponent,
							this.selectCusToUpdate,
							this.customFieldMediaToUpload,
							this.currentLanguage()
						).reduce((a, b) => a.concat(b), []);
					})
					.reduce((a, b) => a.concat(b), []);

				const formFieldIds = this.registerForm.formContent.sections
					.map((sect) =>
						sect.items
							.map((item) =>
								!item.hidden && item.inputFieldType !== TypeCustomFields.MODULE ? item.uid : null
							)
							.filter((item) => item !== null)
					)
					.reduce((a, b) => a.concat(b), []);

				const customFieldsNotPresentInForm = this.computedCustomFields
					.map((cus) => {
						{
							return !formFieldIds.includes(cus.moduleSettings.uid)
								? ({
										uid: cus.moduleSettings.uid,
										field: cus.fieldDatas.field[
											TypeCustomFieldsDataCorrespondence[cus.baseSettings.type]
										] as IField
								  } as ICustomFieldData)
								: null;
						}
					})
					.filter((cus) => cus !== null);
				let datasOfCustomFieldsNotPresentInForm: ICustomFieldData[] = [];
				let formFieldsNotPresentInUserDatas = [];
				let formFieldsPresentInUserDatas = [];

				if (updatedEventUser.customFields && updatedEventUser.customFields.length > 0) {
					const datasOfCustomFieldsNotPresentInFormIds = customFieldsNotPresentInForm.map((cus) => cus.uid);
					datasOfCustomFieldsNotPresentInForm = updatedEventUser.customFields.filter((cus) =>
						datasOfCustomFieldsNotPresentInFormIds.includes(cus.uid)
					);

					formFieldsPresentInUserDatas = updatedEventUser.customFields.filter((cusFormData) => {
						return formFieldIds.includes(cusFormData.uid);
					});

					formFieldsNotPresentInUserDatas = customFieldsArray.filter((cusData) => {
						return !formFieldsPresentInUserDatas.map((cus) => cus.uid).includes(cusData.uid);
					});

					// replce in userDatasPresentInForm the new values from customFieldsArray
					updatedEventUser.customFields = formFieldsPresentInUserDatas
						.concat(formFieldsNotPresentInUserDatas)
						.map((cus) => {
							const cusFormData = customFieldsArray.find((cusForm) => cusForm.uid === cus.uid);

							if (cusFormData) {
								cus.field = cusFormData.field;
							}
							return cus;
						})
						?.concat(datasOfCustomFieldsNotPresentInForm);
				} else {
					updatedEventUser.customFields = customFieldsArray.concat(datasOfCustomFieldsNotPresentInForm);
				}

				// Update eventUser groups if needed
				if (this.getCustomFieldSelectGroups().length > 0) {
					updatedEventUser.groups = updatedEventUser.groups.concat(
						this.getCustomFieldSelectGroups().filter((group) => !updatedEventUser.groups.includes(group))
					);
				}

				// Assigning data
				updatedEventUser.name = name ? name : eventUser.name;
				updatedEventUser.email = eventUser.email; // Can't be updated
				updatedEventUser.editedProfile = true;

				try {
					// Upload event user media to firebase storage et set the url
					this.customFieldMediaToUpload = this.customFieldMediaToUpload.filter((media) => media);
					if (updatedEventUser && this.customFieldMediaToUpload.length > 0) {
						this.eventUser = updatedEventUser;
						await this.uploadCustomFieldMedia();
						this.SEventUsers.updateEventUser(this.eventId, this.module.uid, updatedEventUser);
					}
				} catch (error) {
					this.snackbar.open(this.STranslate.instant("snackbar.failed_to_upload_media"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
				}

				// Upload profil picture
				const photoUrl = await this.formFieldsComponent.saveProfilPicture(this.eventUser);
				if (photoUrl) {
					updatedEventUser.photoUrl = photoUrl;
				}

				// Update event user
				await this.SEventUsers.updateEventUser(this.eventId, this.moduleId, updatedEventUser);

				this.adminEventUser = updatedEventUser;

				// Register user for sessions if necessary
				const registeredDatasForField = this.formFieldsComponent.getRegisteredDatasForField();
				if (registeredDatasForField.length > 0) {
					registeredDatasForField.forEach((datasField) => {
						if (datasField.sessions.length > 0) {
							datasField.sessions.forEach((session) => {
								const sess = this.sessions.find((ses) => ses.uid === session.uid);

								if (sess) {
									if (!sess.registeredUsers.includes(eventUser.uid)) {
										sess.registeredUsers.push(eventUser.uid);
										this.SSchedules.updateSession(sess.eventId, sess.moduleId, sess.uid, sess);
									}
								}
							});
						}
					});
				}

				// Analytics
				await this.SAnalytics.editEventUserProfile(this.eventUser);

				this.modalState$.next({
					shouldClose: true,
					force: true,
					modalId: "fill-form-modal"
				});

				// Register user for sessions if necessary
				try {
					await this.registerToSession(eventUser);
				} catch (error) {}

				this.loader = false;

				if (
					!this.eventUser?.hasFilledRegisterForm ||
					!updatedEventUser?.hasFilledRegisterForm ||
					!this.adminEventUser?.hasFilledRegisterForm
				) {
					// Check automations
					const triggers = this.SAutomations.getAutomationTriggersByType(
						this.automations,
						TriggerType.GENERIC_REGISTER_EVENTUSER
					);

					this.checkAndExcuteAutomations(triggers, this.eventUser);
				}

				// Show invite accompanying modal
				if (
					this.registerForm.formSettings.enableAccompanyingUsers &&
					!this.adminEventUser?.hasFilledRegisterForm
				) {
					const canRegisterAccompagnying = await this.canRegisterAccompanyingUsers();

					if (
						canRegisterAccompagnying &&
						(this.mode === "register-modal"
							? !this.eventUser.isAccompanying
							: !this.adminEventUser.isAccompanying)
					) {
						await this.showInviteModal();
					}
				}

				// Confirm alert
				if (
					this.registerForm.formSettings.enableConfirmationMsg &&
					!this.adminEventUser?.hasFilledRegisterForm
				) {
					this.openConfirmRegisterModal(
						this.registerForm.formSettings?.confirmationSettings?.confirmationText?.[this.currentLanguage()]
					);
				}

				this.SEventUsers.updatePartOfEventUser(this.eventId, this.moduleId, updatedEventUser.uid, {
					hasFilledRegisterForm: true
				});

				this.snackbar.open(this.STranslate.instant("snackbar.update_successfull"), "", {
					duration: 3000,
					panelClass: "success-snackbar"
				});
				this.activeLoader = false;
			} else {
				this.loader = false;
				this.activeLoader = false;
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		} catch (error) {
			// delete custom field image and file from the storage if needed
			this.deleteAllEventUserCustomFieldFilesImagesFromStorage();

			this.loader = false;
			this.activeLoader = true;

			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * showInviteModal
	 */
	async showInviteModal() {
		try {
			const totalRegisterAccompanyings = await firstValueFrom(
				this.SFirestore.getCountOfQueryObs(
					`events/${this.event.uid}/modules/${
						this.mode === "register-modal" ? this.eventUser.moduleId : this.adminEventUser.moduleId
					}/event-users`,
					"default",
					[
						where("isAccompanying", "==", true),
						where(
							"registeredBy",
							"==",
							this.mode === "register-modal" ? this.eventUser.uid : this.adminEventUser.uid
						)
					]
				)
			);

			const modal = await this.modalCtrl.create({
				id: "accompanying-invite-modal",
				component: PathComponents.inviteAccompanyingModal,
				componentProps: {
					event: this.event,
					eventUser: this.mode === "register-modal" ? this.eventUser : this.adminEventUser,
					registerForm: this.registerForm,
					remainingPlaces: this.registerForm.formSettings.accompanyingUsersLimit - totalRegisterAccompanyings
				},
				cssClass: !this.isMobile ? "invite-accompanying-modal" : "invite-accompanying-modal--mobile"
			});

			await modal.present();

			this.activeLoader = false;
		} catch (error) {
			console.error("showInviteModal ~ error:", error);
			this.activeLoader = false;
		}
	}

	/**
	 * Recover password
	 * @param email
	 */
	async recoveryPassword() {
		try {
			const datas = this.formDatas;
			const baseDatas = this.SCustomFields.getRegisterFormBaseFields(
				this.registerForm.formContent.sections,
				datas,
				this.currentLanguage()
			);
			const email: string = baseDatas["email"];
			await firstValueFrom(this.SAuth.recoveryPassword(email));
			this.snackbar.open(this.STranslate.instant("login.recovery_success"), "", {
				duration: 3000,
				panelClass: "success-snackbar"
			});
		} catch (error) {
			console.error("🚀 ~ recoveryPassword ~ error:", error);

			this.snackbar.open(this.STranslate.instant("login.recovery_fail"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * uploadCustomFieldMedia
	 */
	async uploadCustomFieldMedia() {
		try {
			if (
				((this.mode === "accompanying-form-modal" || this.mode === "checkin") && !this.newRegisteredUser) ||
				(this.mode !== "accompanying-form-modal" && this.mode !== "checkin" && !this.eventUser)
			) {
				throw new Error("No event user !");
			}

			for (const customMedia of this.customFieldMediaToUpload) {
				if (customMedia.baseSettings.type === TypeCustomFields.IMAGE) {
					if (
						!customMedia.fieldDatas?.field?.image?.isExternalUrl &&
						customMedia.fieldDatas &&
						!customMedia.fieldDatas?.field?.image?.url
					) {
						try {
							await this.deleteCustomFieldMediaFromStorage(customMedia, this.event.language);
						} catch (error) {}
					}
					const url = await firstValueFrom(
						this.formFieldsComponent.importCustomFieldFileImage(
							this.mode === "accompanying-form-modal" || this.mode === "checkin"
								? this.newRegisteredUser
								: this.eventUser,
							customMedia
						)
					);

					if (url) {
						this.mode === "accompanying-form-modal" || this.mode === "checkin"
							? (this.newRegisteredUser.customFields.find(
									(cus) => cus.uid === customMedia.baseSettings.uid
							  ).field.image.url = url)
							: (this.eventUser.customFields.find(
									(cus) => cus.uid === customMedia.baseSettings.uid
							  ).field.image.url = url);
					}
				} else {
					// file

					if (
						(customMedia.fieldDatas && !customMedia.fieldDatas?.field?.file?.url) ||
						customMedia.fieldDatas?.field?.file?.url === ""
					) {
						try {
							await this.deleteCustomFieldMediaFromStorage(customMedia, this.event.language);
						} catch (error) {}
					}
					const url = await lastValueFrom(
						this.formFieldsComponent.importCustomFieldFileImage(
							this.mode === "accompanying-form-modal" || this.mode === "checkin"
								? this.newRegisteredUser
								: this.eventUser,
							customMedia
						)
					);

					if (url) {
						this.mode === "accompanying-form-modal" || this.mode === "checkin"
							? (this.newRegisteredUser.customFields.find(
									(cus) => cus.uid === customMedia.baseSettings.uid
							  ).field.file.url = url)
							: (this.eventUser.customFields.find(
									(cus) => cus.uid === customMedia.baseSettings.uid
							  ).field.file.url = url);
					}
				}
			}
		} catch (error) {
			console.error("🚀 ~ uploadCustomFieldMedia ~ error:", error);
			this.snackbar.open(this.STranslate.instant("snackbar.failed_to_upload_media"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * deleteAllEventUserCustomFieldFilesImagesFromStorage
	 * @desc Delete (if necessary) customField files and images from the storage
	 * @param customField
	 */
	async deleteAllEventUserCustomFieldFilesImagesFromStorage() {
		this.customFieldMediaToUpload = this.customFieldMediaToUpload.filter((media) => media);

		if (this.customFieldMediaToUpload.length > 0) {
			for (const customMedia of this.customFieldMediaToUpload) {
				try {
					const path = `/events/${this.event.uid}/modules/${this.module.uid}/event-users/${
						this.mode === "accompanying-form-modal" ? this.newRegisteredUser?.uid : this.eventUser.uid
					}/${
						customMedia.baseSettings.type === TypeCustomFields.FILE
							? customMedia.fieldDatas.field.file.name
							: customMedia.fieldDatas.field.image.name
					}`;

					await this.storage.deleteDocumentOnStorage(path);
				} catch (error) {
					console.error("🚀 ~ deleteAllEventUserCustomFieldFilesImagesFromStorage ~ error:", error);
					this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
				}
			}
		}
	}

	/**
	 * Open terms or privacy modal
	 * @param type
	 * @returns
	 */
	async openModalTermsOrPrivacy(type: string) {
		try {
			const modal = await this.modalCtrl.create({
				component: type === "privacy" ? PathComponents.privacy : PathComponents.termsOfUse,
				componentProps:
					type === "privacy"
						? {
								privacy: this.container.privacyTerms,
								event: this.event
						  }
						: {
								terms: this.container.termsOfUse,
								event: this.event
						  }
			});
			return await modal.present();
		} catch (error) {
			console.error("🚀 ~ RegisterFormComponent ~ openModalTermsOrPrivacy ~ error:", error);
		}
	}

	/**
	 * getCustomFieldSelectGroups
	 * @returns string[]
	 */
	getCustomFieldSelectGroups(): string[] {
		const customsFieldsData = Object.assign({}, ...Object.values(this.formDatas));

		let groups: string[] = [];

		const selectCustomFields = this.registerForm.formContent.sections
			.map((section) => section.items)
			.reduce((a, b) => a.concat(b), [])
			.filter(
				(item) =>
					item.type === FormItemType.INPUT &&
					!item.isBaseField &&
					(item.inputFieldType === TypeCustomFields.SELECT ||
						item.inputFieldType === TypeCustomFields.MULTI_SELECT) &&
					(item.value as IFullCustomField)?.baseSettings?.enableGroupAssociation
			)
			.map((item) => item.value as IFullCustomField);
		selectCustomFields.forEach((selectCustomField) => {
			// Check if "Group association is enabled"
			if (selectCustomField.baseSettings.enableGroupAssociation) {
				if (
					customsFieldsData[`${selectCustomField.baseSettings.uid}_${this.currentLanguage()}`] &&
					selectCustomField.baseSettings.optionsGroup &&
					selectCustomField.baseSettings.optionsGroup.length > 0
				) {
					if (selectCustomField.baseSettings.type === TypeCustomFields.SELECT) {
						const optionIndex = selectCustomField.baseSettings.options.findIndex(
							(opt) =>
								opt[this.currentLanguage()] ===
									(customsFieldsData[
										`${selectCustomField.baseSettings.uid}_${this.currentLanguage()}`
									]?.[this.currentLanguage()] ||
										customsFieldsData[
											`${selectCustomField.baseSettings.uid}_${this.event.language}`
										]) && selectCustomField.baseSettings.type === TypeCustomFields.SELECT
						);

						if (optionIndex !== -1) {
							this.groupIds.some((grpId) =>
								selectCustomField.baseSettings.optionsGroup[optionIndex][
									`${this.event.language}_Group_${optionIndex}`
								]?.includes(grpId)
							) &&
								(groups = groups.concat(
									selectCustomField.baseSettings.optionsGroup[optionIndex][
										`${this.event.language}_Group_${optionIndex}`
									]
								));
						}
					} else {
						const options = selectCustomField.baseSettings.options.filter(
							(opt) =>
								customsFieldsData[
									`${selectCustomField.baseSettings.uid}_${this.currentLanguage()}`
								]?.some((cusData) =>
									cusData?.[this.currentLanguage()]?.includes(opt[this.currentLanguage()])
								) && selectCustomField.baseSettings.type === TypeCustomFields.MULTI_SELECT
						);

						if (options.length > 0) {
							options.forEach((option) => {
								const optionIndex = selectCustomField.baseSettings.options.findIndex(
									(opt) => opt[this.currentLanguage()] === option[this.currentLanguage()]
								);

								if (optionIndex !== -1) {
									this.groupIds.some((grpId) =>
										selectCustomField.baseSettings.optionsGroup[optionIndex][
											`${this.event.language}_Group_${optionIndex}`
										]?.includes(grpId)
									) &&
										(groups = groups.concat(
											selectCustomField.baseSettings.optionsGroup[optionIndex][
												`${this.event.language}_Group_${optionIndex}`
											]
										));
								}
							});
						}
					}
				}
			}
		});

		// remove duplicates items in array
		return Array.from(new Set(groups));
	}

	/**
	 * captchaResolved
	 * @param captchaResponse
	 */
	async captchaResolved(recaptchaResponse: any, key) {
		if (recaptchaResponse) {
			this.recaptchaStatus = false;

			this.recaptchaResponse = recaptchaResponse;
			try {
				const data: any = await firstValueFrom(this.SAuth.verifyCaptcha(this.event, recaptchaResponse));
				data && data.result && data.result.success && (this.recaptchaStatus = true);
			} catch (error) {
				console.error("🚀 ~ captchaResolved ~ error:", error);
				this.recaptchaStatus = false;
			}
		}
	}

	/**
	 * onCaptchaError
	 * @param error
	 */
	onCaptchaError(error: any) {
		console.error("🚀 ~ RegisterFormComponent ~ onCaptchaError ~ error:", error);
		// this.recaptchaRef.reset();
		this.recaptchaStatus = false;
	}

	/**
	 * recaptchaV3Validation
	 * TODO: not use for the moment
	 */
	recaptchaV3Validation() {
		this.recaptchaV3Service.execute("importantAction").subscribe(async (token) => {
			if (!token) return;

			try {
				const data: any = await firstValueFrom(this.SAuth.verifyCaptcha(this.event, token));

				data && data.result.success && data.result.score >= this.event.settings.captchaThreshold
					? (this.recaptchaStatus = true)
					: this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
							duration: 3000
					  });
			} catch (error) {
				console.error("🚀 ~ this.recaptchaV3Service.execute ~ error:", error);
				this.recaptchaStatus = false;
			}
		});
	}

	/**
	 * Open login page
	 */
	openLogin() {
		this.activeLoader == false;
		this.modalCtrl.dismiss({
			openLogin: true
		});
	}

	/**
	 * openFillProfil
	 */
	openFillProfil() {
		this.activeLoader == false;
		this.modalCtrl.dismiss({
			openFillProfil: true
		});
	}

	/**
	 * canNotCloseModal
	 * @returns
	 */
	canNotCloseModal() {
		return (
			(this.mode === "register-modal" && this.event.settings.requiredSignup && !this.loggedInUser) ||
			(this.mode === "fill-form-modal" &&
				((this.event.settings.requiredFillForm &&
					this.adminEventUser &&
					!this.adminEventUser?.hasFilledRegisterForm) ||
					(this.adminEventUser &&
						this.registerForm.formSettings.requiredFillFormForAccompnaying &&
						this.adminEventUser?.isAccompanying &&
						!this.adminEventUser?.hasFilledRegisterForm)))
		);
	}

	/**
	 * Close modal
	 */
	async close(config?: { [modalId: string]: boolean }, modalId?: string) {
		this.activeLoader == false;

		try {
			await this.modalCtrl.dismiss(null, null, modalId ? modalId : this.mode);

			// re-show the invite modal if necessary
			const canRegisterAccompagnying = await this.canRegisterAccompanyingUsers();
			if (
				config &&
				config[modalId] &&
				this.registerForm.formSettings.enableAccompanyingUsers &&
				canRegisterAccompagnying &&
				(this.mode === "register-modal"
					? !this.eventUser.isAccompanying
					: !this.adminEventUser.isAccompanying) &&
				modalId === "accompanying-invite-modal"
			) {
				await this.showInviteModal();
			}
		} catch (error) {
			console.error("🚀 ~ close ~ error:", error);
		}
	}

	/**
	 * Open login modal
	 * @returns
	 */
	async openConfirmRegisterModal(message: string) {
		try {
			const modal = await this.modalCtrl.create({
				id: "confirm-register-modal",
				component: PathComponents.confirmRegisterModal,
				componentProps: {
					event: this.event,
					eventUser: this.eventUser,
					message: message,
					enableAddToCalendar: this.registerForm.formSettings.confirmationSettings.enableAddToCalendar,
					calendarEvent: this.registerForm.formSettings.confirmationSettings.calendarEvent
				},
				cssClass: this.isMobile ? "full-sized-modal" : "confirm-register-modal"
			});
			await modal.present();

			await modal.onWillDismiss();
		} catch (error) {
			console.error("🚀 ~ openConfirmRegisterModal ~ error:", error);
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Changing language
	 */
	async changeLanguage() {
		try {
			const picker = await this.pickerCtrl.create({
				columns: [
					{
						name: "lang",
						options: [
							{
								text: this.STranslate.instant("languages.arabic"),
								value: "ArAR"
							},
							{
								text: this.STranslate.instant("languages.german"),
								value: "DeDE"
							},
							{
								text: this.STranslate.instant("languages.english"),
								value: "EnUS"
							},
							{
								text: this.STranslate.instant("languages.spanish"),
								value: "EsES"
							},
							{
								text: this.STranslate.instant("languages.french"),
								value: "FrFR"
							},
							{
								text: this.STranslate.instant("languages.portuguese"),
								value: "PtBR"
							}
						].filter((lang) => {
							if (this.event.languagesSettings[lang.value] && lang.value !== this.currentLanguage()) {
								return true;
							} else {
								return false;
							}
						})
					}
				],
				buttons: [
					{
						text: this.STranslate.instant("buttons.cancel"),
						role: "cancel"
					},
					{
						text: this.STranslate.instant("buttons.ok"),
						handler: (option) => {
							const lang = option.lang.value;
							if (this.user) {
								this.user.language = lang;
								if (this.eventUser) {
									this.eventUser.updatedSettings.language = lang;

									this.SAuth.updateUser(this.user);
									this.SEventUsers.updateEventUser(
										this.eventUser.eventId,
										this.eventUser.moduleId,
										this.eventUser
									);
								}
							}
							this.language = lang.slice(0, 2).toLowerCase();
							this.STranslate.use(lang);

							// Reset the form with the new language
							// this.formFieldsComponent.initRegisterForm();
							// this.formFieldsComponent.initForm();
						}
					}
				]
			});
			await picker.present();
		} catch (error) {
			console.error("🚀 ~ RegisterFormComponent ~ changeLanguage ~ error:", error);
		}
	}

	/**
	 * deleteCustomFieldFilesImagesFromStorage
	 * @desc Delete (if necessary) customField files and images from the storage
	 * @param customField
	 */
	async deleteCustomFieldMediaFromStorage(customField: IFullCustomField, language: string) {
		if (customField) {
			try {
				const path =
					`/events/${this.event.uid}/modules/${this.module.uid}` +
					`/event-users/${
						this.mode === "accompanying-form-modal" ? this.newRegisteredUser?.uid : this.eventUser?.uid
					}/` +
					`${
						customField.baseSettings.type === TypeCustomFields.FILE
							? customField.fieldDatas.field.file.name
							: customField.fieldDatas.field.image.name
					}`;

				const fileExist = await this.storage.checkIfFileExists(path);

				if (fileExist) {
					try {
						await this.storage.deleteDocumentOnStorage(path);
					} catch (error) {
						console.error("🚀 ~ deleteCustomFieldMediaFromStorage ~ error:", error);
					}
				}
			} catch (error) {
				console.error("🚀 ~ deleteCustomFieldMediaFromStorage ~ error:", error);
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		}
	}

	/**
	 * deleteCustomFieldFileImage
	 * @param customField
	 */
	async deleteCustomFieldFileImage(customField: IFullCustomField) {
		try {
			const path = `/events/${this.event.uid}/modules/${this.module.uid}/event-users/${
				this.mode === "accompanying-form-modal" ? this.newRegisteredUser?.uid : this.eventUser?.uid
			}/${
				customField.baseSettings.type === TypeCustomFields.FILE
					? customField.fieldDatas.field.file.name
					: customField.fieldDatas.field.image.name
			}`;

			await this.storage.deleteDocumentOnStorage(path);
		} catch (error) {
			console.error("🚀 ~ deleteCustomFieldFileImage ~ error:", error);
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * checkAndExcuteAutomations
	 * @param triggers
	 * @param eventUser
	 */
	checkAndExcuteAutomations(triggers: ITrigger[], eventUser: IEventUser): void {
		if (triggers.length > 0) {
			for (const trigger of triggers) {
				if (
					this.SAutomations.checkAutomationTriggerConditions(trigger, eventUser, {
						event: this.event,
						customFields: this.customFields.map((cus) => cus.baseSettings),
						groupIds: this.groupIds,
						moduleIds: this.attendeeModuleIds
					})
				) {
					this.SAutomations.excecuteAutomationTrigger(trigger, {
						eventUser: eventUser,
						event: this.event
					});
				}
			}
		}
	}

	/**
	 * Open openAccompanyingForm
	 */
	async openAccompanyingForm() {
		try {
			const canRegisterAccompagnying = await this.canRegisterAccompanyingUsers();
			if (!canRegisterAccompagnying) {
				this.snackbar.open(this.STranslate.instant("register.accompanying_users_limit_reached"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}

			const modal = await this.modalCtrl.create({
				id: "accompanying-form-modal",
				component: PathComponents.registerForm,
				componentProps: {
					eventId: this.event.uid,
					moduleId: this.eventUser.moduleId,
					eventUserProfileId: this.eventUser.uid,
					mode: "accompanying-form-modal"
				},
				cssClass: "full-sized-modal"
			});

			await modal.present();

			modal.onWillDismiss().then(async (data) => {
				if (data.data.reOpenModal.inviteModal) {
					await this.showInviteModal();
				}
			});
		} catch (error) {
			console.error("🚀 ~ RegisterFormComponent ~ openAccompanyingForm ~ error:", error);
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * canRegisterAccompanyingUsers
	 * @returns
	 */
	async canRegisterAccompanyingUsers(): Promise<boolean> {
		try {
			const [totalEventUsers, totalModuleEventUsers, totalEventUsersAccompanying] = await Promise.all([
				firstValueFrom(
					this.SFirestore.getCountOfQueryObs("event-users", "group", [where("eventId", "==", this.event.uid)])
				),
				firstValueFrom(
					this.SFirestore.getCountOfQueryObs(
						`events/${this.event.uid}/modules/${
							this.mode === "register-modal" ? this.eventUser.moduleId : this.adminEventUser.moduleId
						}/event-users`,
						"default",
						[]
					)
				),
				firstValueFrom(
					this.SFirestore.getCountOfQueryObs(
						`events/${this.event.uid}/modules/${
							this.mode === "register-modal" ? this.eventUser.moduleId : this.adminEventUser.moduleId
						}/event-users`,
						"default",
						[
							where("isAccompanying", "==", true),
							where(
								"registeredBy",
								"==",
								this.mode === "register-modal" ? this.eventUser.uid : this.adminEventUser.uid
							)
						]
					)
				)
			]);

			return !(
				totalEventUsers >= this.event?.limitations?.usersLimit ||
				(this.registerForm &&
					this.registerForm.formSettings.enableRegisterLimit &&
					totalModuleEventUsers >= this.registerForm.formSettings.registerLimit) ||
				(this.registerForm &&
					this.registerForm.formSettings.enableAccompanyingUsers &&
					this.adminEventUser &&
					totalEventUsersAccompanying >= this.registerForm.formSettings.accompanyingUsersLimit)
			);
		} catch (error) {
			console.error("🚀 ~ RegisterFormComponent ~ canRegisterAccompanyingUsers ~ error:", error);
		}
	}

	/**
	 * registrationLimitReached
	 * @returns boolean
	 */
	registrationLimitReached(): boolean {
		return (
			this.totalEventUsers >= this.event?.limitations?.usersLimit ||
			(this.registerForm &&
				this.registerForm.formSettings.enableRegisterLimit &&
				this.totalModuleEventUsers >= this.registerForm.formSettings.registerLimit)
		);
	}

	/**
	 * buildFieldsVisibility
	 * @returns
	 */
	buildFieldsVisibility() {
		return Object.entries(this.module.options.requiredFields)
			.map((obj) => {
				return {
					[obj[0]]: obj[1][obj[0]]?.["hiding"]?.["default"] ? obj[1][obj[0]]?.["hiding"]?.["default"] : false
				};
			})
			.concat(
				this.computedCustomFields.map((cus) => {
					return {
						[cus.baseSettings.uid]: cus.moduleSettings.hiding.default
							? cus.moduleSettings.hiding.default
							: false
					};
				})
			)
			.reduce((result, obj) => {
				Object.keys(obj).forEach((key) => {
					result[key] = obj[key];
				});
				return result;
			}, {});
	}

	/**
	 * registerToSession
	 * @param eventUser
	 */
	async registerToSession(eventUser: IEventUser) {
		if (this.formFieldsComponent.registeredDatasForField.length > 0) {
			for (const datasField of this.formFieldsComponent.registeredDatasForField) {
				const module = this.scheduleModules.find((mod) => mod.uid === datasField.moduleId);
				if (datasField.sessions.length > 0) {
					for (const session of datasField.sessions) {
						const sess = this.sessions.find((ses) => ses.uid === session.uid);
						if (sess) {
							sess;
							if (!sess.registeredUsers.includes(eventUser.uid)) {
								sess.registeredUsers.push(eventUser.uid);
								await this.SSchedules.updateSession(sess.eventId, sess.moduleId, sess.uid, sess);
							}
						}
					}
				}
			}
		}
	}

	/**
	 * groupRegisterWhenRegisterToSession
	 * @param eventUser
	 */
	async groupRegisterWhenRegisterToSession(eventUser: IEventUser) {
		if (this.formFieldsComponent.registeredDatasForField.length > 0) {
			for (const datasField of this.formFieldsComponent.registeredDatasForField) {
				const module = this.scheduleModules.find((mod) => mod.uid === datasField.moduleId);
				// Groups registration
				if (module.options.allowSessionGroupRegistration) {
					for (const session of datasField.sessions) {
						session.groups.forEach((sessionGroup) => {
							if (this.checkRegister(session)) {
								// register
								if (!eventUser.groups.includes(sessionGroup)) {
									eventUser.groups.push(sessionGroup);
								}
							}
						});
					}

					await this.SEventUsers.updatePartOfEventUser(this.eventId, eventUser.moduleId, eventUser.uid, {
						groups: eventUser.groups
					});
				}
			}
		}
	}

	/**
	 * Check if attendees is registered for the session
	 * @returns
	 */
	checkRegister(session: ISchedule) {
		return session.registeredUsers.find((uid) => this.eventUser && uid === this.eventUser.uid) ? true : false;
	}

	/**
	 * closeModalWatcher
	 * @description Watcher for closing modal, based on latest eventUser state
	 */
	closeModalWatcher() {
		if (this.closeModalSub && !this.closeModalSub.closed) {
			this.closeModalSub.unsubscribe();
		}

		this.closeModalSub = this.modalState$.subscribe(async (modalState) => {
			if (modalState.shouldClose) {
				if (!this.canNotCloseModal() || modalState.force) {
					try {
						await this.close(modalState.data ? modalState.data?.reOpenModal : null, modalState.modalId);
					} catch (error) {
						console.error("closeModalWatcher ~ error:", error);
					}
				}
			}
		});
	}
}
