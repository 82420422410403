import * as _ from "lodash-es";
import { ISearchFilter } from "../interfaces/search.interfaces";
import {
	ICustomFieldData,
	IDocument,
	IEventUser,
	IFullCustomField,
	IGroup,
	ILocation,
	IModule,
	ISchedule,
	ITrack
} from "../interfaces";
import { TypeCustomFields } from "../enums/type-custom-fields";
import { TypeModule } from "../enums/type-module";
var he = require("he");

export const filterSearch = (filters: ISearchFilter, datas: any[]) => {
	let datasFiltered: any[] = datas;

	if (!filters) {
		return {
			datas: datas,
			totalItems: datas.length,
			focusedItemId: ""
		};
	}
	datasFiltered = datas.filter((data) => {
		if (filters.equalityFields.length > 0) {
			let check: boolean = true;
			filters.equalityFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) != fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.inequalityFields.length > 0) {
			let check: boolean = true;
			filters.inequalityFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) == fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.includeTextFields.length > 0) {
			let check: boolean = true;
			filters.includeTextFields.forEach((fieldFilter) => {
				if (
					!_.get(data, fieldFilter.fieldKey)
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.toLocaleUpperCase()
						.includes(
							fieldFilter.compareData
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.toLocaleUpperCase()
						)
				) {
					check = false;
				}
			});

			if (!check) {
				return false;
			}
		}

		if (filters.includeOrTextFields.length > 0) {
			let check: boolean = true;
			check = filters.includeOrTextFields.some((fieldFilter) => {
				if (fieldFilter.fieldKey === "groups") {
					return _.get(data, fieldFilter.fieldKey).some((data) => fieldFilter.compareData.includes(data));
				} else if (
					fieldFilter.fieldKey.includes("customFields") &&
					fieldFilter.fieldKey.includes("module.items")
				) {
					const separatedFields = fieldFilter.fieldKey.split("|");
					if (data["customFields"] && separatedFields[1] && separatedFields[2]) {
						return data["customFields"].some((cus: ICustomFieldData) => {
							return (
								cus &&
								cus.uid === separatedFields[2] &&
								_.get(cus.field as any, separatedFields[1]).some((data) =>
									fieldFilter.compareData.includes(data)
								)
							);
						});
					} else {
						return false;
					}
				} else if (fieldFilter.fieldKey.includes("customFields")) {
					const separatedFields = fieldFilter.fieldKey.split("|");
					if (data["customFields"] && separatedFields[1] && separatedFields[2]) {
						return data["customFields"].some((cus: ICustomFieldData) => {
							if (separatedFields[3]) {
								if (separatedFields[1] === "multiLanguageSelectArray") {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										cus.field.multiLanguageSelectArray
											.map((opt) =>
												opt[separatedFields[3]]
													.toString()
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.toLocaleUpperCase()
											)
											.some((dataCheck) =>
												dataCheck
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.toLocaleUpperCase()
													.includes(
														fieldFilter.compareData
															.normalize("NFD")
															.replace(/[\u0300-\u036f]/g, "")
															.toLocaleUpperCase()
													)
											)
									);
								} else if (separatedFields[1] === "multiLanguageTextArray") {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										cus.field.multiLanguageTextArray[separatedFields[3]] &&
										cus.field.multiLanguageTextArray[separatedFields[3]].length > 0 &&
										cus.field.multiLanguageTextArray[separatedFields[3]].some((dataCheck) => {
											return dataCheck
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase()
												.includes(
													fieldFilter.compareData
														.normalize("NFD")
														.replace(/[\u0300-\u036f]/g, "")
														.toLocaleUpperCase()
												);
										})
									);
								} else {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										(cus.field as any)[separatedFields[1]] &&
										(cus.field as any)[separatedFields[1]][separatedFields[3]] &&
										(cus.field as any)[separatedFields[1]][separatedFields[3]]
											.normalize("NFD")
											.replace(/[\u0300-\u036f]/g, "")
											.toLocaleUpperCase()
											.includes(
												fieldFilter.compareData
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.toLocaleUpperCase()
											)
									);
								}
							} else {
								return (
									cus &&
									cus.uid === separatedFields[2] &&
									(cus.field as any)[separatedFields[1]] &&
									(cus.field as any)[separatedFields[1]]
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
										.toLocaleUpperCase()
										.includes(
											fieldFilter.compareData
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase()
										)
								);
							}
						});
					} else {
						return false;
					}
				} else {
					return he
						.decode(_.get(data, fieldFilter.fieldKey))
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.toLocaleUpperCase()
						.includes(
							he
								.decode(fieldFilter.compareData)
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/g, "")
								.toLocaleUpperCase()
						);
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.isIncludedInFields && filters.isIncludedInFields.length > 0) {
			let check: boolean = true;
			filters.isIncludedInFields.forEach((fieldFilter) => {
				if (!fieldFilter.compareData.includes(_.get(data, fieldFilter.fieldKey))) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.anyTextFields.length > 0) {
			let check: boolean = true;
			if (!filters.anyTextFields.some((filter) => _.get(data, filter.fieldKey) == filter.compareData)) {
				check = false;
			}
			if (!check) {
				return false;
			}
		}

		if (filters.inferiorFields.length > 0) {
			let check: boolean = true;
			filters.inferiorFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) >= fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.inferiorOrEqualFields.length > 0) {
			let check: boolean = true;

			filters.inferiorOrEqualFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) > fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.superiorFields.length > 0) {
			let check: boolean = true;

			filters.superiorFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) <= fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.superiorOrEqualFields.length > 0) {
			let check: boolean = true;

			filters.superiorOrEqualFields.forEach((fieldFilter) => {
				if (_.get(data, fieldFilter.fieldKey) < fieldFilter.compareData) {
					check = false;
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.arrayContainsBlocAndOrFields.length > 0) {
			let check: boolean = true;
			filters.arrayContainsBlocAndOrFields.forEach((fieldFilter) => {
				if (!fieldFilter.fieldKey.includes("customFields")) {
					if (
						!fieldFilter.compareData.some((comparedData) =>
							_.get(data, fieldFilter.fieldKey).includes(comparedData)
						)
					) {
						check = false;
					}
				} else {
					const separatedFields = fieldFilter.fieldKey.split("|");
					if (data["customFields"] && separatedFields[1] && separatedFields[2]) {
						check = data["customFields"].some((cus: ICustomFieldData) => {
							if (separatedFields[3]) {
								if (separatedFields[1] === "multiLanguageSelectArray") {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										fieldFilter.compareData.some(
											(comparedData) =>
												cus &&
												cus.uid &&
												cus.field.multiLanguageSelectArray
													.map((opt) =>
														opt[separatedFields[3]]
															.toString()
															.normalize("NFD")
															.replace(/[\u0300-\u036f]/g, "")
															.toLocaleUpperCase()
													)
													.includes(
														comparedData
															.normalize("NFD")
															.replace(/[\u0300-\u036f]/g, "")
															.toLocaleUpperCase()
													)
										)
									);
								} else {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										fieldFilter.compareData.some(
											(comparedData) =>
												cus &&
												cus.uid &&
												(cus.field as any)[separatedFields[1]] &&
												(cus.field as any)[separatedFields[1]][separatedFields[3]] &&
												(cus.field as any)[separatedFields[1]][separatedFields[3]]
													.normalize("NFD")
													.replace(/[\u0300-\u036f]/g, "")
													.toLocaleUpperCase() ===
													comparedData
														.normalize("NFD")
														.replace(/[\u0300-\u036f]/g, "")
														.toLocaleUpperCase()
										)
									);
								}
							} else {
								return (
									cus &&
									cus.uid === separatedFields[2] &&
									fieldFilter.compareData.some(
										(comparedData) =>
											(cus.field as any)[separatedFields[1]]
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase() ===
											comparedData
												.normalize("NFD")
												.replace(/[\u0300-\u036f]/g, "")
												.toLocaleUpperCase()
									)
								);
							}
						});
					} else {
						check = false;
					}
				}
			});
			if (!check) {
				return false;
			}
		}

		if (filters.arrayContainsAllFields.length > 0) {
			let check: boolean = true;
			if (
				!filters.arrayContainsAllFields.every((fieldFilter) => {
					if (!fieldFilter.fieldKey.includes("customFields")) {
						return _.get(data, fieldFilter.fieldKey).includes(fieldFilter.compareData);
					} else {
						const separatedFields = fieldFilter.fieldKey.split("|");
						if (data["customFields"] && separatedFields[1] && separatedFields[2] && data["customFields"]) {
							return data["customFields"].some((cus: ICustomFieldData) => {
								if (separatedFields[3]) {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										(cus.field as any)[separatedFields[1]][separatedFields[3]] ===
											fieldFilter.compareData
									);
								} else {
									return (
										cus &&
										cus.uid === separatedFields[2] &&
										(cus.field as any)[separatedFields[1]] === fieldFilter.compareData
									);
								}
							});
						} else {
							return false;
						}
					}
				})
			) {
				check = false;
			}
			if (!check) {
				return false;
			}
		}

		if (filters.arrayContainsAnyFields.length > 0) {
			let check: boolean = true;
			check = filters.arrayContainsAnyFields.some((fieldFilter) => {
				return _.get(data, fieldFilter.fieldKey)
					.map((data) =>
						data
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.toLocaleUpperCase()
					)
					.includes(
						fieldFilter.compareData
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.toLocaleUpperCase()
					);
			});

			if (!check) {
				return false;
			}
		}

		return true;
	});

	// Sorting
	if (filters.sortBy && filters.sortBy.length > 0) {
		datasFiltered = datasFiltered.sort((a, b) => {
			const aKeyValue = _.get(a, filters.sortBy[0].fieldKey);
			const bKeyValue = _.get(b, filters.sortBy[0].fieldKey);
			if (filters.sortBy[0].type === "asc") {
				return aKeyValue > bKeyValue ? 1 : aKeyValue < bKeyValue ? -1 : 0;
			} else {
				return aKeyValue < bKeyValue ? 1 : aKeyValue > bKeyValue ? -1 : 0;
			}
		});
	} else {
		datasFiltered = datasFiltered.sort((a, b) => {
			const aKeyValue = _.get(a, "creationDate");
			const bKeyValue = _.get(b, "creationDate");
			return aKeyValue > bKeyValue ? 1 : aKeyValue < bKeyValue ? -1 : 0;
		});
	}
	const totalItems: number = datasFiltered.length;

	const returnedObject: { datas: any[]; totalItems: number; focusedItemId: "" } = {
		datas: [],
		totalItems: 0,
		focusedItemId: ""
	};

	if (filters.page > 0 && filters.itemsPerPage > 0 && datasFiltered.length > 0) {
		const chunkedDatas = _.chunk(datasFiltered, filters.itemsPerPage);
		returnedObject.datas = chunkedDatas[filters.page - 1];
	}

	returnedObject.totalItems = totalItems;

	return returnedObject;
};

export const buildFiltersQuery = (
	modules: IModule[],
	filtersQuery: ISearchFilter,
	searchValue: string,
	currentLanguage: string,
	computedCustomFields: IFullCustomField[],
	attendees: IEventUser[],
	speakers: IEventUser[],
	sessions: ISchedule[],
	documents: IDocument[],
	groups: IGroup[],
	locations: ILocation[],
	tracks: ITrack[]
) => {
	console.log("Search value: ", searchValue, searchValue.normalize("NFC"));
	if (searchValue) {
		searchValue = searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		modules.forEach((module) => {
			if (module && module.options && module.options.searchFields) {
				module.options.searchFields.forEach((searchField) => {
					// If search activated on field
					if (searchField.searchActivated) {
						// If search field is a custom field
						if (
							searchField.isCustomField &&
							getCustomFieldType(computedCustomFields, searchField.customFieldUid) !==
								TypeCustomFields.MODULE
						) {
							const fieldKey =
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.TEXT ||
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.SELECT
									? "multiLanguageText"
									: getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
											TypeCustomFields.URL ||
									  getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
											TypeCustomFields.EMAIL
									? "text"
									: getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									  TypeCustomFields.NUMERIC
									? "numeric"
									: getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									  TypeCustomFields.MULTI_SELECT
									? "multiLanguageSelectArray"
									: getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									  TypeCustomFields.MULTI_TEXT
									? "multiLanguageTextArray"
									: false;

							const isMultilanguage: boolean =
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.TEXT ||
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.SELECT ||
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.MULTI_SELECT ||
								getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
									TypeCustomFields.MULTI_TEXT
									? true
									: false;

							if (
								!filtersQuery.includeOrTextFields.find(
									(filter) =>
										filter.fieldKey ===
										"customFields|" +
											fieldKey +
											"|" +
											searchField.customFieldUid +
											(isMultilanguage ? "|" + currentLanguage : "")
								)
							) {
								filtersQuery.includeOrTextFields.push({
									fieldKey:
										"customFields|" +
										fieldKey +
										"|" +
										searchField.customFieldUid +
										(isMultilanguage ? "|" + currentLanguage : ""),
									compareData: searchValue
								});
							}
						} else if (
							searchField.isCustomField &&
							getCustomFieldType(computedCustomFields, searchField.customFieldUid) ===
								TypeCustomFields.MODULE
						) {
							const correspondingCustomField = computedCustomFields.find(
								(custom) =>
									custom &&
									custom.baseSettings &&
									custom.baseSettings.uid === searchField.customFieldUid
							);
							if (correspondingCustomField) {
								const basedDatas: any[] =
									correspondingCustomField.moduleSettings.customFieldModuleType ===
									TypeModule.ATTENDEE
										? attendees
										: correspondingCustomField.moduleSettings.customFieldModuleType ===
										  TypeModule.SPEAKER
										? speakers
										: correspondingCustomField.moduleSettings.customFieldModuleType ===
										  TypeModule.DOCUMENT
										? documents
										: correspondingCustomField.moduleSettings.customFieldModuleType ===
										  TypeModule.SCHEDULE
										? sessions
										: correspondingCustomField.moduleSettings.customFieldModuleType ===
										  TypeModule.TRACKS
										? tracks
										: correspondingCustomField.moduleSettings.customFieldModuleType ===
										  TypeModule.LOCATION
										? locations
										: [];
								const fieldKey = "customFields|" + "module.items" + "|" + searchField.customFieldUid;
								if (
									correspondingCustomField.moduleSettings.customFieldModuleType ===
										TypeModule.ATTENDEE ||
									correspondingCustomField.moduleSettings.customFieldModuleType === TypeModule.SPEAKER
								) {
									const datasFound = basedDatas.filter((data) =>
										data.name
											.normalize("NFD")
											.replace(/[\u0300-\u036f]/g, "")
											.toLocaleUpperCase()
											.includes(searchValue.toLocaleUpperCase())
									);
									if (datasFound.length > 0) {
										const bloc = filtersQuery.includeOrTextFields.find(
											(bloc) => bloc.fieldKey === fieldKey
										);
										if (!bloc) {
											if (
												!filtersQuery.includeOrTextFields.find(
													(filter) => filter.fieldKey === fieldKey
												)
											) {
												filtersQuery.includeOrTextFields.push({
													fieldKey: fieldKey,
													compareData: datasFound.map((data) => data.uid)
												});
											}
										} else {
											bloc.compareData.concat(datasFound.map((data) => data.uid));
										}
									}
								} else {
									const datasFound = basedDatas.filter((data) =>
										data.name[currentLanguage]
											.normalize("NFD")
											.replace(/[\u0300-\u036f]/g, "")
											.toLocaleUpperCase()
											.includes(searchValue.toLocaleUpperCase())
									);
									if (datasFound.length > 0) {
										const bloc = filtersQuery.includeOrTextFields.find(
											(bloc) => bloc.fieldKey === fieldKey + "|" + currentLanguage
										);
										if (!bloc) {
											if (
												!filtersQuery.includeOrTextFields.find(
													(filter) => filter.fieldKey === fieldKey + "|" + currentLanguage
												)
											) {
												filtersQuery.includeOrTextFields.push({
													fieldKey: fieldKey + "|" + currentLanguage,
													compareData: datasFound.map((data) => data.uid)
												});
											}
										} else {
											bloc.compareData.concat(datasFound.map((data) => data.uid));
										}
									}
								}
							}
						} else {
							// If search field is groups
							if (searchField.fieldType === "groups") {
								const groupsFound = groups.filter((group) =>
									group.name
										.normalize("NFD")
										.replace(/[\u0300-\u036f]/g, "")
										.toLocaleUpperCase()
										.includes(searchValue.toLocaleUpperCase())
								);
								if (groupsFound.length > 0) {
									const bloc = filtersQuery.includeOrTextFields.find(
										(bloc) => bloc.fieldKey === "groups"
									);
									if (!bloc) {
										if (
											!filtersQuery.includeOrTextFields.find(
												(filter) => filter.fieldKey === "groups"
											)
										) {
											filtersQuery.includeOrTextFields.push({
												fieldKey: "groups",
												compareData: groupsFound.map((group) => group.uid)
											});
										}
									} else {
										bloc.compareData.concat(groupsFound.map((group) => group.uid));
									}
								}
							} else {
								// If search field is other type
								if (
									!filtersQuery.includeOrTextFields.find(
										(filter) =>
											filter.fieldKey ===
											`${searchField.key}${
												searchField.multiLanguage ? "." + currentLanguage : ""
											}`
									)
								) {
									filtersQuery.includeOrTextFields.push({
										fieldKey: `${searchField.key}${
											searchField.multiLanguage ? "." + currentLanguage : ""
										}`,
										compareData: searchValue
									});
								}
							}
						}
					}
				});
			} else {
				if (
					!filtersQuery.includeTextFields.find(
						(filter) =>
							filter.fieldKey ===
							(module.options &&
							module.options.usersOrder &&
							["asc", "desc", "recent", "oldest"].includes(module.options.usersOrder)
								? "queryName"
								: "identifier")
					)
				) {
					filtersQuery.includeTextFields.push({
						fieldKey:
							module.options &&
							module.options.usersOrder &&
							["asc", "desc", "recent", "oldest"].includes(module.options.usersOrder)
								? "queryName"
								: "identifier",
						compareData: searchValue
					});
				}
			}
		});
	}
	return filtersQuery;
};

const getCustomFieldType = (computedCustomFields: IFullCustomField[], uid: string) => {
	return computedCustomFields.find(
		(computedCustomField) =>
			computedCustomField && computedCustomField.baseSettings && computedCustomField.baseSettings.uid === uid
	)?.baseSettings.type;
};
