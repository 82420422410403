<div class="filter_bloc">
	<div class="close-chip-bloc">
		<ion-chip class="filter-close-chip" (click)="onFilter()">
			<ion-label>{{ "texts.filter" | translate }}</ion-label>
		</ion-chip>
	</div>

	<!-- Reset filter button -->
	<div class="clear-chip-bloc">
		<ion-label class="filter-clear-chip" (click)="resetFilter()">
			{{ "alerts.clear-filter" | translate }}
		</ion-label>
	</div>

	<div class="delete-all-chip-bloc">
		@if (selectedFilters.length > 0) {
		<ion-chip color="danger" (click)="clearAllFilter()">
			<ion-label>{{ "filter.delete_all" | translate }}</ion-label>
			<ion-icon name="close-circle"></ion-icon>
		</ion-chip>
		}
	</div>

	<!-- Schedules and event users filters -->
	@if (module.type === typeModule.SCHEDULE || module.type === typeModule.ATTENDEE || module.type ===
	typeModule.SPEAKER) {
	<div class="filter-content" style="padding: 10px !important">
		@if ( filterIsActivated('groups') || filterIsActivated('locations') || filterIsActivated('tracks') ||
		filterIsActivated('customs-fields') ) {
		<ion-chip color="danger" (click)="clearAllFilter()">
			<ion-label class="chip-delete-text">{{ "filter.delete_all" | translate }}</ion-label>
			<ion-icon name="close-circle"></ion-icon>
		</ion-chip>
		}
		<!-- Locations -->
		@if (((module.options['baseFields'] && module.options['baseFields'].location &&
		module.options['baseFields'].location.filter) || (module.options['requiredFields'] &&
		module.options['requiredFields']['location'] && module.options['requiredFields']['location'].filter)) &&
		filters.locations.length > 0) {
		<div class="filter-content" style="padding: 10px !important">
			<div
				(click)="collapse('locations')"
				style="
					display: flex;
					justify-content: space-between;
					margin-top: 5px;
					margin-bottom: 5px;
					cursor: pointer;
				"
			>
				<h5 class="filter-name" [style.color]="event.styling.titleColor">
					{{
						module.options.locationName && module.options.locationName[language] !== ""
							? module.options.locationName[language]
							: ("labels.locations" | translate)
					}}:
				</h5>
				@if (!getFilterCollapseState('locations')) {
				<ion-icon src="assets/icon/ArrowUp_thin.svg" style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
				} @if (getFilterCollapseState('locations')) {
				<ion-icon src="assets/icon/ArrowDown_thin.svg" style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
				}
			</div>
			<ion-list
				class="filter_bloc_values accordion-container"
				[class.collapsed]="getFilterCollapseState('locations')"
				[style.maxHeight]="'auto'"
			>
				@for (locationItem of filters.locations; track locationItem) {
				<ion-item lines="none">
					<ion-label class="filter-label">{{ locationItem.name }}</ion-label>
					<ion-checkbox
						color="dark"
						slot="end"
						[value]="locationItem.name"
						[(ngModel)]="locationItem.checked"
						[disabled]="!locationItem.isReachable"
					>
					</ion-checkbox>
				</ion-item>
				} @if (filterIsActivated('locations')) {
				<ion-chip color="danger" (click)="clearFilter('locations')">
					<ion-label class="chip-delete-text"
						>{{ "filter.delete_all" | translate }} ({{
							module.options.locationName && module.options.locationName[language] !== ""
								? module.options.locationName[language]
								: ("labels.locations" | translate)
						}})
					</ion-label>
					<ion-icon name="close-circle"></ion-icon>
				</ion-chip>
				}
			</ion-list>
		</div>
		}
		<!-- Tracks -->
		@if (((module.options['baseFields'] && module.options['baseFields'].track &&
		module.options['baseFields'].track.filter) || (module.options['requiredFields'] &&
		module.options['requiredFields']['track'] && module.options['requiredFields']['track'].filter)) &&
		filters.tracks.length > 0) {
		<div class="filter-content" style="padding: 10px !important">
			<div
				(click)="collapse('tracks')"
				style="
					display: flex;
					justify-content: space-between;
					margin-top: 5px;
					margin-bottom: 5px;
					cursor: pointer;
				"
			>
				<h5 class="filter-name" [style.color]="event.styling.titleColor">
					{{
						module.options.trackName && module.options.trackName[language] !== ""
							? module.options.trackName[language]
							: ("labels.tracks" | translate)
					}}:
				</h5>
				@if (!getFilterCollapseState('tracks')) {
				<ion-icon src="assets/icon/ArrowUp_thin.svg" style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
				} @if (getFilterCollapseState('tracks')) {
				<ion-icon src="assets/icon/ArrowDown_thin.svg" style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
				}
			</div>
			<ion-list
				class="accordion-container"
				[class.collapsed]="getFilterCollapseState('tracks')"
				[style.maxHeight]="'auto'"
			>
				@for (trackItem of filters.tracks; track trackItem) {
				<ion-item lines="none">
					<ion-label class="filter-label">{{ trackItem.name[language] }}</ion-label>
					<ion-checkbox
						color="dark"
						slot="end"
						[value]="trackItem.name[language]"
						[(ngModel)]="trackItem.checked"
						[disabled]="!trackItem.isReachable"
					>
					</ion-checkbox>
				</ion-item>
				} @if (filterIsActivated('tracks')) {
				<ion-chip color="danger" (click)="clearFilter('tracks')">
					<ion-label class="chip-delete-text"
						>{{ "filter.delete_all" | translate }} ({{
							module.options.trackName && module.options.trackName[language] !== ""
								? module.options.trackName[language]
								: ("labels.tracks" | translate)
						}})</ion-label
					>
					<ion-icon name="close-circle"></ion-icon>
				</ion-chip>
				}
			</ion-list>
		</div>
		}
		<!-- Groups -->
		@if (((module.options['baseFields'] && module.options['baseFields'].group &&
		module.options['baseFields'].group.filter) || (module.options['requiredFields'] &&
		module.options['requiredFields']['group'] && module.options['requiredFields']['group'].filter)) &&
		filters.groups.length > 0) {
		<div class="filter-content" style="padding: 10px !important">
			<div
				(click)="collapse('groups')"
				style="
					display: flex;
					justify-content: space-between;
					margin-top: 5px;
					margin-bottom: 5px;
					cursor: pointer;
				"
			>
				<h5 class="filter-name" [style.color]="event.styling.titleColor">{{ "labels.groups" | translate }}:</h5>
				@if (!getFilterCollapseState('groups')) {
				<ion-icon src="assets/icon/ArrowUp_thin.svg" style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
				} @if (getFilterCollapseState('groups')) {
				<ion-icon src="assets/icon/ArrowDown_thin.svg" style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
				}
			</div>
			<ion-list
				class="accordion-container"
				[class.collapsed]="getFilterCollapseState('groups')"
				[style.maxHeight]="'auto'"
			>
				@for (groupItem of filters.groups; track groupItem) {
				<ion-item lines="none">
					<ion-label class="filter-label">{{ groupItem.name }}</ion-label>
					<ion-checkbox
						color="dark"
						slot="end"
						[value]="groupItem.name"
						[(ngModel)]="groupItem.checked"
						[disabled]="!groupItem.isReachable"
					>
					</ion-checkbox>
				</ion-item>
				} @if (filterIsActivated('groups')) {
				<ion-chip color="danger" (click)="clearFilter('groups')">
					<ion-label class="chip-delete-text"
						>{{ "filter.delete_all" | translate }} ({{ "labels.groups" | translate }})</ion-label
					>
					<ion-icon name="close-circle"></ion-icon>
				</ion-chip>
				}
			</ion-list>
		</div>
		}
		<!-- Customs fields -->
		@for (customFieldItem of filters.customFields; track customFieldItem) {
		<div class="filter-content" style="padding: 10px !important">
			@if (customFieldItem.values.length > 0) {
			<div class="filter-custom-field-item">
				<div
					(click)="collapse(customFieldItem.uid)"
					style="
						display: flex;
						justify-content: space-between;
						margin-top: 5px;
						margin-bottom: 5px;
						cursor: pointer;
					"
				>
					<h5 class="filter-name" [style.color]="event.styling.titleColor">{{ customFieldItem.name }}:</h5>
					@if (!getFilterCollapseState(customFieldItem.uid)) {
					<ion-icon src="assets/icon/ArrowUp_thin.svg" style="position: relative; top: 6px; cursor: pointer">
					</ion-icon>
					} @if (getFilterCollapseState(customFieldItem.uid)) {
					<ion-icon
						src="assets/icon/ArrowDown_thin.svg"
						style="position: relative; top: 6px; cursor: pointer"
					>
					</ion-icon>
					}
				</div>
				<ion-list
					class="accordion-container"
					[class.collapsed]="getFilterCollapseState(customFieldItem.uid)"
					[style.maxHeight]="'auto'"
				>
					@for (value of customFieldItem.values; track value) {
					<ion-item lines="none">
						<ion-label class="filter-label">{{ value.value }}</ion-label>
						<ion-checkbox
							color="dark"
							slot="end"
							[value]="value"
							[(ngModel)]="value.isSelected"
							[disabled]="!value.isReachable"
						>
						</ion-checkbox>
					</ion-item>
					} @if (filterIsActivated('customs-fields', customFieldItem.uid)) {
					<ion-chip color="danger" (click)="clearFilter('customs-fields', customFieldItem.uid)">
						<ion-label class="chip-delete-text"
							>{{ "filter.delete_all" | translate }} ({{ customFieldItem.name }})</ion-label
						>
						<ion-icon name="close-circle"></ion-icon>
					</ion-chip>
					}
				</ion-list>
			</div>
			}
		</div>
		}
	</div>
	}
</div>
