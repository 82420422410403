/* eslint-disable no-useless-escape */
import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormControl, ValidatorFn } from "@angular/forms";
import { DateTime } from "luxon";
import { IMultipleTextField } from "../interfaces/custom-fields.interfaces";
import { TypeCustomFields } from "../enums/type-custom-fields";
import { IRegisterFormItem } from "../interfaces/register-form.interfaces";

@Injectable({
	providedIn: "root"
})
export class FormsService {
	FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
	minChar1: number = 1;
	minChar6: number = 6;
	maxChar70: number = 70;
	maxChar100: number = 100;
	maxChar200: number = 200;
	maxChar300: number = 300;
	maxChar1000: number = 1000;
	// eslint-disable-next-line max-len, no-useless-escape
	urlRegexString = `^(?:(?:http|https):\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-._~:\\/?#[\\]@!$&'()*+,;=%]+$`;
	emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

	/**
	 * Check validation input
	 * @param formControl
	 * @returns
	 */
	checkValidInput(formControl: UntypedFormControl) {
		return formControl?.valid && (formControl?.dirty || formControl?.touched) && formControl?.value ? true : false;
	}

	/**
	 * checkInvalidInput
	 * @param formControl
	 * @returns
	 */
	checkInvalidInput(formControl: UntypedFormControl) {
		return (formControl.invalid && (formControl?.dirty || formControl?.touched)) ||
			(!formControl.value && formControl?.hasError("required") && (formControl?.dirty || formControl?.touched))
			? true
			: false;
	}

	/**
	 * getInputErrorType
	 * @param formControl
	 * @returns
	 */
	getInputErrorType(formControl: UntypedFormControl) {
		const formState = (formControl && formControl?.touched) || formControl?.dirty;
		if (formState && formControl?.hasError?.("required")) {
			return "required";
		} else if (formState && formControl?.hasError?.("minlength")) {
			return "minlength";
		} else if (formState && formControl?.hasError?.("maxlength")) {
			return "maxlength";
		} else if (formState && formControl?.hasError?.("pattern")) {
			return "pattern";
		} else if (formState && formControl?.hasError?.("email")) {
			return "email";
		} else if (formState && formControl?.hasError?.("url")) {
			return "url";
		} else if (formState && formControl?.hasError?.("phone")) {
			return "phone";
		} else if (formState && formControl?.hasError?.("multiTextShouldContainValue")) {
			return "multiTextShouldContainValue";
		} else if (formState && formControl?.hasError?.("controlShouldNotContainValue")) {
			return "controlShouldNotContainValue";
		} else if (formState && formControl?.hasError?.("multiTextIsRequired")) {
			return "multiTextIsRequired";
		} else if (formState && formControl?.hasError?.("dateMaximum")) {
			return "dateMaximum";
		} else if (formState && formControl?.hasError?.("dateMinimum")) {
			return "dateMinimum";
		} else if (formState && formControl?.hasError?.("checkboxError")) {
			return "checkboxError";
		} else if (formState && formControl?.hasError?.("fileSizeLimit")) {
			return "fileSizeLimit";
		} else {
			return "";
		}
	}

	/**
	 * Validate start date
	 * @param startDateControl
	 */
	noPastDate(startDateControl: AbstractControl) {
		if (startDateControl.value && startDateControl.value.split("-").length === 3) {
			const dateArray = startDateControl.value.split("-");
			if (
				DateTime.fromObject(
					{
						day: dateArray[2],
						month: dateArray[1],
						year: dateArray[0]
					},
					{ zone: "local" }
				).startOf("day") < DateTime.local().startOf("day")
			) {
				return { dateInThePast: true };
			}
		}
		return null;
	}

	/**
	 * Validate end time
	 * @param endTimeControl
	 * @param startTimeControl
	 */
	endTimeBeforeStart(endTimeControl: AbstractControl, startTimeControl: AbstractControl) {
		if (!startTimeControl.value || startTimeControl.value.split(":").length < 2) {
			return { endTimeBeforeStart: true };
		}

		if (
			endTimeControl.value &&
			startTimeControl.value &&
			endTimeControl.value.split(":").length >= 2 &&
			startTimeControl.value.split(":").length >= 2
		) {
			const startTimeArray = startTimeControl.value.split(":");
			const endTimeArray = endTimeControl.value.split(":");
			if (
				DateTime.local()
					.set({
						hour: parseInt(startTimeArray[0]),
						minute: parseInt(startTimeArray[1]),
						second: startTimeArray.length > 2 ? parseInt(startTimeArray[2]) : 0
					})
					.toISO() >
				DateTime.local()
					.set({
						hour: parseInt(endTimeArray[0]),
						minute: parseInt(endTimeArray[1]),
						second: endTimeArray.length > 2 ? parseInt(endTimeArray[2]) : 0
					})
					.toISO()
			) {
				return { endTimeBeforeStart: true };
			}
		}
		return null;
	}

	urlValidator(text: string) {
		return text.match("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?");
	}

	/**
	 * isTextControlType
	 * @param cusFieldType
	 * @returns
	 */
	isTextControlType(cusFieldType: TypeCustomFields) {
		return (
			cusFieldType === TypeCustomFields.TEXT ||
			cusFieldType === TypeCustomFields.HMTL ||
			cusFieldType === TypeCustomFields.MULTI_TEXT ||
			cusFieldType === TypeCustomFields.PHONE ||
			cusFieldType === TypeCustomFields.URL ||
			cusFieldType === TypeCustomFields.PASSWORD ||
			cusFieldType === TypeCustomFields.NUMERIC ||
			cusFieldType === TypeCustomFields.ADDRESS
		);
	}

	/**
	 * isMultiLanguageField
	 * @param customFieldType
	 * @returns
	 */
	isMultiLanguageField(item: IRegisterFormItem) {
		return (
			(item.inputFieldType === TypeCustomFields.TEXT ||
				item.inputFieldType === TypeCustomFields.HMTL ||
				item.inputFieldType === TypeCustomFields.SELECT ||
				item.inputFieldType === TypeCustomFields.MULTI_SELECT ||
				item.inputFieldType === TypeCustomFields.MULTI_TEXT) &&
			item.uid !== "name"
		);
	}

	/*
	 * ********** Register Form custom validators **********
	 */

	/**
	 * multiTextValidator
	 * @param data
	 * @param required
	 * @param language
	 * @returns
	 */
	multiTextValidator(data: IMultipleTextField, required: boolean, language: string): ValidatorFn {
		return (control: AbstractControl) => {
			const isRequired: boolean = required;
			const controlShouldNotContainValue: boolean = control.value === null || control.value === "";
			const multiTextShouldContainValue: boolean = data.multiLanguageTextArray[language].length > 0;

			if (
				(isRequired && controlShouldNotContainValue && multiTextShouldContainValue) ||
				(!isRequired && controlShouldNotContainValue)
			) {
				return null;
			}

			return {
				multiTextIsRequired: { error: !isRequired, msg: "register.validator-error-msg.multi-text.required" },
				controlShouldNotContainValue: {
					error: !controlShouldNotContainValue,
					msg: "register.validator-error-msg.multi-text.control_value_must_validated"
				},
				multiTextShouldContainValue: {
					error: !multiTextShouldContainValue,
					msg: "register.validator-error-msg.multi-text.multi_text_must_contain_value"
				}
			};
		};
	}

	/**
	 * checkBoxValidator
	 * @param control
	 * @returns
	 */
	checkBoxValidator(control: AbstractControl) {
		if (control.value === true) {
			return null;
		} else {
			return {
				checkboxError: {
					error: true,
					msg: "register.validator-error-msg.checkbox-required"
				}
			};
		}
	}

	/**
	 * customMinDateValidator
	 * @param validationDate
	 * @returns
	 */
	customMinDateValidator(validationDate: string): ValidatorFn {
		return (control: AbstractControl) => {
			const ctrlValue: Date = control.value;

			const result =
				DateTime.fromISO(validationDate?.split("T")?.[0]).valueOf() >
				DateTime.fromISO(DateTime.fromJSDate(ctrlValue).toISO()?.split("T")?.[0]).valueOf();

			if (!result) {
				return null;
			} else {
				return {
					dateMinimum: {
						error: true,
						msg: "register.validator-error-msg.date-minimum"
					}
				};
			}
		};
	}

	/**
	 * customMaxDateValidator
	 * @param validationDate
	 * @returns
	 */
	customMaxDateValidator(validationDate: string): ValidatorFn {
		return (control: AbstractControl) => {
			const ctrlValue: Date = control.value;

			const result =
				DateTime.fromISO(validationDate?.split("T")?.[0]).valueOf() <
				DateTime.fromISO(DateTime.fromJSDate(ctrlValue).toISO()?.split("T")?.[0]).valueOf();

			if (!result) {
				return null;
			} else {
				return {
					dateMaximum: {
						error: true,
						msg: "register.validator-error-msg.date-maximum"
					}
				};
			}
		};
	}
}
